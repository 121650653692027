import React from 'react';
import styled from 'styled-components';

const ItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: ${(props) => props.theme.space('space-08')};
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-top: ${(props) => props.theme.space('space-08')};
`;

export { ItemContainer, ButtonContainer };
