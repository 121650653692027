export const ProductStatus = {
  DELIVERED: 'DELIVERED',
  SOLD_OUT: 'SOLD_OUT',
  MISSING: 'MISSING',
  REPLACED: 'REPLACED',
  REPLACEMENT: 'REPLACEMENT',
  PRICE_CHANGE: 'PRICE_CHANGE',
  WEIGHT_CHANGE: 'WEIGHT_CHANGE',
  QUANTITY_CHANGE: 'QUANTITY_CHANGE',
};
