import React, { memo } from 'react';
import type { FC } from 'react';
import type { RefundsProps } from './Refunds.type';
import { RefundItemList, RefundTitle } from './components';
import { useRefunds } from './hooks/useRefunds';
import { Container } from '@app/components/Container';
import { withRetryOnError } from '@app/detail/use_cases/hoc';

export const Refunds: FC<RefundsProps> = memo(
  withRetryOnError(({ data, country, orderId, orderState, isCash }) => {
    const { data: result } = useRefunds(data);

    return (
      <section>
        <Container pTop="16" pBottom="0" pLeft="16" pRight="16">
          <Container pTop="0" pBottom="16" pLeft="0" pRight="0">
            <RefundTitle
              refunds={result.refunds}
              cancellations={result.cancellations}
              updates={result.updates}
              country={country}
            />
          </Container>
          <RefundItemList
            refunds={result.refunds}
            cancellations={result.cancellations}
            updates={result.updates}
            country={country}
            orderId={orderId}
            orderState={orderState}
          />
        </Container>
      </section>
    );
  }),
);
