import { defineMessages } from 'react-intl';

export default defineMessages({
  'refundItemBankTransfer.title': {
    id: 'refundItemBankTransfer.title',
    defaultMessage: 'Transferencia a tu cuenta bancaria',
  },
  'refundItemBankTransfer.description': {
    id: 'refundItemBankTransfer.description',
    defaultMessage: 'Tu banco acreditará el dinero dentro de los próximos {day} días hábiles.',
  },
});
