import React from 'react';
import styled from 'styled-components';

const ProductContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color('shape-color-stroke-primary')};
  &:last-child {
    border-bottom: 0px;
  }
  &:first-child {
    margin-top: ${(props) => props.theme.space('space-08')};
  }
`;

const ViewAllContainer = styled.div`
  margin-top: ${(props) => props.theme.space('space-06')};
`;

const AddtionalNotesContainer = styled.div`
  margin-top: ${(props) => props.theme.space('space-08')};
  padding-top: ${(props) => (props.viewAll ? props.theme.space('space-08') : '0px')};
  border-top: ${(props) => (props.viewAll ? '1px solid #DBDADE' : '0px')};
`;

export { ProductContainer, ViewAllContainer, AddtionalNotesContainer };
