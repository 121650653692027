import React, { forwardRef, useState, useEffect } from 'react';
import ArrowLeft from '@pedidosya/web-fenix/icons/ArrowLeft';
import PropTypes from 'prop-types';
import { NavBarFloating, ItemsContainer, VendorName } from './NavBar.style';
import { Container } from '@app/components/Container';
import { useInView } from 'react-intersection-observer';
import { useUrlQuery } from '@app/hooks/useUrlQuery';

const NavBar = forwardRef((props, ref) => {
  const [outisde, setOutside] = useState(false);
  const query = useUrlQuery();

  const { ref: inViewRef } = useInView({
    rootMargin: '-32px',
    onChange: (inView) => {
      setOutside(inView);
    },
  });

  useEffect(() => {
    if (ref.current) {
      inViewRef(ref.current);
    }
  }, [ref, inViewRef]);

  return (
    <NavBarFloating outisde={outisde} hasSafe={query.get('hasSafe') === 'true' ? true : false}>
      <Container pBottom="0" pTop="0">
        <ItemsContainer>
          <div>
            <ArrowLeft
              color={!outisde ? 'shape-color-surface-secondary' : 'shape-color-background-primary'}
              onClick={props?.onBack}
              data-testid="back"
            />
          </div>
          {!outisde && (
            <VendorName
              component="p"
              token="font-headline-highcontrast-small"
              color="shape-color-surface-secondary"
            >
              {props?.name}
            </VendorName>
          )}
          <p></p>
        </ItemsContainer>
      </Container>
    </NavBarFloating>
  );
});

NavBar.defaultProps = {
  onBack: () => {},
  name: '',
};

NavBar.propTypes = {
  name: PropTypes.string,
  onBack: PropTypes.func,
};

export { NavBar };
