import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const GridContainer = styled.div`
  padding-top: ${(props) => props.theme.space('space-08')};
  padding-bottom: ${(props) => props.theme.space('space-08')};
  display: grid;
  grid-template-columns: 48px auto auto;
  gap: 16px;
`;

const ImageSection = styled.div`
  min-width: 48px;
`;

const DescriptionSection = styled.div`
  flex-shrink: 1;
`;

const Description = styled(Typography)`
  margin-top: ${(props) => props.theme.space('space-02')};
`;

const Notes = styled(Typography)`
  margin-top: ${(props) => props.theme.space('space-04')};
`;

const TagContainer = styled.div`
  padding-top: ${(props) => props.theme.space('space-08')};
`;

const AmountSection = styled.div`
  min-width: ${(props) => (props?.hasPriceChanged ? '130px' : '90px')};
  text-align: right;
  @media (max-width: 320px) {
    text-align: left;
  }
`;

const AmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  justify-content: flex-end;
`;

const Amount = styled(Typography)`
  margin-top: ${(props) => props.theme.space('space-02')};
  word-wrap: break-word;
`;

const QuantitySection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  justify-content: flex-end;
`;

const ModifiedText = styled(Typography)`
  margin-bottom: ${(props) => props.theme.space('space-08')};
`;

export {
  ImageSection,
  DescriptionSection,
  AmountSection,
  Notes,
  Description,
  Amount,
  TagContainer,
  QuantitySection,
  AmountContainer,
  ModifiedText,
  GridContainer,
};
