import { ProductStatus } from '@app/components/Detail/DetailProducts/ProductStatus';
import LocalTranslations from './ProductItem.translation';

export const TagLabel = {
  [ProductStatus.MISSING]: LocalTranslations.product_state_missing_item,
  [ProductStatus.SOLD_OUT]: LocalTranslations.product_state_sold_out_item,
  [ProductStatus.PRICE_CHANGE]: LocalTranslations.product_state_price_change_item,
  [ProductStatus.REPLACED]: LocalTranslations.product_state_replaced_item,
  [ProductStatus.REPLACEMENT]: LocalTranslations.product_state_replaced_item,
  [ProductStatus.QUANTITY_CHANGE]: LocalTranslations.product_state_quantity_change_item,
  [ProductStatus.WEIGHT_CHANGE]: LocalTranslations.product_state_weight_change_item,
};
