import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

export const SectionContainer = styled.div<{ margin?: string }>`
  margin: ${(props) => (props?.margin ? props.margin : '0')};
`;

export const Text = styled(Typography)`
  margin: ${(props) => (props?.margin ? props.margin : '0')};
`;
