import has from 'lodash/has';
/**
 * checkRefundMethod
 *
 * @description Function to check options passed to process with rules
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const checkOrderDetail = (fact, context) => {
  if (context.parameters.orderState === fact.orderState) {
    if (
      context.parameters?.pdi === fact?.pdi &&
      !context.parameters?.cancelledByUser &&
      !context.parameters?.reason &&
      !context.parameters?.cash
    ) {
      return fact;
    } else if (
      context.parameters?.pdi === fact?.pdi &&
      !context.parameters?.cancelledByUser &&
      context.parameters?.reason === fact.reason &&
      !context.parameters?.cash
    ) {
      return fact;
    } else if (
      context.parameters?.reason === fact.reason &&
      !context.parameters?.pdi &&
      !context.parameters?.cancelledByUser &&
      !context.parameters?.cash
    ) {
      return fact;
    } else if (
      context.parameters?.cancelledByUser === fact.cancelledByUser &&
      context.parameters?.cash === fact.cash &&
      !context.parameters?.pdi &&
      !context.parameters?.reason
    ) {
      return fact;
    }
  }

  return null;
};

/**
 * getTranslationRefundMethod
 *
 * @description Function to return the translations keys from rule
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const getTranslationOrderDetail = (fact, context) => {
  fact.translations = { ...context.parameters };
  return fact;
};
