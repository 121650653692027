import React, { Children, cloneElement, memo } from 'react';
import PropTypes from 'prop-types';
/**
 * Styled
 */
import { TitleContainer, TitleChildren, AccordionContainer, Arrow } from './Accordion.style';

const Accordion = memo(({ children, expanded, toggle }) => {
  const elements = Children.toArray(children);
  const TitleElement = elements.find((element) => element.type.displayName === 'Title');
  const BodyElement = elements.find((element) => element.type.displayName === 'Body');

  return (
    <AccordionContainer>
      {TitleElement &&
        cloneElement(TitleElement, { children: TitleElement?.props?.children, expanded, toggle })}
      {BodyElement &&
        cloneElement(BodyElement, { children: BodyElement?.props?.children, expanded })}
    </AccordionContainer>
  );
});

const Title = ({ children, expanded, backgroundColor, padding, toggle, borderRadius }) => {
  return (
    <TitleContainer
      style={{
        backgroundColor,
        padding,
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
        borderBottomLeftRadius: !expanded ? borderRadius : '0px',
        borderBottomRightRadius: !expanded ? borderRadius : '0px',
      }}
      onClick={() => toggle && toggle()}
      data-testid="toggle_accordion"
    >
      <TitleChildren>{children}</TitleChildren>
      <Arrow expanded={expanded} size="small" color="icon-color-primary" />
    </TitleContainer>
  );
};
Title.displayName = 'Title';

const Body = ({ children, expanded }) => {
  return <div>{expanded && children}</div>;
};
Body.displayName = 'Body';

Accordion.title = Title;

Accordion.body = Body;

Accordion.defaultProps = {
  children: <></>,
  expanded: false,
};

Accordion.title.defaultProps = {
  children: <></>,
  expanded: false,
  backgroundColor: '',
  padding: '0px 0px 0px 0px',
  borderRadius: '0px',
  toggle: () => {},
};

Accordion.body.defaultProps = {
  children: <></>,
  expanded: false,
};

Accordion.propTypes = {
  children: PropTypes.node,
  expanded: PropTypes.bool,
  toggle: PropTypes.func,
};

Accordion.title.propTypes = {
  children: PropTypes.node,
  expanded: PropTypes.bool,
  backgroundColor: PropTypes.string,
  padding: PropTypes.string,
  toggle: PropTypes.func,
  borderRadius: PropTypes.string,
};
Accordion.body.propTypes = {
  children: PropTypes.node,
  expanded: PropTypes.bool,
};

export { Accordion };
