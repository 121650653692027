import React, { useEffect } from 'react';
import { getDetailByOrderId } from '@app/services/orderDetail';
import { useQuery } from 'react-query';
import { useUrlQuery } from '@app/hooks/useUrlQuery';
import { useTracker } from '@app/contexts/TrackerProvider';
import { sentryHub } from '@app/utils/sentry';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { Constants } from '@app/config/constants';
import isEmpty from 'lodash/isEmpty';

const useOrderByOrderId = () => {
  const query = useUrlQuery();
  const { pageLoaded, gtmTrack } = useTracker();
  const { initialData } = usePublicEnv();

  const { data, isLoading, isError } = useQuery(
    ['getDetailByOrderId', query.get],
    async ({ signal }) =>
      getDetailByOrderId(query.get('orderId'), query.get('flow') || query.get('origin'), signal),
    {
      retry: (failureCount, error) => {
        const omitCodes = [503, 404];
        if (failureCount > Constants.RETRY_LIMIT) {
          return false;
        }
        if (omitCodes.includes(error?.response?.status)) {
          return false;
        }

        return true;
      },
      onError: (error) => {
        sentryHub.withScope((scope) => {
          scope.setTransactionName('ErrorGetDetailByOrderId');
          scope.setTag('orderId', query.get('orderId'));
          scope.setTag('flow', query.get('flow') || query.get('origin'));
          scope.setTag('http.status', error?.response?.status);
          scope.setTag('http.status.backend', error?.response?.data?.status);
          sentryHub.captureException(new Error(JSON.stringify(error?.response?.data)));
        });
      },
      enabled: isEmpty(initialData),
    },
  );

  useEffect(() => {
    const res = isEmpty(initialData) ? data : initialData;
    if (res) {
      const flow = query.get('flow') || query.get('origin');
      const orderId = query.get('orderId');
      pageLoaded(
        flow,
        orderId,
        res?.order?.status,
        res?.compensationInformation ? res?.compensationInformation.length > 0 : false,
      );
    }
  }, [data, initialData, gtmTrack]);

  return {
    data: isEmpty(initialData) ? data : initialData,
    isLoading,
    isError,
  };
};

export { useOrderByOrderId };
