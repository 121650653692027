/**
 * Constants
 *
 * @description general constants
 */
export const Constants = {
  MOCK_FLAG: 'mockRequest',
  DEEPLINK: 'deeplink',
  WEBEVENT: 'webevent',
  NAVIGATE: 'navigate',
  ROUTER: 'router',
  DELIVERED_STATE: 'DELIVERED',
  CANCELLED_STATE: 'REJECTED',
  SCHEDULED_STATE: 'QUEUED',
  PENDING_STATE: 'IN_PROGRESS',
  PRODUCT_MAX_VIEWABLE: 3,
  ORIGIN: 'orderDetail',
  VENDOR_OPEN_STATE: 'OPEN',
  VENDOR_OPEN_LATE_STATE: 'OPENS_LATER',
  VENDOR_CLOSED_STATE: 'CLOSED',
  WEBLINK_REFUND_CARD: 'cardMoreInformation',
  FEEDBACK_CLOSE_EVENT: 'close',
  ORIGIN_PDI: 'orderPDI',
  ORIGIN_OMN_EMAIL: 'omn-mail',
  ORIGIN_PARAM: 'origin',
  RETRY_LIMIT: 2,
  FLOW_PARAM: 'flow',
  SAFEAREA_PARAM: 'hasSafe',
  OPERATION_PARAM: 'operation',
};
