import { defineMessages } from 'react-intl';

export default defineMessages({
  'refundItemVoucher.title': {
    id: 'refundItemVoucher.title',
    defaultMessage: '{count, plural, one {Cupón de reembolso} other {Cupones de reembolso}}',
  },
  'refundItemVoucher.description': {
    id: 'refundItemVoucher.description',
    defaultMessage:
      '{count, plural, one {Ya lo tienes acreditado en la sección Cupones.} other {Ya los tienes acreditados en la sección Cupones.}}',
  },
  'refundItemVoucher.view': {
    id: 'refundItemVoucher.view',
    defaultMessage: '{count, plural, one {Ver cupón} other {Ver cupones}}',
  },
});
