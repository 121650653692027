import { PaymentMethods } from '@shared/config';

export class PaymentMethodProxy {
  private data: `${PaymentMethods}`;
  constructor(data: `${PaymentMethods}`) {
    this.data = data;
  }

  isWallet(): boolean {
    return this.data.toUpperCase() === PaymentMethods.WALLET;
  }

  isVoucher(): boolean {
    return (
      this.data.toUpperCase() === PaymentMethods.VOUCHER ||
      this.data.toUpperCase() === PaymentMethods.COUPON
    );
  }

  acceptRefund(): boolean {
    return (
      this.data.toUpperCase() !== PaymentMethods.DLOCAL_PAGO_QR &&
      this.data.toUpperCase() !== PaymentMethods.PAGO_MOVIL_ONLINE &&
      this.data.toUpperCase() !== PaymentMethods.WEBPAY
    );
  }
}
