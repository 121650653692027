import Compensation from '@app/assets/images/compensation.svg';
import { ACTIONS } from '@app/utils/deeplink';
import { Constants } from '@app/config/constants';

export const CompensationRule = {
  name: 'compensation',
  rules: [
    {
      name: 'Compensation with BAD_WEATHER + OUTSIDE_SERVICE_HOURS',
      description: 'Order complete without rating',
      when: {
        closure: 'checkOptionCompensation',
        cancellationReason: ['BAD_WEATHER', 'OUTSIDE_SERVICE_HOURS'],
      },
      then: {
        closure: 'getTranslationCompensation',
        title: 'compensation_title_custom',
        buttons: [
          {
            title: 'compensation_button_viewCoupon',
            type: Constants.DEEPLINK,
            value: ACTIONS.COUPONS,
            style: 'primary',
          },
        ],
        image: Compensation,
      },
    },
    {
      name: 'Compensation with any other reasons',
      description: 'Compensation with any other reasons',
      when: {
        closure: 'checkOptionCompensation',
        cancellationReason: '*',
        except: ['BAD_WEATHER', 'OUTSIDE_SERVICE_HOURS'],
      },
      then: {
        closure: 'getTranslationCompensation',
        title: 'compensation_title',
        buttons: [
          {
            title: 'compensation_button_useCoupon',
            type: Constants.DEEPLINK,
            value: ACTIONS.COUPONS,
            style: 'primary',
          },
        ],
        image: Compensation,
      },
    },
  ],
};
