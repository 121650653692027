import React from 'react';
import styled from 'styled-components';

const ItemsContainer = styled.div`
  &:first-child {
    margin-top: ${(props) => props.theme.space('space-08')};
  }
  &:last-child > div {
    padding-bottom: 0px;
  }
`;

const BillingContainer = styled.div`
  margin-top: ${(props) => props.theme.space('space-16')};
  & > p {
    margin-top: ${(props) => props.theme.space('space-08')};
  }
`;

export { ItemsContainer, BillingContainer };
