import React, { memo } from 'react';
import type { FC } from 'react';
import { useIntl } from 'react-intl';
import {
  AmountContainer,
  DescriptionContainer,
  IconContainer,
  ItemContainer,
  Title,
  ImageContainer,
} from './RefundItemBankTransfer.style';
import { Formatter } from '@app/components/NumberFormatter';
import type { RefundItemsProps } from '@app/detail/presentation/components/Refunds/models';
import { Constants } from '@app/detail/infra/config/Constants';
import Bank from '@pedidosya/web-fenix/icons/Bank';
import Translations from './RefundItemBankTransfer.translation';

export const RefundItemBankTransfer: FC<RefundItemsProps> = memo(({ data, country }) => {
  const { formatMessage } = useIntl();
  const amountFormated = Formatter(parseFloat(data.amount as any), country);

  return (
    <ItemContainer>
      <DescriptionContainer>
        <ImageContainer>
          <IconContainer>
            <Bank />
          </IconContainer>
        </ImageContainer>
        <div>
          <Title
            token="font-body-midcontrast-sentence-medium"
            color="text-color-action-enabled-loud"
            component="p"
          >
            {formatMessage(Translations['refundItemBankTransfer.title'])}
          </Title>
          <Title
            token="font-body-midcontrast-sentence-small"
            color="text-color-action-activated-quiet"
            component="p"
          >
            {formatMessage(Translations['refundItemBankTransfer.description'], {
              day: Constants.BANK_TRANSFER_DAYS,
            })}
          </Title>
        </div>
      </DescriptionContainer>
      {data.amount && parseFloat(data.amount as any) > 0.0 ? (
        <AmountContainer>
          <Title token="text-base-large" color="text-color-action-enabled-loud" component="p">
            {amountFormated}
          </Title>
        </AmountContainer>
      ) : (
        <></>
      )}
    </ItemContainer>
  );
});
