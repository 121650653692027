import React, { memo, useMemo } from 'react';
import type { FC } from 'react';
import { RefundReferenceProps } from './RefundReference.type';
import { PaymentMethods } from '@app/config/paymentMethods';
import { useIntl } from 'react-intl';
import { Reference } from './RefundReference.style';
import { Container as Block } from '@app/components/Container';
import Translations from './RefundReference.translation';

export const RefundReference: FC<RefundReferenceProps> = memo(
  ({ cancellations = [], refunds, country, updates = [] }) => {
    const { formatMessage } = useIntl();

    const references = useMemo(() => {
      return [...cancellations, ...refunds, ...updates]
        .filter((item) => item?.type.toUpperCase() === PaymentMethods.CARD)
        .reduce((acc, element) => {
          element.operations.forEach((item) => {
            item.movements.forEach((movement) => {
              if (movement.referenceId) {
                acc.push(movement.referenceId);
              }
            });
          });
          return acc;
        }, [] as Array<string>);
    }, [cancellations, refunds, updates]);

    if (references.length > 1 || references.length === 0) {
      return null;
    }

    return (
      <Block pTop="4" pBottom="16" pLeft="0" pRight="0">
        <Reference component="p" token="text-base-small" data-testid="reference">
          <b>{formatMessage(Translations.reference_code)}: &nbsp;</b>
          <span>{references[0]}</span>
        </Reference>
      </Block>
    );
  },
);
