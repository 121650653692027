import React from 'react';
import { useQuery } from 'react-query';
import { getBillingData } from '@app/services/billing';
import { useUrlQuery } from '@app/hooks/useUrlQuery';
import { useDownloadFile } from '@app/hooks/useDownloadFile';

const useDetailSummary = (orderId, documentId, countryId) => {
  const query = useUrlQuery();
  const flow = query.get('flow') || query.get('origin');
  const { download } = useDownloadFile();

  const { data, isLoading, isError, refetch } = useQuery(
    ['getBillingData', orderId, documentId, countryId],
    async ({ signal }) => getBillingData(orderId, documentId, countryId, flow, signal),
    {
      retry: 2,
      enabled: false,
      onSuccess: (data) => {
        download(data?.name, data?.content, data?.type);
      },
    },
  );

  const downloadBilling = () => {
    if (!isLoading) {
      refetch();
    }
  };

  return { data, isLoading, isError, downloadBilling };
};

export { useDetailSummary };
