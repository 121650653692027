import React from 'react';
import { ACTIONS } from '@app/utils/deeplink';
import { useLinkContext } from '@app/contexts/LinkProvider';
import { Constants } from '@app/config/constants';

const useDeeplinks = () => {
  const { redirect, navigate, emitEvent, redirectRouter } = useLinkContext();

  const handleDeeplink = (name, params) => {
    return redirect(name, params);
  };

  const handleNavigate = (name, params) => {
    return navigate(name, params);
  };

  const handleWebEvent = (name, params) => {
    return emitEvent(name, params);
  };

  const handleRoute = (name, params) => {
    return redirectRouter(name, params);
  };

  const handleEvent = (name, type, params) => {
    if (type === Constants.DEEPLINK) {
      return handleDeeplink(name, params);
    } else if (type === Constants.NAVIGATE) {
      return handleNavigate(name, params);
    } else if (type === Constants.WEBEVENT) {
      return handleWebEvent(name, params);
    } else if (type === Constants.ROUTER) {
      return handleRoute(name, params);
    }
  };

  return {
    handleEvent,
  };
};

export { useDeeplinks };
