import { defineMessages } from 'react-intl';

export default defineMessages({
  'refundItemList.void_title': {
    id: 'refundItemList.void_title',
    defaultMessage: 'Pago anulado',
  },
  'refundItemList.credit_title': {
    id: 'refundItemList.credit_title',
    defaultMessage: 'Reembolso',
  },
  'refundItemList.update_amount_title': {
    id: 'refundItemList.update_amount_title',
    defaultMessage: 'Pago Actualizado',
  },
});
