import { useMemo } from 'react';
import { Method } from '@shared/models';
import { OperationType } from '@app/detail/infra/config/OperationType';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Routes } from '@app/config/routes';
import { PaymentMethods } from '@app/config/paymentMethods';
import { Constants } from '@app/config/constants';
import { Formatter } from '@app/components/NumberFormatter';
import { useTracker } from '@app/contexts/TrackerProvider';
import { useUrlQuery } from '@app/hooks/useUrlQuery';
import First from 'lodash/first';
import Translation from './RefundItemCard.translation';

export const useRefundItemCard = (
  data: Method,
  orderId: number,
  country: string,
  orderState?: string,
) => {
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  const { refundDetailClicked } = useTracker();
  const query = useUrlQuery();
  const hasSafe = useMemo(
    () =>
      query.get(Constants.SAFEAREA_PARAM) ? query.get(Constants.SAFEAREA_PARAM) === 'true' : false,
    [],
  );

  const message = useMemo(() => {
    if (data) {
      /**
       * Finding the first element in array
       * there will be only one but it have a array
       * format
       */
      const operation = First(data.operations);
      if (operation?.type.toLowerCase() === OperationType.CREDIT) {
        return {
          message: formatMessage(Translation['refundItemCard.credit_message']),
          action: formatMessage(Translation['refundItemCard.credit_action']),
          operationType: OperationType.CREDIT,
          amount: Formatter(operation.amount, country),
          updateAmount: operation?.originalAmount
            ? Formatter(operation.originalAmount, country)
            : null,
        };
      }

      if (operation?.type.toLowerCase() === OperationType.UPDATE_AMOUNT) {
        return {
          message: formatMessage(Translation['refundItemCard.refund_amount_message']),
          action: formatMessage(Translation['refundItemCard.refund_amount_action']),
          operationType: OperationType.UPDATE_AMOUNT,
          amount: Formatter(operation.amount, country),
          updateAmount: operation?.originalAmount
            ? Formatter(operation.originalAmount, country)
            : null,
        };
      }

      if (operation?.type.toLowerCase() === OperationType.VOID) {
        return {
          message: formatMessage(Translation['refundItemCard.void_message']),
          action: formatMessage(Translation['refundItemCard.void_action']),
          operationType: OperationType.VOID,
          amount: Formatter(operation.amount, country),
          updateAmount: operation?.originalAmount
            ? Formatter(operation.originalAmount, country)
            : null,
        };
      }
    }
  }, [data]);

  /**
   * goToDetail
   *
   * @description Method for redirect to card detail refund
   * @param {string} operationType - Operation type (VOID|CREDIT|REFUND_AMOUNT)
   */
  const goToDetail = (operationType: string) => {
    const route = Routes.CARD_REFUND_DETAIL.replace(':orderId', orderId.toString()).replace(
      ':paymentMethod',
      PaymentMethods.CARD,
    );

    refundDetailClicked(orderId, orderState as string);

    push(
      `${route}?${Constants.OPERATION_PARAM}=${operationType}&${Constants.FLOW_PARAM}=${Constants.ORIGIN}&${Constants.SAFEAREA_PARAM}=${hasSafe}`,
    );
  };

  return {
    message,
    goToDetail,
  };
};
