import { BillingRepository } from '@app/repositories/billing';

/**
 * getBillingData
 *
 * @description Function to get billing data to download
 * @param {string} orderId - Order id
 * @param {string} documentId - Billing id
 * @param {string} countryId - Billing country id
 * @param {string} flow - Origin of request
 * @returns {Promise<Object>}
 */
export async function getBillingData(orderId, documentId, countryId, flow, signal) {
  const result = await BillingRepository.getBillingData(
    orderId,
    documentId,
    countryId,
    flow,
    signal,
  );
  return result.data;
}
