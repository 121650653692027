import indexOf from 'lodash/indexOf';
/**
 * checkOption
 *
 * @description Function to check options passed to process with rules
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const checkOption = (fact, context) => {
  if (
    indexOf(context.parameters.localReason, fact.localReason) !== -1 &&
    (!context.parameters.basketRecreationValue ||
      context.parameters.basketRecreationValue === '*') &&
    (!context.parameters.businessType || context.parameters.businessType === '*')
  ) {
    return fact;
  } else if (
    indexOf(context.parameters.localReason, fact.localReason) !== -1 &&
    context.parameters.basketRecreationValue === fact.basketRecreationValue &&
    context.parameters.businessType === fact.businessType
  ) {
    return fact;
  } else if (
    indexOf(context.parameters.localReason, fact.localReason) !== -1 &&
    context.parameters.basketRecreationValue === fact.basketRecreationValue &&
    context.parameters.businessType === '*'
  ) {
    return fact;
  }

  return null;
};

/**
 * getTranslation
 *
 * @description Function to return the translations keys from rule
 * @param {Object} fact - Paramters to process
 * @param {Object} context - Context of engine
 */
export const getTranslation = (fact, context) => {
  fact.translations = { ...context.parameters };
  return fact;
};
