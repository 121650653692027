import { Urls } from '@app/config/urls';
import { Constants } from '@app/config/constants';
import { getItem } from '@app/utils/storage';
import { HttpClient } from '@app/utils/http';

const BillingRepository = {
  /**
   * getBillingData
   *
   * @description Method for return order detail by code
   * @param {string} flow - Origin of request
   * @param {string} cursor - Cursor reference to paginate
   */
  getBillingData: (orderId, documentId, countryId, flow, signal) => {
    const url = Urls.BILLING.replace(':orderId', orderId)
      .replace(':documentId', documentId)
      .replace(':countryId', countryId);

    return HttpClient.getInstance().get(
      `${url}?mock=${getItem(Constants.MOCK_FLAG, false)}&flow=${flow}`,
      { signal },
    );
  },
};

export { BillingRepository };
