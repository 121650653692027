import { SectionRequest } from '@app/detail/domain/models';
import { Detail } from '@app/detail/domain/repositories';
import { HttpClient } from '@app/utils/http';
import { UrlsBuilder } from '@app/detail/infra/config/Urls';
import type { AxiosInstance } from 'axios';

export class DetailRepository implements Detail {
  private http: AxiosInstance;

  constructor() {
    this.http = HttpClient.getInstance();
  }

  async getSection<T>(payload: SectionRequest): Promise<T> {
    const url = UrlsBuilder.getSectionUrl(payload);
    const result = await this.http.get<T>(url, {
      params: {
        flow: payload.flow,
        mock: payload.mock,
        section: payload.section,
      },
      signal: payload.signal,
    });
    return result.data;
  }
}
