import { defineMessages } from 'react-intl';

const messages = defineMessages({
  total_title: {
    id: 'OA_orderDetail_payment_title',
    defaultMessage: 'Medios de pago',
  },
});

export default messages;
