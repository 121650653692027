import { defineMessages } from 'react-intl';

const messages = defineMessages({
  total_title: {
    id: 'OA_orderDetail_summary_total_title',
    defaultMessage: 'Total',
  },
  summary_title: {
    id: 'OA_orderDetail_summary_title',
    defaultMessage: 'Tu pago',
  },
  billing_label: {
    id: 'OA_orderDetail_summary_billing_label',
    defaultMessage: 'Descarga el detalle del costo de envío y servicio',
  },
  billing_button_download: {
    id: 'OA_orderDetail_summary_billing_button_download',
    defaultMessage: 'Descargar factura',
  },
  billing_download_error: {
    id: 'OA_orderDetail_summary_billing_download_error',
    defaultMessage: 'Error al descargar la factura, intente mas tarde',
  },
});

export default messages;
