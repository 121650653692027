import React from 'react';
import styled from 'styled-components';

const MainContainer = styled.main`
  display: flex;
  justify-content: center;
  align-content: center;
  padding-bottom: 80px;
`;

const InsideContainer = styled.div`
  max-width: 800px;
  margin-bottom: 80px;
  flex: 1;
`;

const FloatingContainer = styled.div`
  margin-bottom: '80px';
`;

export { MainContainer, InsideContainer, FloatingContainer };
