import { defineMessages } from 'react-intl';

const messages = defineMessages({
  products_title: {
    id: 'OA_orderDetail_products_title',
    defaultMessage: 'Tu pedido',
  },
  products_additional_note: {
    id: 'OA_orderDetail_products_additional_note',
    defaultMessage: 'Notas adicionales para el {vertical}',
  },
  products_note: {
    id: 'OA_orderDetail_products_note',
    defaultMessage: 'Notas',
  },
  products_see_all: {
    id: 'OA_orderDetail_products_see_all',
    defaultMessage: 'Ver todos',
  },
  products_see_less: {
    id: 'OA_orderDetail_products_see_less',
    defaultMessage: 'Ver menos',
  },
  products_add_item_title: {
    id: 'OA_orderDetail_products_add_item_title',
    defaultMessage: '¿Te falta algo de {vendor}?',
  },
  products_button_add_item: {
    id: 'OA_orderDetail_products_button_add_item',
    defaultMessage: 'Agregar productos',
  },
});

export default messages;
