import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ImageSkeleton } from '@app/components/ImageSkeleton';
import { ImageContainer } from './ImageLoader.style';

const ImageLoader = ({
  src,
  width = 48,
  height = 48,
  fallback,
  state,
  justRadiusLeft,
  borderRadius = 4,
  border = true,
  containSize = false,
  ...props
}) => {
  const [source, setSource] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const image = new Image();
    image.src = src;
    image.onload = () => {
      if (isMounted) {
        setSource(src);
      }
    };
    image.onerror = () => {
      setError(true);
    };
    return () => {
      isMounted = false;
    };
  }, [src]);

  if (error && fallback) {
    return (
      <ImageContainer
        width={width}
        height={height}
        data-testid="image"
        src={fallback}
        role="img"
        state={state}
        justRadiusLeft={justRadiusLeft}
        borderRadius={borderRadius}
        border={border}
        {...props}
      />
    );
  }

  if (!source) {
    return (
      <ImageSkeleton
        width={`${width}px`}
        height={`${height}px`}
        shape="square"
        role="img"
        data-testid="image"
      />
    );
  }

  return (
    <ImageContainer
      width={width}
      height={height}
      data-testid="image"
      src={source}
      state={state}
      role="img"
      justRadiusLeft={justRadiusLeft}
      borderRadius={borderRadius}
      border={border}
      containSize={containSize}
      {...props}
    />
  );
};

ImageLoader.defaultProps = {
  src: '',
  width: 48,
  height: 48,
  fallback: '',
  state: 'enable',
  justRadiusLeft: false,
  borderRadius: 4,
  border: true,
  containSize: false,
};

ImageLoader.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  fallback: PropTypes.string,
  state: PropTypes.oneOf(['enable', 'disable']),
  justRadiusLeft: PropTypes.bool,
  borderRadius: PropTypes.number,
  border: PropTypes.bool,
  containSize: PropTypes.bool,
};

export { ImageLoader };
