import React, { memo } from 'react';
import type { FC } from 'react';
import { useIntl } from 'react-intl';
import type { RefundItemsProps } from '@app/detail/presentation/components/Refunds/models';
import { Formatter } from '@app/components/NumberFormatter';
import {
  AmountContainer,
  DescriptionContainer,
  IconContainer,
  ItemContainer,
  Title,
  ImageContainer,
  Button,
} from './RefundItemWallet.style';
import { useLinkContext } from '@app/contexts/LinkProvider';
import { ACTIONS } from '@app/utils/deeplink';
import { RefundContingencyText } from '../RefundContingencyText';
import useIsWebView from '@app/hooks/useIsWebView';
import Translations from './RefundItemWallet.translation';
import PedidosYa from '@pedidosya/web-fenix/icons/PedidosYaPay';

export const RefundItemWallet: FC<RefundItemsProps> = memo(({ data, country }) => {
  const { formatMessage } = useIntl();
  const { redirect } = useLinkContext();
  const isWebView = useIsWebView();
  const amountFormated = Formatter(parseFloat(data.amount as any), country);

  const goToWallet = () => {
    redirect(ACTIONS.WALLET, {});
  };

  return (
    <ItemContainer>
      <DescriptionContainer>
        <ImageContainer>
          <IconContainer>
            <PedidosYa />
          </IconContainer>
        </ImageContainer>
        <div>
          <Title
            token="font-body-midcontrast-sentence-medium"
            color="text-color-action-enabled-loud"
            component="p"
          >
            {data?.description
              ? data.description
              : formatMessage(Translations['refundItemWallet.title'])}
          </Title>
          {data.contingency ? (
            <RefundContingencyText
              originalMethod={data.type as any}
              finalMethod={data.contingency}
            />
          ) : (
            <Title
              token="font-body-midcontrast-sentence-small"
              color="text-color-action-activated-quiet"
              component="p"
            >
              {formatMessage(Translations['refundItemWallet.description'])}
            </Title>
          )}
          {isWebView ? (
            <Button
              label={formatMessage(Translations['refundItemWallet.view'])}
              size="medium"
              hierarchy="tertiary"
              onClick={goToWallet}
            />
          ) : null}
        </div>
      </DescriptionContainer>
      {data.amount && parseFloat(data.amount as any) > 0.0 ? (
        <AmountContainer>
          <Title token="text-base-large" color="text-color-action-enabled-loud" component="p">
            {amountFormated}
          </Title>
        </AmountContainer>
      ) : (
        <></>
      )}
    </ItemContainer>
  );
});
