import React, { memo } from 'react';
import type { FC } from 'react';
import type { CompensationProps } from './Compensation.type';
import { Container } from '@app/components/Container';
import { CompensationContainer, Content, Elypse, Text, Link } from './Compensation.style';
import { useIntl } from 'react-intl';
import { withRetryOnError } from '@app/detail/use_cases/hoc';
import { useLinkContext } from '@app/contexts/LinkProvider';
import { ACTIONS } from '@app/utils/deeplink';
import useIsWebView from '@app/hooks/useIsWebView';
import Translations from './Compensation.translation';
import Image from '@pedidosya/web-fenix/foundations/Image';

export const Compensation: FC<CompensationProps> = memo(
  withRetryOnError(({ data, country }) => {
    const { formatMessage } = useIntl();
    const { redirect } = useLinkContext();
    const isWebView = useIsWebView();

    const gotToVoucher = () => {
      redirect(ACTIONS.COUPONS, {});
    };

    return (
      <Container pBottom="16">
        <CompensationContainer>
          <Content>
            <Text component="p" token="text-title-small" color="text-color-primary">
              {data?.result?.title}
            </Text>
            <Text
              component="p"
              token="text-base-medium"
              color="text-color-tertiary"
              margin="8px 0px 0px 0px"
            >
              {data?.result?.subtitle}
            </Text>
            <Text
              component="p"
              token="text-base-medium"
              color="text-color-tertiary"
              margin="4px 0px 0px 0px"
            >
              {data?.result?.expirationMessage}
            </Text>
            {isWebView ? (
              <Link
                component="p"
                token="text-utility-underline-medium"
                color="text-color-primary"
                margin="12px 0px 0px 0px"
                onClick={gotToVoucher}
              >
                {formatMessage(Translations['compensation.action'], { count: data.result.total })}
              </Link>
            ) : null}
            <Elypse>
              <Image
                src="https://images.dhmedia.io/image/pedidosya/order-management/compensation.png"
                alt="Coupon image"
                width="80px"
              />
            </Elypse>
          </Content>
        </CompensationContainer>
      </Container>
    );
  }),
);
