import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { TagContent, TagContianer } from './Tag.style';

const Tag = ({ label }) => {
  return (
    <TagContianer>
      <TagContent>
        <Typography component="span" token="font-body-highcontrast-sentence-small">
          {label}
        </Typography>
      </TagContent>
    </TagContianer>
  );
};

Tag.defaultProps = {
  label: '',
};

Tag.propTypes = {
  label: PropTypes.string,
};

export { Tag };
