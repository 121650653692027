import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

export const Text = styled(Typography)`
  margin: ${(props) => (props?.margin ? props?.margin : '')};
  text-align: center;
`;

export const ActionContainer = styled.div`
  text-align: center;
  & > div {
    display: inline-block;
  }
`;

export const LoaderContainer = styled.div`
  position: relative;
  min-height: 80px;
  & > div {
    left: 50%;
    margin-left: -16px;
    top: 50%;
    margin-top: -4px;
  }
`;
