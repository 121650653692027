import Engine from 'rules-js';
import { StatusRule } from './flows/status';
import { CompensationRule } from './flows/compensation';
import { checkOption, getTranslation } from './closure/status';
import { RemediesRule } from './flows/remedies';
import { OrderDetailRule } from './flows/orderDetail';
import {
  checkOption as checkOptionCompensation,
  getTranslation as getTranslationCompensation,
} from './closure/compensation';
import {
  checkOption as checkOptionRemedies,
  getTranslation as getTranslationRemedies,
} from './closure/remedies';

import { checkOrderDetail, getTranslationOrderDetail } from './closure/orderDetail';

const RuleEngine = new Engine();
RuleEngine.closures.add('checkOption', checkOption, { required: ['status', 'feedback'] });
RuleEngine.closures.add('getTranslation', getTranslation, { required: ['status', 'feedback'] });
RuleEngine.closures.add('checkOptionCompensation', checkOptionCompensation, {
  required: ['cancellationReason'],
});
RuleEngine.closures.add('getTranslationCompensation', getTranslationCompensation, {
  required: ['cancellationReason'],
});
RuleEngine.closures.add('checkOptionRemedies', checkOptionRemedies, {
  required: ['localReason'],
});
RuleEngine.closures.add('getTranslationRemedies', getTranslationRemedies, {
  required: ['localReason'],
});

RuleEngine.closures.add('checkOrderDetail', checkOrderDetail, {
  required: ['orderState'],
});
RuleEngine.closures.add('getTranslationOrderDetail', getTranslationOrderDetail, {
  required: ['orderState'],
});

RuleEngine.add(StatusRule);
RuleEngine.add(CompensationRule);
RuleEngine.add(RemediesRule);
RuleEngine.add(OrderDetailRule);

/**
 * StatusRules
 *
 * @description Function for process the params applying a rule for reasons
 * @param {Object} params - Parameters to evaluate
 * @param {string} params.status - Status of order
 * @param {boolean} params.feedback - Have feedback
 * @returns {Promise<Object>}
 */
export const StatusRules = async (params) => {
  const result = await RuleEngine.process('status', params);
  return result.fact.translations || null;
};

/**
 * CompensationRules
 *
 * @description Function for process the params applying a rule for reasons
 * @param {Object} params - Parameters to evaluate
 * @param {string} params.cancellationReason - Cancel reason
 * @returns {Promise<Object>}
 */
export const CompensationRules = async (params) => {
  const result = await RuleEngine.process('compensation', params);
  return result.fact.translations || null;
};

/**
 * RemediesRules
 *
 * @description Function for process the params applying a rule for reasons
 * @param {Object} params - Parameters to evaluate
 * @param {string} params.localReason - Cancel reason
 * @returns {Promise<Object>}
 */
export const RemediesRules = async (params) => {
  const result = await RuleEngine.process('remedies', params);
  return result.fact.translations || null;
};

/**
 * RefundDetailRules
 *
 * @description Function for process rule for main refund method in
 * ask user flow
 * @param {Object} params - Parameters to evaluate
 * @param {string} params.orderState - state of order
 * @returns {Promise<Object>}
 */
export const OrderDetailRules = async (params) => {
  const result = await RuleEngine.process('orderDetail', params);
  return result.fact.translations || null;
};
