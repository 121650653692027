import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

export const CompensationContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

export const Elypse = styled.div`
  width: 222px;
  height: 246px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.51);
  right: -100px;
  top: -10px;
  & > picture {
    position: absolute;
    top: 45px;
    left: 50%;
    margin-left: -80px;
  }
`;

export const Content = styled.div`
  z-index: 1;
  position: relative;
  background-color: #e8fbff;
  border-radius: 4px;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 136px 16px 16px;
`;

export const Text = styled(Typography)`
  word-break: break-word;
  margin: ${(props) => (props?.margin ? props.margin : '')};
`;

export const Link = styled(Typography)`
  word-break: break-word;
  margin: ${(props) => (props?.margin ? props.margin : '')};
  cursor: pointer;
`;
