export default {
  PageLoad: 'order_details.loaded',
  RepeatClicked: 'my_orders_repeat.clicked',
  RateClicked: 'my_orders_feedback.clicked',
  UseCouponClicked: 'use_coupon.clicked',
  PartnerClicked: 'go_to_partner.clicked',
  RemedyClicked: 'remedy.clicked',
  HelpClicked: 'online_help.clicked',
  SoldOutShown: 'out_of_stock.shown',
  RefundDetailClicked: 'refund_details.clicked',
};
