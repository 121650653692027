import React from 'react';
import styled from 'styled-components';

const TotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${(props) => props.theme.space('space-06')};
  padding-top: ${(props) => props.theme.space('space-08')};
  margin-top: ${(props) => props.theme.space('space-08')};
  border-top: 1px solid ${(props) => props.theme.color('shape-color-stroke-primary')};
`;

export { TotalContainer };
