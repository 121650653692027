import React from 'react';
import { getDetailByCode } from '@app/services/orderDetail';
import { useQuery } from 'react-query';
import { useUrlQuery } from '@app/hooks/useUrlQuery';
import { useParams } from 'react-router-dom';
import { sentryHub } from '@app/utils/sentry';

const useOrderByCode = () => {
  const query = useUrlQuery();
  const { code } = useParams();

  const { data, isLoading, isError } = useQuery(
    ['getDetailByCode', code],
    async ({ signal }) => getDetailByCode(code, query.get('flow') || query.get('origin'), signal),
    {
      retry: 2,
      onError: (error) => {
        sentryHub.withScope((scope) => {
          scope.setTransactionName('ErrorGetDetailByCode');
          scope.setTag('code', code);
          scope.setTag('flow', query.get('flow') || query.get('origin'));
          scope.setTag('http.status', error?.response?.status);
          scope.setTag('http.status.backend', error?.response?.data?.status);
          sentryHub.captureException(new Error(JSON.stringify(error?.response?.data)));
        });
      },
    },
  );
  return {
    data,
    isLoading,
    isError,
  };
};

export { useOrderByCode };
