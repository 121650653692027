import { useTracker } from '@app/contexts/TrackerProvider';
import { getWindow } from '@app/utils/window';

const useDetailRemediesEvents = () => {
  const { remedyClicked } = useTracker();

  const Listeners = [
    (context) => {
      /**
       * Tracking event to GTM
       */
      remedyClicked(context?.orderId, context?.orderStatus, context?.name);
    },
    (context) => {
      /**
       * Execute event to trigger deeplink or
       * web event
       */
      const wn = getWindow();
      if (wn) {
        wn.location.href = context?.link;
      }
    },
  ];

  return Listeners;
};

export { useDetailRemediesEvents };
