import { OrderDetailRepository } from '@app/repositories/orderDetail';

/**
 * getDetailByCode
 *
 * @description Function to get order detail by code
 * @param {string} code - Order Code
 * @param {string} flow - Origin of request
 * @returns {Promise<Object>}
 */
export async function getDetailByCode(code, flow, signal) {
  const result = await OrderDetailRepository.getDetailByCode(code, flow, signal);
  return result.data;
}

/**
 * getDetailByOrderId
 *
 * @description Function to get order detail by orderId
 * @param {string} orderId - Order Id
 * @param {string} flow - Origin of request
 * @returns {Promise<Object>}
 */
export async function getDetailByOrderId(orderId, flow, signal) {
  const result = await OrderDetailRepository.getDetailByOrderId(orderId, flow, signal);
  return result.data;
}

/**
 * getCardRefundDetail
 *
 * @description Function to get information about refund in card
 * @param {string} orderId - Order id
 * @param {string} paymentMethod - Payment method (CARD | VOUCHER | etc)
 * @param {string} flow - Origin request
 * @param {AbortSignal} signal
 */
export async function getCardRefundDetail(orderId, paymentMethod, flow, signal) {
  const result = await OrderDetailRepository.getCardRefundDetail(
    orderId,
    paymentMethod,
    flow,
    signal,
  );
  return result.data;
}
