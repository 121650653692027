import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import RatingIcon from '@pedidosya/web-fenix/icons/RatingIcon';
import Reload from '@pedidosya/web-fenix/icons/Reload';
import Button from '@pedidosya/web-fenix/atoms/Button';
import { ButtonSide } from './ActionsButtons.style';
import { Constants } from '@app/config/constants';
import { ACTIONS } from '@app/utils/deeplink';

const ActionsButtons = forwardRef(({ actions, onClickButton }, ref) => {
  const hasFeedback = useMemo(() => {
    return !isEmpty(actions?.feedback);
  }, [actions?.feedback]);

  const hasRepeat = useMemo(() => {
    return !isEmpty(actions?.repeat);
  }, [actions?.repeat]);

  return (
    <>
      {hasRepeat ? (
        <ButtonSide ref={ref ? ref : null} length={hasFeedback ? 2 : 1}>
          <div>
            <Button
              fullWidth={true}
              type="button"
              size="medium"
              hierarchy="primary"
              label={actions?.repeat?.title}
              icon={<Reload size="medium" />}
              onClick={() => onClickButton(ACTIONS.BASKET_RECREATION, Constants.DEEPLINK)}
            />
          </div>
        </ButtonSide>
      ) : (
        <></>
      )}
      {hasFeedback ? (
        <ButtonSide length={hasRepeat ? 2 : 1} ref={ref ? ref : null}>
          <div>
            <Button
              fullWidth={true}
              type="button"
              size="medium"
              hierarchy="secondary"
              label={actions?.feedback?.title}
              icon={<RatingIcon size="medium" />}
              onClick={() => onClickButton(ACTIONS.NEW_FEEDBACK, Constants.ROUTER)}
            />
          </div>
        </ButtonSide>
      ) : (
        <></>
      )}
    </>
  );
});

ActionsButtons.defaultProps = {
  actions: {},
  onClickButton: () => {},
};

ActionsButtons.propTypes = {
  actions: PropTypes.shape({
    feedback: PropTypes.shape({
      title: PropTypes.string,
      deeplink: PropTypes.string,
    }),
    repeat: PropTypes.shape({
      title: PropTypes.string,
      deeplink: PropTypes.string,
    }),
  }),
  onClickButton: PropTypes.func,
};

export { ActionsButtons };
