import React from 'react';
import styled from 'styled-components';

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${(props) => props.theme.space('space-06')};
  padding-bottom: ${(props) => props.theme.space('space-04')};
  padding-top: ${(props) => props.theme.space('space-04')};
`;

const AmountContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space('space-04')};
  align-items: center;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  & span {
    text-transform: uppercase;
  }
`;

const DescriptionContainer = styled(AmountContainer)``;

export { ItemContainer, AmountContainer, DescriptionContainer, ImageContainer };
