import React, { FC, PropsWithChildren } from 'react';
import * as Sentry from '@sentry/react';
import { PROD_ENVIRONMENT } from '@appscore/web-constants/environments';
import { getWindow } from '@app/utils/window';

interface Props extends PropsWithChildren {
  sentryDSN: string;
  environment: string;
  enabled: boolean;
  isServer: boolean;
}

export const SentrySSR: FC<Props> = ({ children, sentryDSN, environment, enabled, isServer }) => {
  if (isServer && getWindow()) {
    try {
      const isLive = environment === PROD_ENVIRONMENT;
      Sentry.init({
        dsn: sentryDSN,
        enabled,
        environment: environment,
        tracesSampleRate: isLive ? 0.1 : 1,
        sampleRate: isLive ? 0.1 : 1,
        integrations: [
          new Sentry.BrowserTracing({
            _metricOptions: {
              _reportAllChanges: true,
            },
          }),
        ],
        stackParser: Sentry.defaultStackParser,
        transport: Sentry.makeFetchTransport,
      });
    } catch (e) {
      console.error(e);
    }
  }

  return <>{children}</>;
};
