import React, { memo, useMemo } from 'react';
import type { FC } from 'react';
import { useIntl } from 'react-intl';
import {
  AmountContainer,
  DescriptionContainer,
  IconContainer,
  ItemContainer,
  Title,
  ImageContainer,
  Button,
} from './RefundItemVoucher.style';
import { Formatter } from '@app/components/NumberFormatter';
import type { RefundItemsProps } from '@app/detail/presentation/components/Refunds/models';
import { useLinkContext } from '@app/contexts/LinkProvider';
import { ACTIONS } from '@app/utils/deeplink';
import { RefundContingencyText } from '../RefundContingencyText';
import useIsWebView from '@app/hooks/useIsWebView';
import Coupon from '@pedidosya/web-fenix/icons/Coupon';
import Translations from './RefundItemVoucher.translation';

export const RefundItemVoucher: FC<RefundItemsProps> = memo(({ data, country }) => {
  const { formatMessage } = useIntl();
  const { redirect } = useLinkContext();
  const isWebView = useIsWebView();
  const amountFormated = Formatter(parseFloat(data.amount as any), country);

  const goToVouchers = () => {
    redirect(ACTIONS.COUPONS, {});
  };

  const totalVouchers = useMemo(() => {
    if (data && data.operations) {
      const result = data.operations.reduce((acc, operation) => {
        acc += operation.movements.length;
        return acc;
      }, 0);

      return Math.max(1, result);
    }

    return 1;
  }, [data]);

  return (
    <ItemContainer>
      <DescriptionContainer>
        <ImageContainer>
          <IconContainer>
            <Coupon />
          </IconContainer>
        </ImageContainer>
        <div>
          <Title
            token="font-body-midcontrast-sentence-medium"
            color="text-color-action-enabled-loud"
            component="p"
          >
            {formatMessage(Translations['refundItemVoucher.title'], { count: totalVouchers })}
          </Title>
          {data.contingency ? (
            <RefundContingencyText
              originalMethod={data.type as any}
              finalMethod={data.contingency}
              count={totalVouchers}
            />
          ) : (
            <Title
              token="font-body-midcontrast-sentence-small"
              color="text-color-action-activated-quiet"
              component="p"
            >
              {formatMessage(Translations['refundItemVoucher.description'], {
                count: totalVouchers,
              })}
            </Title>
          )}
          {isWebView && data?.showAction === true ? (
            <Button
              label={formatMessage(Translations['refundItemVoucher.view'], {
                count: totalVouchers,
              })}
              size="medium"
              hierarchy="tertiary"
              onClick={goToVouchers}
            />
          ) : null}
        </div>
      </DescriptionContainer>
      {data.amount && parseFloat(data.amount as any) > 0.0 ? (
        <AmountContainer>
          <Title token="text-base-large" color="text-color-action-enabled-loud" component="p">
            {amountFormated}
          </Title>
        </AmountContainer>
      ) : (
        <></>
      )}
    </ItemContainer>
  );
});
