import React, { useEffect } from 'react';
import pkg from '../../../package.json';
import { sentryHub } from '@app/utils/sentry';
import { usePublicEnv } from '@app/contexts/PublicEnv';

const SentryProvider = ({ children, isServer }) => {
  const { userId, environment, country, device, deviceOS, user } = usePublicEnv();

  useEffect(() => {
    try {
      sentryHub.init({ dsn: SENTRY_DSN, environment, isServer });
      sentryHub.setUser({ id: userId || user?.id });
      sentryHub.setTag('country', country?.shortName);
      sentryHub.setTag('templateVersion', pkg.templateVersion);
      sentryHub.setTag('origin_env', 'frontend');
      sentryHub.setTag('device', device);
      sentryHub.setTag('deviceOS', deviceOS);
    } catch (error) {
      console.error('Error initializing sentry hub', error.message);
    }
  }, [environment, userId, country?.shortName, device, deviceOS, user?.id]);

  return children;
};

export { SentryProvider };
