import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Container } from '@app/components/Container';
import { useIntl } from 'react-intl';
import { PaymentsCode } from '@app/config/paymentsCode';
import { Items } from './Items';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Translations from './DetailExtraPayment.translation';

const DetailExtraPayment = ({ payments, country }) => {
  const { formatMessage } = useIntl();

  const items = useMemo(() => {
    return payments.map((element) => {
      if (PaymentsCode[element.code]) {
        return { ...element, icon: PaymentsCode[element.code] };
      }
      return element;
    });
  }, [payments]);

  if (payments.length === 0) {
    return <></>;
  }

  return (
    <Container pBottom="16" pTop="20">
      <Typography
        component="h6"
        token="font-subtitle-highcontrast-sentence-large"
        color="text-color-action-enabled-loud"
      >
        {formatMessage(Translations.extraPayment_title)}
      </Typography>
      <Container pTop="12" pBottom="0" pRight="0" pLeft="0">
        {items.map((element) => {
          return <Items key={element?.code} {...element} country={country} />;
        })}
      </Container>
    </Container>
  );
};

DetailExtraPayment.defaultProps = {
  payments: [],
  country: '',
};

DetailExtraPayment.propTypes = {
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      amount: PropTypes.number,
      description: PropTypes.string,
      code: PropTypes.string,
    }),
  ),
  country: PropTypes.string,
};

export { DetailExtraPayment };
