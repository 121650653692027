import { Detail } from '@shared/models/Detail';
import { OrderState } from '@app/detail/infra/config/OrderState';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

export class DetailProxy {
  private data: Detail;
  constructor(data: Detail) {
    this.data = data;
  }

  public hasRefund() {
    return !isEmpty(this.data?.refund);
  }

  public hasCompensation() {
    return !isEmpty(this.data?.compensation);
  }

  public hasCompensationandOrRefund() {
    return this.hasRefund() || this.hasCompensation();
  }

  public hasExtraPayments() {
    const status = this.data?.order?.status ? this.data.order.status.toUpperCase() : '';
    return (
      isArray(this.data.collectionsRequests) &&
      this.data.collectionsRequests.length > 0 &&
      status !== OrderState.CANCELLED
    );
  }

  public hasStatus() {
    const status = this.data?.order?.status ? this.data.order.status.toUpperCase() : '';
    return status !== OrderState.PENDING;
  }
}
