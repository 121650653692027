export enum Constants {
  FLOW_PARAM = 'flow',
  SAFE_PARAM = 'hasSafe',
  BANK_TRANSFER_DAYS = 7,
  UPDATE_AMOUNT_IMAGE = 'https://images.dhmedia.io/image/pedidosya/order-management/updateAmount.jpg',
  VOID_IMAGE = 'https://images.dhmedia.io/image/pedidosya/order-management/canceledPayment.jpg',
  CREDIT_IMAGE = 'https://images.dhmedia.io/image/pedidosya/order-management/refundedPayment.jpg',
  CREDIT_DAYS = 7,
  VOID_DAYS = 7,
  NOTCH_MIN_RESOLUTION = 812,
}
