import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: top;
`;

export const IconContainer = styled.div`
  height: 24px;
`;
