import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Translation from '../DetailSummary.translation';
import { useIntl } from 'react-intl';
import { NumberFormatter } from '@app/components/NumberFormatter';
import { TotalContainer } from './TotalItem.style';

const TotalItem = memo(({ total, currencyIso, country }) => {
  const { formatMessage } = useIntl();
  return (
    <TotalContainer>
      <Typography
        token="font-subtitle-highcontrast-sentence-large"
        color="text-color-action-enabled-loud"
        component="span"
      >
        {formatMessage(Translation.total_title)}
      </Typography>
      <Typography
        token="font-subtitle-highcontrast-sentence-large"
        color="text-color-action-enabled-loud"
        component="span"
      >
        <NumberFormatter amount={total} currencyIso={currencyIso} country={country} />
      </Typography>
    </TotalContainer>
  );
});

TotalItem.defaultProps = {
  total: 0,
  currencyIso: 'UYU',
  country: '',
};

TotalItem.propTypes = {
  total: PropTypes.number.isRequired,
  currencyIso: PropTypes.string.isRequired,
  country: PropTypes.string,
};

export { TotalItem };
