import styled from 'styled-components';

export const TagContianer = styled.div`
  display: inline-flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  width: fit-content;
  padding: 0px 4px;
  height: 20px;
  color: ${(props) => props.theme.color('shape-color-surface-primary')};
  background-color: ${(props) => props.theme.color('shape-color-surface-feedback-informative')};
  border-radius: 2px;
`;

export const TagContent = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  gap: 0px 2px;
`;
