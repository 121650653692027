import React, { memo } from 'react';
import type { FC } from 'react';
import type { RefundItemsProps } from '@app/detail/presentation/components/Refunds/models';
import {
  AmountContainer,
  DescriptionContainer,
  ItemContainer,
  Title,
  ImageContainer,
  Button,
} from './RefundItemCard.style';
import { ImageLoader } from '@app/components/ImageLoader';
import { useRefundItemCard } from './useRefundItemCard';

export const RefundItemCard: FC<RefundItemsProps> = memo(
  ({ data, country, orderId, orderState }) => {
    const { message, goToDetail } = useRefundItemCard(data, orderId as number, country, orderState);

    return (
      <ItemContainer>
        <DescriptionContainer>
          <ImageContainer>
            <ImageLoader
              src={data.image}
              width={32}
              height={32}
              alt="Payment icon"
              containSize={true}
            />
          </ImageContainer>
          <div>
            <Title
              token="font-body-midcontrast-sentence-medium"
              color="text-color-action-enabled-loud"
              component="p"
            >
              {data.description}
            </Title>
            {message ? (
              <>
                <Title
                  token="font-body-midcontrast-sentence-small"
                  color="text-color-action-activated-quiet"
                  component="p"
                >
                  {message?.message}
                </Title>
                <Button
                  label={message?.action}
                  size="medium"
                  hierarchy="tertiary"
                  onClick={() => goToDetail(message.operationType)}
                />
              </>
            ) : null}
          </div>
        </DescriptionContainer>
        {message ? (
          <AmountContainer>
            {message.amount ? (
              <Title token="text-base-large" color="text-color-action-enabled-loud" component="p">
                {message.amount}
              </Title>
            ) : null}
            {message.updateAmount ? (
              <Title token="text-utility-strikethrough" color="text-color-tertiary" component="p">
                {message.updateAmount}
              </Title>
            ) : null}
          </AmountContainer>
        ) : (
          <></>
        )}
      </ItemContainer>
    );
  },
);
