import { defineMessages } from 'react-intl';

export default defineMessages({
  'retryOnError.title': {
    id: 'retryOnError.title',
    defaultMessage: 'No se pudo cargar esta sección.',
  },
  'retryOnError.action': {
    id: 'retryOnError.action',
    defaultMessage: 'Reintentar',
  },
});
