import styled from 'styled-components';

const ButtonSide = styled.div`
  width: ${(props) => (props.length > 1 ? '50%' : '100%')};
  & > div {
    padding-right: 8px;
    padding-left: 8px;
  }
  &:last-child {
    & > div {
      padding-right: 0px;
    }
  }
  &:first-child {
    & > div {
      padding-left: 0px;
    }
  }
`;

export { ButtonSide };
