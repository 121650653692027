import React, { useState, useEffect, useCallback } from 'react';
import { AddressCode } from '@app/config/addressCode';
import { ShippingType } from '@app/config/shippingType';

const useDetailAddress = (addresses, note, deliveryType, openSection) => {
  const [open, setOpen] = useState(openSection);
  const [items, setItems] = useState([]);

  /**
   * addDeliveryType
   *
   * @description Function to add delivery tpe to items and return
   * object with translation key and icon
   */
  const addDeliveryType = useCallback(() => {
    return AddressCode[deliveryType];
  }, [deliveryType]);

  /**
   * addNote
   *
   * @description Function to add note to items and return
   * object with translation key and icon
   */
  const addNote = useCallback(() => {
    return { ...AddressCode.NOTE, value: note };
  }, [note]);

  /**
   * addAddress
   *
   * @description Function to add address to items. this function
   * have logic to determinate if existe only one address or more
   * and return the correct array-object with translation key and icon
   */
  const addAddress = useCallback(() => {
    const result = [];
    if (addresses.length === 1) {
      result.push({ ...AddressCode.ADDRESS, value: addresses[0].address });
      /**
       * If there are more address, find wich one is the origin and the rest
       * add like destination
       */
    } else if (addresses.length > 1) {
      addresses.forEach((element) => {
        if (ShippingType.PICKUP === element.type) {
          result.push({ ...AddressCode.ADDRESS_ORIGIN, value: element.address });
        } else {
          result.push({ ...AddressCode.ADDRESS_DESTINATION, value: element.address });
        }
      });
    }

    return result;
  }, [addresses]);

  useEffect(() => {
    const itemsResult = [];

    const type = addDeliveryType();
    if (type) {
      itemsResult.push(addDeliveryType());
    }

    itemsResult.push(...addAddress());
    if (note) {
      itemsResult.push(addNote());
    }

    setItems(itemsResult);
  }, [addAddress, addNote, addDeliveryType, note, deliveryType]);

  return {
    setOpen,
    open,
    items,
  };
};

export { useDetailAddress };
