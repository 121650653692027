import React, { memo, useMemo } from 'react';
import type { FC } from 'react';
import type { RefundItemListProps } from './RefundItemList.type';
import { RefundItem } from '@app/detail/presentation/components/Refunds/components/RefundItem';
import { SectionContainer, Text } from './RefundItemList.style';
import { useIntl } from 'react-intl';
import { Method } from '@root/shared/models';
import { PaymentMethods } from '@app/config/paymentMethods';
import Translations from './RefundItemList.translation';
import Some from 'lodash/some';

export const RefundItemList: FC<RefundItemListProps> = memo(
  ({ cancellations = [], refunds = [], country, orderId, updates = [], orderState = '' }) => {
    const { formatMessage } = useIntl();
    const items = useMemo(() => {
      const elements = [];
      if (cancellations && cancellations.length) {
        elements.push({
          title: formatMessage(Translations['refundItemList.void_title']),
          data: cancellations,
        });
      }

      if (refunds && refunds.length) {
        elements.push({
          title: Some(
            refunds,
            (element: Method) =>
              element.type.toUpperCase() === PaymentMethods.CARD ||
              cancellations.length > 0 ||
              updates.length > 0,
          )
            ? formatMessage(Translations['refundItemList.credit_title'])
            : '',
          data: refunds,
        });
      }

      if (updates && updates.length) {
        elements.push({
          title: formatMessage(Translations['refundItemList.update_amount_title']),
          data: updates,
        });
      }

      return elements;
    }, [cancellations, refunds, updates]);

    return (
      <>
        {items.map((element, index) => {
          return (
            <SectionContainer margin="12px 0px 0px 0px" key={`section-${index}`}>
              {element.title ? (
                <Text component="p" token="text-title-small" margin="0px 0px 16px 0px">
                  {element.title}
                </Text>
              ) : null}
              {element?.data.map((item, index) => {
                return (
                  <RefundItem
                    key={`${item.type}-${index}`}
                    country={country}
                    orderId={orderId}
                    data={item}
                    orderState={orderState}
                  />
                );
              })}
            </SectionContainer>
          );
        })}
      </>
    );
  },
);
