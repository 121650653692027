import React from 'react';
import styled from 'styled-components';

const Divider = styled.div`
  background-color: #efedf0;
  width: 100%;
  height: ${(props) => (props.height ? `${props.height}px` : '8px')};
  margin-top: ${(props) => (props.mTop ? `${props.mTop}px` : '0px')};
  margin-bottom: ${(props) => (props.mBottom ? `${props.mBottom}px` : '0px')};
  margin-left: ${(props) => (props.mLeft ? `${props.mLeft}px` : '0px')};
  margin-right: ${(props) => (props.mRight ? `${props.mRight}px` : '0px')};
`;

export { Divider };
