import { defineMessages } from 'react-intl';

export default defineMessages({
  'compensation.title': {
    id: 'compensation.title',
    defaultMessage: '{amount} de compensación',
  },
  'compensation.description': {
    id: 'compensation.description',
    defaultMessage:
      '{count, plural, one {Te acreditamos # cupón por el problema con tu pedido.} other {Te acreditamos # cupones por el problema con tu pedido.}}',
  },
  'compensation.expiration': {
    id: 'compensation.expiration',
    defaultMessage: '{count, plural, one {Usalo antes del {date}} other {Usalos antes del {date}}}',
  },
  'compensation.action': {
    id: 'compensation.action',
    defaultMessage: '{count, plural, one {Ver cupón} other {Ver cupones}}',
  },
});
