import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Tag from '@pedidosya/web-fenix/atoms/Tag';
import Translations from '@app/translations/globalTranslations';
import { useIntl } from 'react-intl';
import { ImageLoader } from '@app/components/ImageLoader';
import {
  ItemContainer,
  AmountContainer,
  DescriptionContainer,
  ImageContainer,
} from './SummaryItem.style';
import { NumberFormatter } from '@app/components/NumberFormatter';

const SummaryItem = memo(
  ({ description, image, amount, amountNoDiscount, currencyIso, country }) => {
    const { formatMessage } = useIntl();
    /**
     * getDiscount
     *
     * @description Function to determinate if amount and discount are differents
     * if are differents so, will return the value of amountNoDiscount or Zero if are equals
     * this will help to backend service than return the same values for amount and amountNoDiscount
     * when there is not a real discount
     * @returns {number}
     */
    const getDiscount = () => {
      if (amountNoDiscount && parseFloat(amountNoDiscount) > 0.0 && amount !== amountNoDiscount) {
        return amountNoDiscount;
      }

      return 0;
    };
    const amountDiscount = getDiscount();
    /**
     * showFreeShipping
     *
     * @description Function to determinate if show or hide the label with the word "GRATIS"
     * this ocurred when shipping cost is zero because user have PeyaPlus
     */
    const showFreeShipping = useMemo(() => {
      if (amountNoDiscount && parseFloat(amountNoDiscount) > 0.0 && parseFloat(amount) === 0.0) {
        return true;
      }

      return false;
    }, [amount, amountNoDiscount]);
    return (
      <ItemContainer>
        <DescriptionContainer>
          <Typography
            token="font-label-midcontrast-medium"
            color="text-color-action-enabled-loud"
            component="p"
          >
            {description}
          </Typography>
          {image && (
            <ImageContainer>
              <ImageLoader
                src={image}
                alt="Discount icon"
                width={showFreeShipping ? 24 : 16}
                height={showFreeShipping ? 20 : 16}
                justRadiusLeft={showFreeShipping}
                border={false}
                borderRadius={2}
              />
              {showFreeShipping && (
                <Tag
                  category="deals-and-discounts"
                  label={formatMessage(Translations.summary_order_free)}
                  borderRadius="right"
                />
              )}
            </ImageContainer>
          )}
        </DescriptionContainer>
        <AmountContainer>
          {amountDiscount ? (
            <Typography
              token="font-body-midcontrast-strikethrough-small"
              color="text-color-action-disabled-default"
              component="span"
            >
              <NumberFormatter
                amount={amountDiscount}
                currencyIso={currencyIso}
                country={country}
              />
            </Typography>
          ) : (
            <></>
          )}
          <Typography
            token="font-label-midcontrast-medium"
            color="text-color-action-enabled-loud"
            component="span"
          >
            <NumberFormatter amount={amount} currencyIso={currencyIso} country={country} />
          </Typography>
        </AmountContainer>
      </ItemContainer>
    );
  },
);

SummaryItem.defaultProps = {
  description: '',
  image: '',
  amount: 0,
  amountNoDiscount: 0,
  currencyIso: 'UYU',
  country: '',
};

SummaryItem.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  amount: PropTypes.number.isRequired,
  amountNoDiscount: PropTypes.number,
  currencyIso: PropTypes.string.isRequired,
  country: PropTypes.string,
};

export { SummaryItem };
