import { defineMessages } from 'react-intl';

export default defineMessages({
  'refundContingencyText.voucher': {
    id: 'refundContingencyText.voucher',
    defaultMessage:
      '{count, plural, one {Te devolvimos tu dinero en un cupón porque falló tu reembolso a {instrument}.} other {Te devolvimos tu dinero en # cupones porque falló tu reembolso a {instrument}.}}',
  },
  'refundContingencyText.wallet': {
    id: 'refundContingencyText.wallet',
    defaultMessage: 'Te devolvimos el dinero a tu saldo porque falló tu reembolso a {instrument}.',
  },
  'refundContingencyText.default_wallet': {
    id: 'refundContingencyText.default_wallet',
    defaultMessage:
      'Te devolvimos el dinero a tu saldo porque falló tu reembolso al medio de pago original.',
  },
  'refundContingencyText.default_voucher': {
    id: 'refundContingencyText.default_voucher',
    defaultMessage:
      '{count, plural, one {Te devolvimos tu dinero en un cupón porque falló tu reembolso al medio de pago original.} other {Te devolvimos tu dinero en # cupones porque falló tu reembolso al medio de pago original.}}',
  },
  'refundContingencyText.not_refund_wallet': {
    id: 'refundContingencyText.not_refund_wallet',
    defaultMessage:
      'Te devolvimos el dinero a tu saldo porque tu medio de pago original no soporta devoluciones',
  },
  'refundContingencyText.not_refund_voucher': {
    id: 'refundContingencyText.not_refund_voucher',
    defaultMessage:
      '{count, plural, one {Te devolvimos tu dinero en un cupón porque tu medio de pago original no soporta devoluciones.} other {Te devolvimos tu dinero en # cupones porque tu medio de pago original no soporta devoluciones.}}',
  },
});
