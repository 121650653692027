import React from 'react';
import { Constants } from '@app/config/constants';
import { ACTIONS } from '@app/utils/deeplink';
import { Verticals } from '@app/config/verticals';
import OrderCompleted from '@app/assets/images/order_completed.svg';
import OrderCancelled from '@app/assets/images/order_cancelled.svg';
import OrderScheduled from '@app/assets/images/order_scheduled.svg';
import CourierOrderCompleted from '@app/assets/images/courier_order_completed.svg';
import CourierOrderCancelled from '@app/assets/images/courier_order_cancelled.svg';
import CourierOrderScheduled from '@app/assets/images/courier_order_scheduled.svg';
import Reload from '@pedidosya/web-fenix/icons/Reload';
import Rating from '@pedidosya/web-fenix/icons/RatingIcon';

export const StatusRule = {
  name: 'status',
  rules: [
    {
      name: 'Order Complete',
      description: 'Order complete without rating',
      when: {
        closure: 'checkOption',
        status: Constants.DELIVERED_STATE,
        feedback: false,
        cancellationReason: [],
        businessType: '*',
        exceptBusinessType: [Verticals.COURIER],
      },
      then: {
        closure: 'getTranslation',
        title: 'state_delivered',
        buttons: [
          {
            title: 'button_repeat_order',
            type: 'deeplink',
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            icon: <Reload size="small" />,
            main: true,
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
        ],
        icon: OrderCompleted,
        status: Constants.DELIVERED_STATE,
        feedback: false,
      },
    },
    {
      name: 'Order Complete + feedback',
      description: 'Order complete with rating',
      when: {
        closure: 'checkOption',
        status: Constants.DELIVERED_STATE,
        feedback: true,
        cancellationReason: [],
        businessType: '*',
        exceptBusinessType: [Verticals.COURIER],
      },
      then: {
        closure: 'getTranslation',
        title: 'state_delivered',
        buttons: [
          {
            title: 'button_repeat_order',
            type: 'deeplink',
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            icon: <Reload size="small" />,
            main: true,
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_feedback',
            type: 'navigate',
            value: ACTIONS.FEEDBACK_WEB,
            style: 'secondary',
            icon: <Rating size="small" />,
            main: false,
          },
        ],
        icon: OrderCompleted,
        status: Constants.DELIVERED_STATE,
        feedback: true,
      },
    },
    {
      name: 'Order Scheduled',
      description: 'Order scheduled',
      when: {
        closure: 'checkOption',
        status: Constants.SCHEDULED_STATE,
        feedback: false,
        cancellationReason: [],
        businessType: '*',
        exceptBusinessType: [Verticals.COURIER],
      },
      then: {
        closure: 'getTranslation',
        title: 'state_scheduled',
        buttons: [],
        status: Constants.SCHEDULED_STATE,
        feedback: false,
        icon: OrderScheduled,
      },
    },
    {
      name: 'Order cancelled + Restaurant no taken order',
      description: 'Order cancelled can not take the order',
      when: {
        closure: 'checkOption',
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancellationReason: [
          'BAD_LOCATION',
          'BILLING_PROBLEM',
          'ITEM_UNAVAILABLE',
          'MENU_ACCOUNT_SETTINGS',
          'NO_PICKER',
          'NO_RESPONSE',
          'TOO_BUSY',
          'UNREACHABLE',
          'WILL_NOT_WORK_WITH_PLATFORM',
        ],
        businessType: 'RESTAURANT',
      },
      then: {
        closure: 'getTranslation',
        title: 'state_cancelled',
        buttons: [],
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancelReason: 'cancel_reasons_restaurant_taken',
        icon: OrderCancelled,
      },
    },
    {
      name: 'Order cancelled + Store no taken order',
      description: 'Order cancelled can not take the order',
      when: {
        closure: 'checkOption',
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancellationReason: [
          'BAD_LOCATION',
          'BILLING_PROBLEM',
          'ITEM_UNAVAILABLE',
          'MENU_ACCOUNT_SETTINGS',
          'NO_PICKER',
          'NO_RESPONSE',
          'TOO_BUSY',
          'UNREACHABLE',
          'WILL_NOT_WORK_WITH_PLATFORM',
        ],
        businessType: '*',
        exceptBusinessType: [Verticals.RESTAURANT, Verticals.COURIER],
      },
      then: {
        closure: 'getTranslation',
        title: 'state_cancelled',
        buttons: [],
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancelReason: 'cancel_reasons_store_taken',
        icon: OrderCancelled,
      },
    },
    {
      name: 'Order cancelled + Restaurant closed',
      description: 'Order cancelled restaurant is closed',
      when: {
        closure: 'checkOption',
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancellationReason: ['CLOSED'],
        businessType: 'RESTAURANT',
      },
      then: {
        closure: 'getTranslation',
        title: 'state_cancelled',
        buttons: [],
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancelReason: 'cancel_reasons_restaurant_closed',
        icon: OrderCancelled,
      },
    },
    {
      name: 'Order cancelled + Store closed',
      description: 'Order cancelled Store is closed',
      when: {
        closure: 'checkOption',
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancellationReason: ['CLOSED'],
        businessType: '*',
        exceptBusinessType: [Verticals.RESTAURANT, Verticals.COURIER],
      },
      then: {
        closure: 'getTranslation',
        title: 'state_cancelled',
        buttons: [],
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancelReason: 'cancel_reasons_store_closed',
        icon: OrderCancelled,
      },
    },
    {
      name: 'Order cancelled + Outside hours',
      description: 'Order cancelled Outside hours',
      when: {
        closure: 'checkOption',
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancellationReason: ['OUTSIDE_SERVICE_HOURS'],
        businessType: '*',
      },
      then: {
        closure: 'getTranslation',
        title: 'state_cancelled',
        buttons: [],
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancelReason: 'cancel_reasons_any_outside_hours',
        icon: OrderCancelled,
      },
    },
    {
      name: 'Order cancelled + Bad Weather',
      description: 'Order cancelled bad weather',
      when: {
        closure: 'checkOption',
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancellationReason: ['BAD_WEATHER'],
        businessType: '*',
      },
      then: {
        closure: 'getTranslation',
        title: 'state_cancelled',
        buttons: [],
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancelReason: 'cancel_reasons_any_bad_weather',
        icon: OrderCancelled,
      },
    },
    {
      name: 'Order cancelled + Any Other Reason',
      description: 'Order cancelled bad weather',
      when: {
        closure: 'checkOption',
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancellationReason: '*',
        cancellationReasonExcept: [
          'BAD_WEATHER',
          'OUTSIDE_SERVICE_HOURS',
          'CLOSED',
          'BAD_LOCATION',
          'BILLING_PROBLEM',
          'ITEM_UNAVAILABLE',
          'MENU_ACCOUNT_SETTINGS',
          'NO_PICKER',
          'NO_RESPONSE',
          'TOO_BUSY',
          'UNREACHABLE',
          'WILL_NOT_WORK_WITH_PLATFORM',
        ],
        businessType: '*',
      },
      then: {
        closure: 'getTranslation',
        title: 'state_cancelled',
        buttons: [],
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancelReason: '',
        icon: OrderCancelled,
      },
    },
    {
      name: 'Order Complete Courier',
      description: 'Order complete without rating',
      when: {
        closure: 'checkOption',
        status: Constants.DELIVERED_STATE,
        feedback: false,
        cancellationReason: [],
        businessType: Verticals.COURIER,
      },
      then: {
        closure: 'getTranslation',
        title: 'state_delivered',
        buttons: [
          {
            title: 'button_repeat_order',
            type: 'deeplink',
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            icon: <Reload size="small" />,
            main: true,
          },
        ],
        icon: CourierOrderCompleted,
        status: Constants.DELIVERED_STATE,
        feedback: false,
      },
    },
    {
      name: 'Order Complete + feedback Courier',
      description: 'Order complete with rating',
      when: {
        closure: 'checkOption',
        status: Constants.DELIVERED_STATE,
        feedback: true,
        cancellationReason: [],
        businessType: Verticals.COURIER,
      },
      then: {
        closure: 'getTranslation',
        title: 'state_delivered',
        buttons: [
          {
            title: 'button_repeat_order',
            type: 'deeplink',
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            icon: <Reload size="small" />,
            main: true,
          },
          {
            title: 'button_feedback',
            type: 'navigate',
            value: ACTIONS.FEEDBACK_WEB,
            style: 'secondary',
            icon: <Rating size="small" />,
            main: false,
          },
        ],
        icon: CourierOrderCompleted,
        status: Constants.DELIVERED_STATE,
        feedback: true,
      },
    },
    {
      name: 'Order Scheduled Courier',
      description: 'Order scheduled',
      when: {
        closure: 'checkOption',
        status: Constants.SCHEDULED_STATE,
        feedback: false,
        cancellationReason: [],
        businessType: Verticals.COURIER,
      },
      then: {
        closure: 'getTranslation',
        title: 'state_scheduled',
        buttons: [],
        status: Constants.SCHEDULED_STATE,
        feedback: false,
        icon: CourierOrderScheduled,
      },
    },
    {
      name: 'Order cancelled + Courier no taken order Courier',
      description: 'Order cancelled can not take the order',
      when: {
        closure: 'checkOption',
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancellationReason: [
          'BAD_LOCATION',
          'BILLING_PROBLEM',
          'ITEM_UNAVAILABLE',
          'MENU_ACCOUNT_SETTINGS',
          'NO_PICKER',
          'NO_RESPONSE',
          'TOO_BUSY',
          'UNREACHABLE',
          'WILL_NOT_WORK_WITH_PLATFORM',
        ],
        businessType: Verticals.COURIER,
      },
      then: {
        closure: 'getTranslation',
        title: 'state_cancelled',
        buttons: [],
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancelReason: 'cancel_reasons_restaurant_taken',
        icon: CourierOrderCancelled,
      },
    },
    {
      name: 'Order cancelled + Courier closed',
      description: 'Order cancelled courier is closed',
      when: {
        closure: 'checkOption',
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancellationReason: ['CLOSED'],
        businessType: Verticals.COURIER,
      },
      then: {
        closure: 'getTranslation',
        title: 'state_cancelled',
        buttons: [],
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancelReason: 'cancel_reasons_restaurant_closed',
        icon: CourierOrderCancelled,
      },
    },
    {
      name: 'Order cancelled + Outside hours',
      description: 'Order cancelled Outside hours',
      when: {
        closure: 'checkOption',
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancellationReason: ['OUTSIDE_SERVICE_HOURS'],
        businessType: Verticals.COURIER,
      },
      then: {
        closure: 'getTranslation',
        title: 'state_cancelled',
        buttons: [],
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancelReason: 'cancel_reasons_any_outside_hours',
        icon: CourierOrderCancelled,
      },
    },
    {
      name: 'Order cancelled + Bad Weather',
      description: 'Order cancelled bad weather',
      when: {
        closure: 'checkOption',
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancellationReason: ['BAD_WEATHER'],
        businessType: Verticals.COURIER,
      },
      then: {
        closure: 'getTranslation',
        title: 'state_cancelled',
        buttons: [],
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancelReason: 'cancel_reasons_any_bad_weather',
        icon: CourierOrderCancelled,
      },
    },
    {
      name: 'Order cancelled + Any Other Reason',
      description: 'Order cancelled bad weather',
      when: {
        closure: 'checkOption',
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancellationReason: '*',
        cancellationReasonExcept: [
          'BAD_WEATHER',
          'OUTSIDE_SERVICE_HOURS',
          'CLOSED',
          'BAD_LOCATION',
          'BILLING_PROBLEM',
          'ITEM_UNAVAILABLE',
          'MENU_ACCOUNT_SETTINGS',
          'NO_PICKER',
          'NO_RESPONSE',
          'TOO_BUSY',
          'UNREACHABLE',
          'WILL_NOT_WORK_WITH_PLATFORM',
        ],
        businessType: Verticals.COURIER,
      },
      then: {
        closure: 'getTranslation',
        title: 'state_cancelled',
        buttons: [],
        status: Constants.CANCELLED_STATE,
        feedback: false,
        cancelReason: '',
        icon: CourierOrderCancelled,
      },
    },
  ],
};
