import styled from 'styled-components';

const Content = styled.div`
  margin-left: ${(props) => props.theme.space('space-08')};
  display: flex;
  flex-flow: column wrap;
`;
const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: ${(props) => props.theme.space('space-10')};
`;

const ButtonSide = styled.div`
  width: ${(props) => (props.length > 1 ? '50%' : '100%')};
  & > div {
    padding-right: 8px;
    padding-left: 8px;
  }
  &:last-child {
    & > div {
      padding-right: 0px;
    }
  }
  &:first-child {
    & > div {
      padding-left: 0px;
    }
  }
`;

const StartContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.theme.space('space-02')};
  & > span {
    margin-right: 4px;
  }
  & > span:last-child {
    margin-left: 4px;
  }
`;

const FloatingMainAction = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  box-shadow: 0px -4px 8px rgba(16, 4, 35, 0.12);
  background-color: ${(props) => props.theme.color('shape-color-background-primary')};
  padding-bottom: ${(props) => (props.isIOS ? props.theme.space('space-08') : '0px')};
  z-index: 3;
  & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const StackContainer = styled.div`
  display: flex;
  flex-grow: ${(props) => (props?.grow ? props.grow : 0)};
  flex-shrink: ${(props) => (props?.shrink ? props?.shrink : 0)};
  flex-direction: ${(props) => (props?.direction ? props.direction : 'column')};
  flex-wrap: ${(props) => (props?.wrap ? props.wrap : 'nowrap')};
  align-items: ${(props) => (props?.alignItems ? props?.alignItems : 'center')};
  margin: ${(props) => (props?.margin ? props?.margin : '0px')};
  justify-content: ${(props) => (props?.justify ? props?.justify : 'left')};
  width: ${(props) => (props?.width ? props?.width : 'auto')};
`;

export {
  ActionsContainer,
  Content,
  StartContainer,
  FloatingMainAction,
  ButtonSide,
  StackContainer,
};
