import React from 'react';
import { loadableReady } from '@loadable/component';
import { BrowserRouter } from 'react-router-dom';
import App from '@app/root.component';

function beforeHydratation({ tracker }) {
  return new Promise(async (resolve, reject) => {
    loadableReady(async () => {
      resolve({
        App: function AppWrapper() {
          return (
            <BrowserRouter>
              <App tracker={tracker} />
            </BrowserRouter>
          );
        },
      });
    }).catch((e) => {
      reject(e);
    });
  });
}

export default beforeHydratation;
