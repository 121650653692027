import { sentryHub } from '@app/utils/sentry';
import isFunction from 'lodash/isFunction';

function useWebViewEvents() {
  const fire = (name, params = {}) => {
    /**
     * Checking if webview is a device apple and have the interface
     * for send message to window
     * @param ListenEventsInterface is a interface provided by iOS developer
     */
    if (window.webkit && window.webkit.messageHandlers) {
      const scopes = window.webkit.messageHandlers;
      if (
        scopes['ListenEventsInterface'] &&
        isFunction(scopes['ListenEventsInterface']?.postMessage)
      ) {
        scopes['ListenEventsInterface'].postMessage({ event: name, ...params });
      }
      /**
       * Checking if webview is a device android and have the interface
       * for send message to window
       * @param OrderActionsWebEvents is a interface provided by Android Developer
       */
    } else if (window['ReviewWebEvents'] && isFunction(window['ReviewWebEvents']['send'])) {
      window['ReviewWebEvents']['send'](JSON.stringify({ event: name, ...params }));
    }
  };

  return {
    fire,
  };
}

export { useWebViewEvents };
