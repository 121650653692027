import React, { JSXElementConstructor, useMemo } from 'react';
import { useRetrySectionOnError } from '@app/detail/use_cases/hooks';
import { SectionComponent } from '@app/detail/domain/models';
import { useUrlQuery } from '@app/hooks/useUrlQuery';
import { Constants } from '@app/detail/infra/config/Constants';
import { RetryOnError } from '@app/detail/presentation/components';

export const withRetryOnError =
  (Component: JSXElementConstructor<any>) => (props: SectionComponent<any>) => {
    const query = useUrlQuery();
    const flow = useMemo(() => query.get(Constants.FLOW_PARAM) || '', []);

    const { refetch, isLoading, isError, data } = useRetrySectionOnError({
      section: props.section,
      orderId: props.orderId,
      flow,
      enabled: false,
    });

    if (data) {
      return <Component {...props} data={{ success: true, result: data }} />;
    }

    if (isLoading || isError) {
      return <RetryOnError onClick={refetch} isLoading={isLoading} />;
    }

    if (!props.data.success) {
      return <RetryOnError onClick={refetch} isLoading={isLoading} />;
    }

    return <Component {...props} />;
  };
