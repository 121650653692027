export enum PaymentMethods {
  COUPON = 'COUPON',
  CASH = 'CASH',
  ONLINE = 'ONLINE',
  WALLET = 'WALLET',
  CREDIT_CARD = 'CREDIT_CARD',
  DEBIT_CARD = 'DEBIT_CARD',
  CARD = 'CARD',
  VOUCHER = 'VOUCHER',
  BANK_TRANSFER = 'BANK_TRANSFER',
  WEBPAY = 'WEBPAY',
  PAGO_MOVIL_ONLINE = 'PAGO_MOVIL_ONLINE',
  DLOCAL_PAGO_QR = 'DLOCAL_PAGO_QR',
}
