import type { SectionRequest } from '@app/detail/domain/models';

export enum Urls {
  SECTION = '/order/:orderId/section',
}

export namespace UrlsBuilder {
  export function getSectionUrl(payload: SectionRequest) {
    const url = Urls.SECTION.replace(':orderId', payload.orderId.toString());
    return url;
  }
}
