import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Button from '@pedidosya/web-fenix/atoms/Button';
import Translations from './DetailProducts.translation';
import GlobalTranslation from '@app/translations/globalTranslations';
import useIsWebView from '@app/hooks/useIsWebView';
import { useIntl } from 'react-intl';
import { Container } from '@app/components/Container';
import { ProductItem } from './ProductItem';
import { AddItem } from './AddItem';
import {
  ProductContainer,
  ViewAllContainer,
  AddtionalNotesContainer,
} from './DetailProducts.style';
import { Accordion } from '@app/components/Acoordion';
import { useDetailProducts } from './useDetailProducts';
import { getTranslationKey } from '@app/utils/verticalTranslation';

const DetailProducts = memo(
  ({
    products,
    additionalNote,
    businessType,
    addItemAvailable,
    addItemDmart,
    addItemDmartDeeplink,
    vendorName,
    orderId,
    country,
    openSection,
  }) => {
    const { formatMessage } = useIntl();
    const { open, viewAll, productList, setOpen, viewAllState, setViewAllState } =
      useDetailProducts(products, openSection);
    const vertical = getTranslationKey(businessType);
    const isWebView = useIsWebView();

    if (products?.length) {
      return (
        <Container pBottom="16">
          <Accordion expanded={open} toggle={() => setOpen(!open)}>
            <Accordion.title>
              <Typography
                component="h4"
                token="font-subtitle-highcontrast-sentence-large"
                color="text-color-primary"
              >
                {formatMessage(Translations.products_title)}
              </Typography>
            </Accordion.title>
            <Accordion.body>
              {productList.map((element) => {
                return (
                  <ProductContainer key={element.itemId}>
                    <ProductItem product={element} country={country} state={element?.status} />
                  </ProductContainer>
                );
              })}
              {viewAll && (
                <ViewAllContainer>
                  {!viewAllState && (
                    <Button
                      fullWidth={true}
                      label={formatMessage(Translations.products_see_all)}
                      hierarchy="tertiary"
                      onClick={() => setViewAllState(true)}
                    />
                  )}
                  {viewAllState && (
                    <Button
                      fullWidth={true}
                      label={formatMessage(Translations.products_see_less)}
                      hierarchy="tertiary"
                      onClick={() => setViewAllState(false)}
                    />
                  )}
                </ViewAllContainer>
              )}
              {additionalNote && (
                <AddtionalNotesContainer viewAll={viewAll}>
                  <Typography
                    component="p"
                    token="font-body-midcontrast-sentence-small"
                    color="text-color-action-enabled-quiet"
                  >
                    {formatMessage(Translations.products_additional_note, {
                      vertical: formatMessage(GlobalTranslation[vertical]),
                    })}
                  </Typography>
                  <Typography
                    component="p"
                    token="font-label-midcontrast-medium"
                    color="text-color-action-enabled-loud"
                  >
                    {additionalNote}
                  </Typography>
                </AddtionalNotesContainer>
              )}
              {addItemAvailable && isWebView && (
                <AddItem
                  vendorName={vendorName}
                  addItemDmart={addItemDmart}
                  addItemDmartDeeplink={addItemDmartDeeplink}
                  orderId={orderId}
                />
              )}
            </Accordion.body>
          </Accordion>
        </Container>
      );
    }

    return <></>;
  },
);

DetailProducts.defaultProps = {
  products: [],
  additionalNote: '',
  businessType: 'RESTAURANT',
  addItemAvailable: false,
  addItemDmart: false,
  addItemDmartDeeplink: '',
  vendorName: '',
  orderId: '',
  country: '',
  openSection: false,
};

DetailProducts.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
      amount: PropTypes.number,
      quantity: PropTypes.number,
      note: PropTypes.string,
      options: PropTypes.string,
      showUnit: PropTypes.bool,
      unit: PropTypes.string,
      status: PropTypes.string,
      modifications: PropTypes.shape({
        description: PropTypes.string,
        amount: PropTypes.number,
        quantity: PropTypes.number,
      }),
    }).isRequired,
  ),
  additionalNote: PropTypes.string,
  businessType: PropTypes.string,
  addItemAvailable: PropTypes.bool,
  addItemDmart: PropTypes.bool,
  addItemDmartDeeplink: PropTypes.string,
  vendorName: PropTypes.string,
  orderId: PropTypes.string,
  country: PropTypes.string,
  openSection: PropTypes.bool,
};

export { DetailProducts };
