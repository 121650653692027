import React from 'react';
import styled from 'styled-components';

const Container = styled.header`
  padding: 16px 16px 0px 16px;
  position: relative;
  background-image: url(${(props) => props.src});
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 190px;
  display: flex;
  justify-content: flex-end;
`;

const Shape = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  min-height: 205px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
`;

const TextContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: ${(props) => props.theme.space('space-12')};
  padding-top: ${(props) => props.theme.space('space-12')};
  align-items: center;
  gap: ${(props) => props.theme.space('space-4')};
  text-align: center;
  z-index: 2;
`;

const MockContainer = styled.div`
  position: absolute;
  top: ${(props) => props.theme.space('space-16')};
  left: 60px;
  z-index: 4;
`;

const DefaultImage = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.color('shape-color-surface-secondary')};
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  text-align: right;
  & > picture {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
`;

const HelpCenterContainer = styled.div`
  position: absolute;
  top: ${(props) => (props.hasSafe ? '62px' : '32px')};
  right: 16px;
  z-index: 4;
  & button {
    background-color: #fff;
    border: 1px solid #fff;
  }
`;

export { Container, Shape, TextContainer, MockContainer, DefaultImage, HelpCenterContainer };
