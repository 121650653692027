import { useEffect, useCallback, useState, useMemo } from 'react';
import { StatusRules } from '@app/utils/engine';
import { Constants } from '@app/config/constants';
import { FeedbackStates } from '@app/config/feedbackStates';
import { useDeeplinks } from '@app/hooks/useDeeplinks';
import { useTracker } from '@app/contexts/TrackerProvider';
import { ACTIONS } from '@app/utils/deeplink';
import { OrderState } from '@app/detail/infra/config/OrderState';
import Icon from '@app/assets/images/order_with_problems.svg';
import isEmpty from 'lodash/isEmpty';

const useDetailStatus = (props) => {
  const [remedies, setRemedies] = useState(null);
  const { handleEvent } = useDeeplinks();
  const { rateClicked, repeatClicked } = useTracker();

  const getRemdies = useCallback(async () => {
    const result = await StatusRules({
      status: props?.status,
      feedback:
        props?.feedback === FeedbackStates.AVAILABLE && props?.status === Constants.DELIVERED_STATE,
      cancellationReason:
        props?.status === Constants.CANCELLED_STATE ? props?.cancellationReason : '',
      businessType: props?.businessType,
    });
    setRemedies(result);
  }, [props?.status, props?.feedback, props?.cancellationReason, props?.businessType]);

  /**
   * mainCTAFloating
   *
   * @description Function to know if there is a main cta, this will help
   * to show in floating bar fixed to bottom
   */
  const mainCTAFloating = useMemo(() => {
    return (
      !isEmpty(props?.statusDetail?.actions?.feedback) ||
      !isEmpty(props?.statusDetail?.actions?.repeat)
    );
  }, [props?.statusDetail?.actions]);

  /**
   * onClickButton
   *
   * @description Function to handle click event over button for feedbar
   * or over button for repeat order
   * @param {string} name - Name of action
   * @param {string} type  - Type of event deeplink | navigate
   */
  const onClickButton = (name, type) => {
    if (ACTIONS.BASKET_RECREATION === name) {
      repeatClicked(props?.orderId, props?.orderStatus);
    } else if (ACTIONS.NEW_FEEDBACK === name) {
      rateClicked(props?.orderId);
    }

    return handleEvent(name, type, {
      orderId: props?.orderId,
      partnerId: props?.vendorId,
      resourceId: props?.feedbackResource,
      surveyId: props?.feedbackSurvey,
      country: props?.country,
    });
  };

  /**
   * Image
   *
   * @description Property to show a different bag icon, beacause if there
   * are refunds the bag must be differente when order is delivered, otherwise
   * this prop will contain the icon image result from engine
   */
  const Image = useMemo(() => {
    if (remedies && OrderState.DELIVERED === props?.status.toUpperCase() && props?.hasRefund) {
      return Icon;
    }

    return remedies?.icon;
  }, [remedies]);

  useEffect(() => {
    getRemdies();
  }, [getRemdies]);

  return {
    remedies,
    mainAction: mainCTAFloating,
    onClickButton,
    Image,
  };
};

export { useDetailStatus };
