import React, { useEffect } from 'react';
import { EventListener } from '@app/utils/eventListener';
import { useDetailRemediesEvents } from './DetailRemedies.events';

const useDetailRemedies = (
  localReason,
  orderId,
  vendorId,
  businessType,
  orderStatus,
  cityName,
  country,
) => {
  const Listeners = useDetailRemediesEvents();
  const [dispatch] = EventListener.subscribe(...Listeners);

  /**
   * onClickButton
   *
   * @description Function to handle click event over button for feedbar
   * or over button for repeat order
   * @param {string} link - Link of remedy button
   * @param {string} title  - Button title
   */
  const onClickButton = (link, title) => {
    dispatch({
      orderId,
      orderStatus,
      vertical: businessType,
      partnerId: vendorId,
      city: cityName,
      country,
      link,
      name: title,
    });
  };

  return {
    onClickButton,
  };
};

export { useDetailRemedies };
