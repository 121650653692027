import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Inovice from '@pedidosya/web-fenix/icons/Invoice';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Button from '@pedidosya/web-fenix/atoms/Button';
import Translation from '../DetailSummary.translation';
import { ItemContainer, ButtonContainer } from './BillingItem.style';
import { useIntl } from 'react-intl';

const BillingItem = memo(({ onTryDownload, isLoading }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <ItemContainer>
        <Inovice />
        <Typography
          token="font-body-midcontrast-sentence-small"
          component="p"
          color="text-color-action-enabled-loud"
        >
          {formatMessage(Translation.billing_label)}
        </Typography>
      </ItemContainer>
      <ButtonContainer>
        <Button
          fullWidth={true}
          hierarchy="secondary"
          type="button"
          state={isLoading ? 'loading' : 'enabled'}
          label={formatMessage(Translation.billing_button_download)}
          onClick={onTryDownload}
        />
      </ButtonContainer>
    </>
  );
});

BillingItem.defaultProps = {
  onTryDownload: () => {},
  isLoading: false,
};

BillingItem.propTypes = {
  onTryDownload: PropTypes.func,
  isLoading: PropTypes.bool,
};

export { BillingItem };
