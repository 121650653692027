import React, { memo, createElement } from 'react';
import PropTypes from 'prop-types';
import { Constants } from '@app/config/constants';
import { Components } from '@app/config/componentsMap';
import { MainContainer, InsideContainer, FloatingContainer } from './Detail.style';
import { useDetail } from './useDetail';
import { Sections } from '@shared/config/Sections';
import { DetailProxy } from '@app/detail/use_cases/proxy/Detail';
import kebabCase from 'lodash/kebabCase';
import isEmpty from 'lodash/isEmpty';
/**
 * Components
 */
import { DetailHeader } from './DetailHeader';
import { DetailStatus } from './DetailStatus';
import { DetailRemedies } from './DetailRemedies';
import { DetailProducts } from './DetailProducts';
import { DetailAddress } from './DetailAddress';
import { DetailSummary } from './DetailSummary';
import { DetailPayments } from './DetailPayments';
import { Divider } from '@app/components/Divider';
import { RefundActions } from '@app/config/refundActions';
import { DetailExtraPayment } from './DetailExtraPayment';
import { Refunds, Compensation } from '@app/detail/presentation/components';

const Detail = memo((props) => {
  const {
    components: listComponents,
    setShowFloating,
    showFloating,
    shortName,
    mock,
  } = useDetail(props);

  const components = {
    [Components.DetailAddress]: ({ open }) => (
      <>
        <DetailAddress
          addresses={props?.shipping?.addresses}
          note={props?.shipping?.note}
          deliveryType={props?.order?.orderType?.type}
          openSection={open}
        />
        <Divider />
      </>
    ),
    [Components.DetailPayments]: ({ open }) =>
      props?.paymentMethod?.length > 0 ? (
        <>
          <DetailPayments
            paymentsMethod={props?.paymentMethod}
            currencyIso={props?.order?.currencyIsoCode}
            country={props?.order?.countryCode}
            openSection={open}
          />
          <Divider />
        </>
      ) : (
        <></>
      ),
    [Components.DetailProducts]: ({ open }) =>
      props?.order?.items && props?.order?.items.length ? (
        <>
          <DetailProducts
            products={props?.order?.items}
            country={props?.order?.countryCode}
            additionalNote={props?.order?.checkoutNotes}
            businessType={props?.order?.businessType}
            addItemAvailable={props?.addItem?.available}
            addItemDmart={props?.addItem?.isDmart}
            addItemDmartDeeplink={props?.addItem?.deeplink}
            vendorName={props?.vendor?.name}
            orderId={props?.order?.id}
            openSection={open}
          />
          <Divider />
        </>
      ) : (
        <></>
      ),
    [Components.DetailRemedies]: ({ open }) =>
      props?.order?.status === Constants.CANCELLED_STATE &&
      !isEmpty(props?.cancellationInformation?.orderRejectionInternalReason) ? (
        <>
          <DetailRemedies
            localReason={props?.cancellationInformation?.orderRejectionInternalReason}
            businessType={props?.order?.businessType}
            orderId={props?.order?.id}
            vendorId={props?.vendor?.id}
            orderStatus={props?.order?.status}
            cityName={kebabCase(props?.vendor?.city)}
            country={props?.order?.countryCode}
            vendorState={props?.vendor?.state}
            remedies={props?.cancellationInformation?.remedies}
          />
          <Divider />
        </>
      ) : (
        <></>
      ),
    [Components.DetailSummary]: ({ open }) => (
      <>
        <DetailSummary
          total={props?.summary?.amount}
          summary={props?.summary?.concepts}
          currencyIso={props?.order?.currencyIsoCode}
          country={props?.order?.countryCode}
          billingAvailable={props?.billing?.available}
          billingDocumentId={props?.billing?.documentId}
          orderId={props?.order?.id}
          countryId={props?.order?.countryId}
          openSection={open}
        />
        <Divider />
      </>
    ),
  };

  const proxy = new DetailProxy(props);

  return (
    <>
      <DetailHeader
        name={props?.vendor?.name}
        banner={props?.vendor?.banner}
        businessType={props?.order?.businessType}
        vendorId={props?.vendor?.id}
        mock={mock}
        vendorSlug={props?.vendor?.link}
        cityName={kebabCase(props?.vendor?.city)}
        orderId={props?.order?.id}
        orderStatus={props?.order?.status}
      />
      <MainContainer>
        <InsideContainer>
          {proxy.hasStatus() ? (
            <>
              <DetailStatus
                status={props?.order?.status}
                registerDate={props?.order?.registeredDate}
                businessType={props?.order?.businessType}
                feedback={props?.feedback?.state}
                cancellationReason={props?.cancellationInformation?.globalReason}
                country={shortName}
                feedbackPoint={props?.feedback?.rate}
                onShowFloating={(visible) => setShowFloating(visible)}
                orderId={props?.order?.id}
                vendorId={props?.vendor?.id}
                feedbackResource={props?.feedback?.resourceId}
                feedbackSurvey={props?.feedback?.surveyId}
                orderStatus={props?.order?.status}
                vendorState={props?.vendor?.state}
                statusDetail={props?.order?.statusDetail}
                hasRefund={proxy.hasCompensationandOrRefund()}
              />
              <Divider />
            </>
          ) : (
            <></>
          )}
          {proxy.hasExtraPayments() ? (
            <>
              <DetailExtraPayment
                payments={props?.collectionsRequests}
                country={props?.order?.countryCode}
              />
              <Divider />
            </>
          ) : (
            <></>
          )}
          {proxy.hasCompensationandOrRefund() ? (
            <>
              {proxy.hasRefund() ? (
                <Refunds
                  country={props?.order?.countryCode}
                  data={props?.refund}
                  orderId={props?.order?.id}
                  section={Sections.REFUNDS}
                  orderState={props?.order?.status}
                  isCash={props?.order?.isCash}
                />
              ) : null}
              {proxy.hasCompensation() ? (
                <Compensation
                  data={props?.compensation}
                  country={props?.order?.countryCode}
                  section={Sections.COMPENSATION}
                  orderId={props?.order?.id}
                />
              ) : null}
              <Divider />
            </>
          ) : (
            <></>
          )}
          {listComponents.map((element, index) => {
            try {
              if (typeof components[element.component] !== 'undefined') {
                const ele = createElement(components[element.component], {
                  key: element.component,
                  open: element.open,
                });
                return ele;
              }
              return <></>;
            } catch (e) {
              console.error(e);
              return <></>;
            }
          })}

          {showFloating && <FloatingContainer>{showFloating}</FloatingContainer>}
        </InsideContainer>
      </MainContainer>
    </>
  );
});

Detail.defaultProps = {
  vendor: {},
  feedback: {},
  shippingAddress: {},
  paymentsMethod: [],
  summary: {},
  cancellationInformation: {},
  order: {},
  billing: {},
  addItem: {},
  compensationInformation: {},
};

Detail.propTypes = {
  vendor: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.string,
    banner: PropTypes.string,
    openTime: PropTypes.string.isRequired,
    city: PropTypes.string,
    link: PropTypes.string,
    state: PropTypes.string,
  }),
  feedback: PropTypes.shape({
    state: PropTypes.string,
    rate: PropTypes.number,
    surveyId: PropTypes.string.isRequired,
    resourceId: PropTypes.string.isRequired,
  }),
  shipping: PropTypes.shape({
    addresses: PropTypes.arrayOf(
      PropTypes.shape({
        address: PropTypes.string,
        type: PropTypes.string,
      }),
    ),
    note: PropTypes.string,
  }),
  paymentMethod: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      amount: PropTypes.number,
      description: PropTypes.string.isRequired,
      code: PropTypes.string,
    }),
  ),
  summary: PropTypes.shape({
    concepts: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string,
        amount: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        amountNoDiscount: PropTypes.number,
      }),
    ),
    amount: PropTypes.number,
  }),
  billing: PropTypes.shape({
    available: PropTypes.bool,
    documentId: PropTypes.string,
  }),
  cancellationInformation: PropTypes.shape({
    cancelledBy: PropTypes.string.isRequired,
    globalReason: PropTypes.string.isRequired,
    orderRejectionInternalReason: PropTypes.string,
    beneficiary: PropTypes.string,
    amountRefund: PropTypes.number,
    remedies: PropTypes.shape({
      title: PropTypes.string,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          deeplink: PropTypes.string,
          link: PropTypes.string,
          hierarchy: PropTypes.string,
          title: PropTypes.string,
          webLink: PropTypes.string,
        }),
      ),
    }),
  }),
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    reason: PropTypes.string,
    currencySymbol: PropTypes.string.isRequired,
    registeredDate: PropTypes.string.isRequired,
    isCash: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        itemId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        image: PropTypes.string,
        amount: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        note: PropTypes.string,
        options: PropTypes.string,
        showUnit: PropTypes.bool,
        unit: PropTypes.string,
        status: PropTypes.string,
        modifications: PropTypes.shape({
          description: PropTypes.string,
          amount: PropTypes.number,
          quantity: PropTypes.number,
        }),
      }),
    ),
    checkoutNotes: PropTypes.string,
    orderType: PropTypes.shape({ type: PropTypes.string.isRequired }),
    businessType: PropTypes.string.isRequired,
    countryId: PropTypes.number,
    countryCode: PropTypes.string,
    currencyIsoCode: PropTypes.string,
    statusDetail: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string,
      actions: PropTypes.shape({
        feedback: PropTypes.shape({
          title: PropTypes.string,
          deeplink: PropTypes.number,
        }),
        repeat: PropTypes.shape({
          title: PropTypes.string,
          deeplink: PropTypes.string,
        }),
      }),
    }),
  }),
  addItem: PropTypes.shape({
    available: PropTypes.bool,
    isDmart: PropTypes.bool,
    deeplink: PropTypes.string,
  }),
  compensation: PropTypes.shape({
    reason: PropTypes.string,
    total: PropTypes.number,
    expirationDate: PropTypes.string,
    action: PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  }),
  refund: PropTypes.shape({
    title: PropTypes.string,
    pendingAmount: PropTypes.number,
    refundedAmount: PropTypes.number,
    detail: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string,
        amount: PropTypes.number,
        description: PropTypes.string.isRequired,
        code: PropTypes.string,
        action: PropTypes.oneOf(Object.values(RefundActions)),
        referenceId: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
  }),
  collectionsRequests: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      amount: PropTypes.number,
      description: PropTypes.string,
      code: PropTypes.string,
    }),
  ),
};

export { Detail };
