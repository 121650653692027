import { useQuery } from 'react-query';
import { Sections } from '@shared/config';
import { DetailService } from '@app/detail/use_cases/services';
import { getItem } from '@app/utils/storage';
import { Constants } from '@app/config/constants';

type Props = {
  section: `${Sections}`;
  orderId: number;
  flow?: string;
  enabled: boolean;
};

export const useRetrySectionOnError = (payload: Props) => {
  const service = new DetailService();
  const { data, isLoading, isError, refetch, isRefetching } = useQuery(
    ['retry', payload.section],
    ({ signal }) => {
      return service.getSection({
        orderId: payload.orderId,
        flow: payload.flow,
        section: payload.section,
        signal: signal as AbortSignal,
        mock: getItem(Constants.MOCK_FLAG, false),
      });
    },
    {
      retry: 2,
      enabled: payload.enabled,
    },
  );

  return { data, isLoading, isError, refetch };
};
