import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const NavBarFloating = styled.nav`
  position: fixed;
  top: ${(props) => (!props.outisde ? '0px' : props.theme.space('space-08'))};
  left: 0px;
  width: 100%;
  z-index: 3;
  height: 39px;
  background-color: ${(props) =>
    !props.outisde ? props.theme.color('shape-color-background-primary') : 'transparent'};
  box-shadow: ${(props) => (!props.outisde ? '0px 4px 8px rgba(16, 4, 35, 0.12);' : 'none')};
  padding-top: ${(props) =>
    props.hasSafe ? props.theme.space('space-22') : props.theme.space('space-08')};
`;

const ItemsContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space('space-04')};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const VendorName = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export { NavBarFloating, ItemsContainer, VendorName };
