import React from 'react';
import loadable from '@loadable/component';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { Switch, Route } from 'react-router-dom';
/**
 * config
 */
import { Routes } from '@app/config/routes';
/**
 * Pages
 */
import { OrderByCode } from '@app/pages/OrderByCode';
import { OrderByOrderId } from '@app/pages/OrderByOrderId';

const NotFound = loadable(() => import('@app/pages/NotFound'), {
  resolveComponent: (components) => components.NotFound,
  fallback: <PeyaLoader position="center" />,
});

const CardRefundDetail = loadable(() => import('@app/pages/CardRefundDetail'), {
  resolveComponent: (components) => components.CardRefundDetail,
  fallback: <PeyaLoader position="center" />,
});

const CardMoreInformation = loadable(() => import('@app/pages/CardMoreInformation'), {
  resolveComponent: (components) => components.CardMoreInformation,
  fallback: <PeyaLoader position="center" />,
});

const Feedback = loadable(() => import('@app/pages/Feedback'), {
  resolveComponent: (components) => components.Feedback,
  fallback: <PeyaLoader position="center" />,
});

const RouterApp = () => {
  return (
    <Switch>
      <Route exact path={Routes.ORDER_BY_ORDERID}>
        <OrderByOrderId />
      </Route>
      <Route exact path={Routes.ORDER_BY_ORDERIDTEST}>
        <OrderByOrderId />
      </Route>
      <Route exact path={Routes.ORDER_BY_ORDERIDALTERNATIVE}>
        <OrderByOrderId />
      </Route>
      <Route exact path={Routes.ORDER_BY_CODE}>
        <OrderByCode />
      </Route>
      <Route exact path={Routes.ORDER_CARD_MORE_INFORMATION}>
        <CardMoreInformation />
      </Route>
      <Route exact path={Routes.CARD_REFUND_DETAIL}>
        <CardRefundDetail />
      </Route>
      <Route exact path={Routes.FEEDBACK}>
        <Feedback />
      </Route>
      <Route path="*">
        <NotFound></NotFound>
      </Route>
    </Switch>
  );
};

export { RouterApp };
