import { ACTIONS } from '@app/utils/deeplink';
import { Constants } from '@app/config/constants';
import { BasketRecreationFlag } from '@app/config/basketRecreationFlag';
import { Verticals } from '@app/config/verticals';

export const RemediesRule = {
  name: 'remedies',
  rules: [
    {
      name: 'FORGET_ADD_PRODUCTS',
      description: 'FORGET_ADD_PRODUCTS',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['FORGET_ADD_PRODUCTS'],
        basketRecreationValue: '*',
        businessType: '*',
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_forget_add_products_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'DONT_WANT_PRODUCTS',
      description: 'DONT_WANT_PRODUCTS',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['DONT_WANT_PRODUCTS'],
        basketRecreationValue: '*',
        businessType: '*',
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_dont_want_products_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'UPDATE_ORDER_ADDRESS',
      description: 'UPDATE_ORDER_ADDRESS',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['UPDATE_ORDER_ADDRESS'],
        basketRecreationValue: '*',
        businessType: '*',
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_address_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'UPDATE_ORDER_PAYMENT_METHOD',
      description: 'UPDATE_ORDER_PAYMENT_METHOD',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['UPDATE_ORDER_PAYMENT_METHOD'],
        basketRecreationValue: '*',
        businessType: '*',
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_payment_method_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'COUPON_NOT_LOADED',
      description: 'COUPON_NOT_LOADED',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['COUPON_NOT_LOADED'],
        basketRecreationValue: '*',
        businessType: '*',
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_coupon_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'DELIVERY_ETA_TOO_LONG',
      description: 'DELIVERY_ETA_TOO_LONG',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['DELIVERY_ETA_TOO_LONG', 'ITEM_UNAVAILABLE', 'UNAVAILABLE_TO_FIND'],
        basketRecreationValue: '*',
        businessType: '*',
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_unable_to_find_title',
        buttons: [
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'primary',
          },
        ],
      },
    },
    {
      name: 'ORDER_REGRETTED',
      description: 'ORDER_REGRETTED',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['ORDER_REGRETTED'],
        basketRecreationValue: '*',
        businessType: '*',
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_regret_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'FORGET_ADD_PRODUCTS + QQ + Basket Recreation',
      description: 'FORGET_ADD_PRODUCTS',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['FORGET_ADD_PRODUCTS'],
        basketRecreationValue: BasketRecreationFlag.REPEAT_QQ,
        businessType: Verticals.GROCERIES,
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_forget_add_products_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'FORGET_ADD_PRODUCTS + All verticals + Basket Recreation',
      description: 'FORGET_ADD_PRODUCTS',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['FORGET_ADD_PRODUCTS'],
        basketRecreationValue: BasketRecreationFlag.REPEAT_ALL,
        businessType: '*',
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_forget_add_products_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'UPDATE_ORDER_ADDRESS + QQ + Basket Recreation',
      description: 'UPDATE_ORDER_ADDRESS',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['UPDATE_ORDER_ADDRESS'],
        basketRecreationValue: BasketRecreationFlag.REPEAT_QQ,
        businessType: Verticals.GROCERIES,
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_address_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'UPDATE_ORDER_ADDRESS + All Verticales + Basket Recreation',
      description: 'UPDATE_ORDER_ADDRESS',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['UPDATE_ORDER_ADDRESS'],
        basketRecreationValue: BasketRecreationFlag.REPEAT_ALL,
        businessType: '*',
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_address_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'UPDATE_ORDER_PAYMENT_METHOD + QQ + Basket Recreation',
      description: 'UPDATE_ORDER_PAYMENT_METHOD',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['UPDATE_ORDER_PAYMENT_METHOD'],
        basketRecreationValue: BasketRecreationFlag.REPEAT_QQ,
        businessType: Verticals.GROCERIES,
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_payment_method_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'UPDATE_ORDER_PAYMENT_METHOD + All Verticales + Basket Recreation',
      description: 'UPDATE_ORDER_PAYMENT_METHOD',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['UPDATE_ORDER_PAYMENT_METHOD'],
        basketRecreationValue: BasketRecreationFlag.REPEAT_ALL,
        businessType: '*',
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_payment_method_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'COUPON_NOT_LOADED + QQ + Basket Recreation',
      description: 'COUPON_NOT_LOADED',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['COUPON_NOT_LOADED'],
        basketRecreationValue: BasketRecreationFlag.REPEAT_QQ,
        businessType: Verticals.GROCERIES,
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_coupon_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'COUPON_NOT_LOADED + QQ + Basket Recreation',
      description: 'COUPON_NOT_LOADED',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['COUPON_NOT_LOADED'],
        basketRecreationValue: BasketRecreationFlag.REPEAT_ALL,
        businessType: '*',
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_coupon_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'DONT_WANT_PRODUCTS + QQ + Basket Recreation',
      description: 'DONT_WANT_PRODUCTS',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['DONT_WANT_PRODUCTS'],
        basketRecreationValue: BasketRecreationFlag.REPEAT_QQ,
        businessType: Verticals.GROCERIES,
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_dont_want_products_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'DONT_WANT_PRODUCTS + All verticals + Basket Recreation',
      description: 'DONT_WANT_PRODUCTS',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['DONT_WANT_PRODUCTS'],
        basketRecreationValue: BasketRecreationFlag.REPEAT_ALL,
        businessType: '*',
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_dont_want_products_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'ORDER_REGRETTED + QQ + Basket Recreation',
      description: 'DONT_WANT_PRODUCTS',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['ORDER_REGRETTED'],
        basketRecreationValue: BasketRecreationFlag.REPEAT_QQ,
        businessType: Verticals.GROCERIES,
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_regret_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
    {
      name: 'ORDER_REGRETTED + All verticals + Basket Recreation',
      description: 'DONT_WANT_PRODUCTS',
      when: {
        closure: 'checkOptionRemedies',
        localReason: ['ORDER_REGRETTED'],
        basketRecreationValue: BasketRecreationFlag.REPEAT_ALL,
        businessType: '*',
      },
      then: {
        closure: 'getTranslationRemedies',
        title: 'remedies_repeat_order_regret_title',
        buttons: [
          {
            title: 'button_repeat_order',
            type: Constants.DEEPLINK,
            value: ACTIONS.BASKET_RECREATION,
            style: 'primary',
            conditionEnable: Constants.VENDOR_OPEN_STATE,
          },
          {
            title: 'button_order_local_restaurant',
            type: Constants.DEEPLINK,
            value: ACTIONS.MAKE_ORDER,
            style: 'secondary',
          },
        ],
      },
    },
  ],
};
