/**
 * WebViewEvents
 *
 * @description const with events to send to webviews
 */
export const WebViewEvents = {
  HOME: 'WEBVIEW_HOME',
  CLOSE: 'WEBVIEW_CLOSE',
  DOWNLOAD: 'DOWNLOAD_RECEIPT',
};
