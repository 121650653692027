import { useState, useEffect, useMemo } from 'react';
import type { Refund, ResultSection, Method } from '@shared/models';
import { PaymentMethods } from '@app/config/paymentMethods';
import { OperationType } from '@app/detail/infra/config/OperationType';
import type { Result } from '@app/detail/presentation/components/Refunds/models';

type Props = ResultSection<Refund>;

export const useRefunds = (data: Props): Result => {
  const [refunds, setRefunds] = useState<Array<Method>>([]);
  const [cancelledPayments, setCancelledPayments] = useState<Array<Method>>([]);
  const [updatePayments, setUpdatePayments] = useState<Array<Method>>([]);

  useEffect(() => {
    if (data.success) {
      const re: Array<Method> = [];
      const ca: Array<Method> = [];
      const up: Array<Method> = [];
      data.result?.methods.forEach((element) => {
        if (element.type !== PaymentMethods.CARD) {
          return re.push(element);
        }
        /**
         * Iterating over operations in card type
         */
        element.operations.forEach((operation) => {
          if (operation?.type) {
            if (operation.type.toLowerCase() === OperationType.VOID) {
              return ca.push({ ...element, operations: [operation] });
            }
            if (operation.type.toLowerCase() === OperationType.CREDIT) {
              return re.push({ ...element, operations: [operation] });
            }
            if (operation.type.toLowerCase() === OperationType.UPDATE_AMOUNT) {
              return up.push({ ...element, operations: [operation] });
            }
          }
        });
      });
      setRefunds(() => re);
      setCancelledPayments(() => ca);
      setUpdatePayments(() => up);
    }
  }, [data]);

  const result = useMemo(() => {
    return { refunds: refunds, cancellations: cancelledPayments, updates: updatePayments };
  }, [refunds, cancelledPayments, updatePayments]);

  return {
    data: result,
  };
};
