import { Constants } from '@app/config/constants';
import { Components } from '@app/config/componentsMap';
import { GlobalReasons } from '@app/config/globalReason';

export const OrderDetailRule = {
  name: 'orderDetail',
  rules: [
    {
      name: 'Delivery without PDI',
      when: {
        closure: 'checkOrderDetail',
        orderState: Constants.DELIVERED_STATE,
        pdi: false,
      },
      then: {
        closure: 'getTranslationOrderDetail',
        block: [
          { component: Components.DetailProducts, open: true },
          { component: Components.DetailSummary, open: false },
          { component: Components.DetailPayments, open: false },
          { component: Components.DetailAddress, open: false },
        ],
      },
    },
    {
      name: 'Cancelation by Other No Cash',
      when: {
        closure: 'checkOrderDetail',
        orderState: Constants.CANCELLED_STATE,
        cancelledByUser: false,
        cash: false,
      },
      then: {
        closure: 'getTranslationOrderDetail',
        block: [
          { component: Components.DetailSummary, open: false },
          { component: Components.DetailPayments, open: false },
          { component: Components.DetailProducts, open: false },
        ],
      },
    },
    {
      name: 'Cancelation by Other With Cash',
      when: {
        closure: 'checkOrderDetail',
        orderState: Constants.CANCELLED_STATE,
        cancelledByUser: false,
        cash: true,
      },
      then: {
        closure: 'getTranslationOrderDetail',
        block: [
          { component: Components.DetailSummary, open: true },
          { component: Components.DetailPayments, open: false },
          { component: Components.DetailProducts, open: false },
        ],
      },
    },
    {
      name: 'Cancelation by User no Cash',
      when: {
        closure: 'checkOrderDetail',
        orderState: Constants.CANCELLED_STATE,
        cancelledByUser: true,
        cash: false,
      },
      then: {
        closure: 'getTranslationOrderDetail',
        block: [
          { component: Components.DetailRemedies, open: true },
          { component: Components.DetailSummary, open: false },
          { component: Components.DetailPayments, open: false },
          { component: Components.DetailProducts, open: false },
          { component: Components.DetailAddress, open: false },
        ],
      },
    },
    {
      name: 'Cancelation by User with Cash',
      when: {
        closure: 'checkOrderDetail',
        orderState: Constants.CANCELLED_STATE,
        cancelledByUser: true,
        cash: true,
      },
      then: {
        closure: 'getTranslationOrderDetail',
        block: [
          { component: Components.DetailSummary, open: true },
          { component: Components.DetailPayments, open: false },
          { component: Components.DetailProducts, open: false },
          { component: Components.DetailAddress, open: false },
        ],
      },
    },
    {
      name: 'Delivered with Missing Items',
      when: {
        closure: 'checkOrderDetail',
        orderState: Constants.DELIVERED_STATE,
        reason: GlobalReasons.MISSING_ITEMS,
        pdi: true,
      },
      then: {
        closure: 'getTranslationOrderDetail',
        block: [
          { component: Components.DetailProducts, open: true },
          { component: Components.DetailSummary, open: false },
          { component: Components.DetailPayments, open: false },
          { component: Components.DetailAddress, open: false },
        ],
      },
    },
    {
      name: 'Delivered with Wrong Items',
      when: {
        closure: 'checkOrderDetail',
        orderState: Constants.DELIVERED_STATE,
        reason: GlobalReasons.WRONG_ORDER_ITEMS_DELIVERED,
      },
      then: {
        closure: 'getTranslationOrderDetail',
        block: [
          { component: Components.DetailProducts, open: true },
          { component: Components.DetailSummary, open: false },
          { component: Components.DetailPayments, open: false },
          { component: Components.DetailAddress, open: false },
        ],
      },
    },
    {
      name: 'Delivered with Wrong Order',
      when: {
        closure: 'checkOrderDetail',
        orderState: Constants.DELIVERED_STATE,
        reason: GlobalReasons.WRONG_ORDER,
      },
      then: {
        closure: 'getTranslationOrderDetail',
        block: [
          { component: Components.DetailSummary, open: true },
          { component: Components.DetailProducts, open: false },
          { component: Components.DetailPayments, open: false },
          { component: Components.DetailAddress, open: false },
        ],
      },
    },
    {
      name: 'Delivered with OMAD',
      when: {
        closure: 'checkOrderDetail',
        orderState: Constants.DELIVERED_STATE,
        reason: GlobalReasons.NEVER_DELIVERED,
      },
      then: {
        closure: 'getTranslationOrderDetail',
        block: [
          { component: Components.DetailSummary, open: false },
          { component: Components.DetailPayments, open: false },
          { component: Components.DetailProducts, open: false },
        ],
      },
    },
    {
      name: 'Delivered with FOOD_QUALITY_SPILLAGE',
      when: {
        closure: 'checkOrderDetail',
        orderState: Constants.DELIVERED_STATE,
        reason: GlobalReasons.FOOD_QUALITY_SPILLAGE,
      },
      then: {
        closure: 'getTranslationOrderDetail',
        block: [
          { component: Components.DetailSummary, open: false },
          { component: Components.DetailPayments, open: false },
          { component: Components.DetailProducts, open: false },
          { component: Components.DetailAddress, open: false },
        ],
      },
    },
  ],
};
