import {
  BrowserClient,
  Hub,
  BrowserTracing,
  defaultStackParser,
  makeFetchTransport,
  getCurrentHub,
} from '@sentry/react';
const { PROD_ENVIRONMENT } = require('@appscore/web-constants/environments');

let customHub = null;

const sentryHub = {
  init: ({ dsn, environment, isServer }) => {
    const isLive = environment === PROD_ENVIRONMENT;

    const client = new BrowserClient({
      dsn,
      environment,
      integrations: [
        new BrowserTracing({
          _metricOptions: {
            _reportAllChanges: true,
          },
        }),
      ],
      release: `${APP_NAME}@${APP_VERSION}`,
      tracesSampleRate: isLive ? 0.1 : 1,
      sampleRate: isLive ? 0.1 : 1,
      sendDefaultPii: true,
      stackParser: defaultStackParser,
      transport: makeFetchTransport,
    });
    if (isServer) {
      customHub = getCurrentHub();
    } else {
      customHub = new Hub(client);
    }
    return customHub;
  },
  captureMessage: (message) => {
    if (!customHub) return null;
    customHub.run((hub) => hub.captureMessage(message));
  },
  captureException: (ex) => {
    if (!customHub) return null;
    customHub.run((hub) => hub.captureException(ex));
  },
  setTag: (key, value) => {
    if (!customHub) {
      throw new Error('Cannot set tags before hub initialization');
    }
    customHub.setTag(key, value);
  },
  setUser: (user) => {
    if (!customHub) {
      throw new Error('Cannot set user before hub initialization');
    }
    customHub.setUser(user);
  },
  withScope: (cb) => {
    if (!customHub) {
      throw new Error('Cannot set user before hub initialization');
    }
    customHub.run((hub) => {
      hub.withScope(cb);
    });
  },
};

Object.freeze(sentryHub);

export { sentryHub };
