import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Translation from './DetailAddress.translation';
import { useIntl } from 'react-intl';
import { Container } from '@app/components/Container';
import { Accordion } from '@app/components/Acoordion';
import { useDetailAddress } from './useDetailAddress';
import { AddressItem } from './AddressItem';
import { ItemsContainer } from './DetailAddress.style';

const DetailAddress = memo(({ addresses, note, deliveryType, openSection }) => {
  const { formatMessage } = useIntl();
  const { setOpen, open, items } = useDetailAddress(addresses, note, deliveryType, openSection);

  return (
    <Container pBottom="16">
      <Accordion expanded={open} toggle={() => setOpen(!open)}>
        <Accordion.title>
          <Typography
            component="h4"
            token="font-subtitle-highcontrast-sentence-large"
            color="text-color-primary"
          >
            {formatMessage(Translation.address_title)}
          </Typography>
        </Accordion.title>
        <Accordion.body>
          <ItemsContainer>
            {items.map((element) => {
              return (
                <AddressItem
                  key={element.translateKeyTitle}
                  title={formatMessage(Translation[element.translateKeyTitle])}
                  description={
                    element?.translateKey
                      ? formatMessage(Translation[element.translateKey])
                      : element?.value
                  }
                  icon={element.icon}
                ></AddressItem>
              );
            })}
          </ItemsContainer>
        </Accordion.body>
      </Accordion>
    </Container>
  );
});

DetailAddress.defaultProps = {
  addresses: [],
  note: '',
  deliveryType: '',
  openSection: false,
};

DetailAddress.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      type: PropTypes.string,
    }).isRequired,
  ),
  note: PropTypes.string,
  deliveryType: PropTypes.string.isRequired,
  openSection: PropTypes.bool,
};

export { DetailAddress };
