import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Translation from './DetailPayments.translation';
import { useIntl } from 'react-intl';
import { Container } from '@app/components/Container';
import { Accordion } from '@app/components/Acoordion';
import { useDetailPayments } from './useDetailPayments';
import { PaymentItem } from './PaymentItem';

const DetailPayments = memo(({ paymentsMethod, currencyIso, country, openSection }) => {
  const { formatMessage } = useIntl();
  const { open, setOpen, items } = useDetailPayments(paymentsMethod, openSection);
  return (
    <Container pBottom="16">
      <Accordion expanded={open} toggle={() => setOpen(!open)}>
        <Accordion.title>
          <Typography token="font-subtitle-highcontrast-sentence-large" component="h4">
            {formatMessage(Translation.total_title)}
          </Typography>
        </Accordion.title>
        <Accordion.body>
          <Container pTop="16" pBottom="12" pLeft="0" pRight="0">
            {items.map((element) => {
              return (
                <PaymentItem
                  key={element?.code}
                  description={element?.description}
                  icon={element?.icon}
                  image={element?.imageUrl}
                  amount={element?.amount}
                  currencyIso={currencyIso}
                  country={country}
                />
              );
            })}
          </Container>
        </Accordion.body>
      </Accordion>
    </Container>
  );
});

DetailPayments.defaultProps = {
  paymentsMethod: [],
  currencyIso: 'UYU',
  country: '',
  openSection: false,
};

DetailPayments.propTypes = {
  paymentsMethod: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      amount: PropTypes.number,
      description: PropTypes.string,
      code: PropTypes.string,
    }),
  ),
  currencyIso: PropTypes.string,
  country: PropTypes.string,
  openSection: PropTypes.bool,
};

export { DetailPayments };
