import React from 'react';
import Translations from '@app/translations/globalTranslations';
import Error from '@pedidosya/web-fenix/pages/Error';
import useIsWebView from '@app/hooks/useIsWebView';
import { Container } from './ErrorPage.style';
import { DeepLink } from '@app/utils/deeplink';
import { getWindow } from '@app/utils/window';

const ErrorPage = () => {
  const isWebView = useIsWebView();
  const deeplink = DeepLink.home.ios;
  const wn = getWindow();
  const onBack = () => {
    if (isWebView) {
      wn.location.href = deeplink;
    } else {
      wn.location.href = '/';
    }
  };

  const retry = () => {
    wn.location.reload();
  };

  return (
    <Container>
      <Error
        errorCode={500}
        primaryActionLabel="Reintentar"
        primaryActionClick={retry}
        secondaryActionLabel={Translations.buttonLabelError.defaultMessage}
        secondaryActionClick={onBack}
      />
    </Container>
  );
};

export { ErrorPage };
