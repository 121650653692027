import React, { useState, useEffect } from 'react';
import { PaymentsCode } from '@app/config/paymentsCode';

const useDetailPayments = (payments, openSection) => {
  const [open, setOpen] = useState(openSection);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (payments) {
      const result = payments.map((element) => {
        if (PaymentsCode[element.code]) {
          return { ...element, icon: PaymentsCode[element.code] };
        }

        return element;
      });
      setItems(result);
    }
  }, [payments]);

  return {
    value: '',
    open,
    setOpen,
    items,
  };
};

export { useDetailPayments };
