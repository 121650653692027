import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { AddressContainer, ContentContainer, IconContainer } from './AddressItem.style';

const AddressItem = memo(({ icon, title, description }) => {
  return (
    <AddressContainer>
      <IconContainer>{icon}</IconContainer>
      <ContentContainer>
        <Typography
          token="font-body-midcontrast-sentence-small"
          color="text-color-action-enabled-quiet"
        >
          {title}
        </Typography>
        <Typography token="font-label-midcontrast-medium" color="text-color-action-enabled-loud">
          {description}
        </Typography>
      </ContentContainer>
    </AddressContainer>
  );
});

AddressItem.defaultProps = {
  icon: <></>,
  title: '',
  description: '',
};

AddressItem.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export { AddressItem };
