import React from 'react';
import Cash from '@pedidosya/web-fenix/icons/Cash';
import CardIcon from '@pedidosya/web-fenix/icons/CardIcon';
/**
 * PaymentsCode
 *
 * @description Constants by code to get object with icon
 */
export const PaymentsCode = {
  CASH: <Cash />,
  THIRD_PARTY_OFFLINE: <CardIcon />,
};
