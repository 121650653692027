import { OrderDetailRules } from '@app/utils/engine';
import { useEffect, useState } from 'react';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { CancelEntities } from '@app/config/cancelEntities';
import { PaymentMethods } from '@app/config/paymentMethods';
import { ProductStatus } from './DetailProducts/ProductStatus';
import { Components } from '@app/config/componentsMap';
import Some from 'lodash/some';

export const useDetail = (data) => {
  const [components, setComponents] = useState([]);
  const [showFloating, setShowFloating] = useState(false);
  const {
    country: { shortName },
    mock,
  } = usePublicEnv();

  useEffect(() => {
    const initial = async () => {
      const result = await OrderDetailRules({
        orderState: data?.order?.status,
        reason: data?.order?.reason,
        cancelledByUser: data?.cancellationInformation?.cancelledBy === CancelEntities.USER,
        pdi: hasProductWithPDI(),
        cash: isOrderWithCash(),
      });
      setComponents(result?.block);
    };

    initial();
  }, [data]);

  /**
   * hasProductWithPDI
   *
   * @description Function to determinate if there is a product with some PDI
   * @returns {boolean}
   */
  const hasProductWithPDI = () => {
    return Some(data?.order?.items, (element) => element.status === ProductStatus.MISSING);
  };

  /**
   * isOrderWithCash
   *
   * @description Function to determinate if the order was make with cash
   * @returns {boolean}
   */
  const isOrderWithCash = () => {
    return Some(data?.paymentMethod, (element) => element.code === PaymentMethods.CASH);
  };

  const defaultOrder = [
    { component: Components.DetailRefund, open: true },
    { component: Components.ListCompensantions, open: true },
    { component: Components.DetailRemedies, open: true },
    { component: Components.DetailProducts, open: true },
    { component: Components.DetailAddress, open: false },
    { component: Components.DetailSummary, open: false },
    { component: Components.DetailPayments, open: false },
  ];

  return {
    components: components && components.length ? components : defaultOrder,
    setShowFloating,
    showFloating,
    shortName,
    mock,
  };
};
