import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Translation from '@app/translations/globalTranslations';
import { useIntl, FormattedMessage } from 'react-intl';
import { MonthStyle, HourStyle } from './DateFormatter.style';
import isFunction from 'lodash/isFunction';

const DateFormatter = memo(({ date, hour12, showOnlyDate, children }) => {
  const { formatMessage, formatDateToParts } = useIntl();
  const datePart = formatDateToParts(date, {
    year: 'numeric',
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    fractionalSecondDigits: 3,
    hour12: hour12,
  });

  if (showOnlyDate) {
    if (isFunction(children)) {
      return children(
        formatMessage(Translation['status_date_year_format'], {
          day: datePart[2].value,
          month: datePart[4].value.replace('.', ''),
          year: datePart[6].value,
        }),
      );
    }
    return (
      <MonthStyle>
        {formatMessage(Translation['status_date_year_format'], {
          day: datePart[2].value,
          month: datePart[4].value.replace('.', ''),
          year: datePart[6].value,
        })}
      </MonthStyle>
    );
  }

  return (
    <>
      <MonthStyle>
        {formatMessage(Translation['status_date_format'], {
          day: datePart[2].value,
          dayName: datePart[0].value,
          month: datePart[4].value.replace('.', ''),
        })}
      </MonthStyle>
      <HourStyle>
        {formatMessage(Translation['status_datetime_format'], {
          hour: datePart[8].value,
          minute: datePart[10].value,
          period: datePart[12]
            ? datePart[12].value.replace(/\s/, '').replace('.', '').replace('.', '')
            : '',
        })}
      </HourStyle>
    </>
  );
});

export const useFormatter = () => {
  const { formatMessage, formatDateToParts } = useIntl();

  const formatterDate = (date) => {
    const datePart = formatDateToParts(date, {
      year: 'numeric',
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      fractionalSecondDigits: 3,
      hour12: true,
    });
    if (datePart) {
      return formatMessage(Translation['status_date_year_format'], {
        day: datePart[2].value,
        month: datePart[4].value.replace('.', ''),
        year: datePart[6].value,
      });
    }
    return '';
  };

  return { formatterDate };
};

DateFormatter.defaultProps = {
  date: '',
  hour12: true,
  showOnlyDate: false,
};

DateFormatter.propTypes = {
  date: PropTypes.string.isRequired,
  hour12: PropTypes.bool,
  showOnlyDate: PropTypes.bool,
};

export { DateFormatter };
