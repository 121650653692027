import React from 'react';
import type { FC } from 'react';
import type { RetryOnErrorProps } from './RetryOnError.type';
import { useIntl } from 'react-intl';
import { Container } from '@app/components/Container';
import { Text, ActionContainer, LoaderContainer } from './RetryOnError.style';
import Translations from './RetryOnError.translation';
import Button from '@pedidosya/web-fenix/atoms/Button';
import ButtonLoader from '@pedidosya/web-fenix/animations/ButtonLoader';

export const RetryOnError: FC<RetryOnErrorProps> = ({ isLoading, onClick }) => {
  const { formatMessage } = useIntl();

  return (
    <Container pTop="16" pBottom="16" pLeft="16" pRight="16">
      {!isLoading ? (
        <>
          <Text component="p" token="text-title-small" margin="0px 0px 18px 0px">
            {formatMessage(Translations['retryOnError.title'])}
          </Text>
          <ActionContainer>
            <Button label={formatMessage(Translations['retryOnError.action'])} onClick={onClick} />
          </ActionContainer>
        </>
      ) : null}
      {isLoading ? (
        <LoaderContainer>
          <ButtonLoader color="shape-color-surface-tertiary" />
        </LoaderContainer>
      ) : null}
    </Container>
  );
};
