import React from 'react';
import styled from 'styled-components';
import ArrowDown from '@pedidosya/web-fenix/icons/ChevronDown';

const AccordionContainer = styled.div`
  border: none;
  outline: none;
  transition: 0.4s;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
`;

const TitleChildren = styled.div`
  -webkit-box-flex: 1;
  flex-grow: 1;
`;
const Arrow = styled(ArrowDown)`
  transform: ${(props) => (props.expanded ? 'rotate(180deg);' : 'rotate(0deg);')};
  transition: 0.4s;
  margin-left: 16px;
`;

export { TitleContainer, TitleChildren, AccordionContainer, Arrow };
