import React from 'react';
import type { FC } from 'react';
import type { RefundItemsProps } from '@app/detail/presentation/components/Refunds/models';
import {
  AmountContainer,
  DescriptionContainer,
  IconContainer,
  ItemContainer,
  Title,
  ImageContainer,
} from './RefundItemDefault.style';
import { Formatter } from '@app/components/NumberFormatter';
import { useIntl } from 'react-intl';
import { ImageLoader } from '@app/components/ImageLoader';
import Translations from './RefundItemDefault.translation';

export const RefundItemDefault: FC<RefundItemsProps> = ({ data, country }) => {
  const { formatMessage } = useIntl();
  const amountFormated = Formatter(parseFloat(data.amount as any), country);

  return (
    <ItemContainer>
      <DescriptionContainer>
        <ImageContainer>
          <ImageLoader
            src={data.image}
            width={32}
            height={32}
            alt="Payment icon"
            containSize={true}
          />
        </ImageContainer>
        <div>
          <Title
            token="font-body-midcontrast-sentence-medium"
            color="text-color-action-enabled-loud"
            component="p"
          >
            {data?.description}
          </Title>
          <Title
            token="font-body-midcontrast-sentence-small"
            color="text-color-action-activated-quiet"
            component="p"
          >
            {formatMessage(Translations['refundItemDefault.message'])}
          </Title>
        </div>
      </DescriptionContainer>
      {data.amount && parseFloat(data.amount as any) > 0.0 ? (
        <AmountContainer>
          <Title token="text-base-large" color="text-color-action-enabled-loud" component="p">
            {amountFormated}
          </Title>
        </AmountContainer>
      ) : (
        <></>
      )}
    </ItemContainer>
  );
};
