import { defineMessages } from 'react-intl';

const messages = defineMessages({
  extraPayment_title: {
    id: 'OA_orderDetail_extraPayment_title',
    defaultMessage: 'Cobro extra',
  },
});

export default messages;
