import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const ItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding-top: ${(props) => props.theme.space('space-04')};
  padding-bottom: ${(props) => props.theme.space('space-04')};
  &:last-child {
    border-bottom: 0px;
    padding-bottom: 16px;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: ${(props) => props.theme.space('space-06')};
`;

const IconContainer = styled.div`
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.color('shape-color-stroke-primary')};
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled(Typography)`
  margin-bottom: ${(props) => props.theme.space('space-02')};
`;

const AmountContainer = styled.div`
  flex: 0 1 0%;
  display: flex;
  min-width: 90px;
  margin-left: 8px;
  text-align: right;
  flex-direction: column;
`;

const MessageContainer = styled.div`
  margin-top: ${(props) => props.theme.space('space-08')};
`;

const ImageContainer = styled.div`
  flex-shrink: 0;
`;

export {
  ItemContainer,
  DescriptionContainer,
  IconContainer,
  Title,
  AmountContainer,
  MessageContainer,
  ImageContainer,
};
