import React from 'react';
import styled from 'styled-components';

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding-top: ${(props) => props.theme.space('space-08')};
  padding-bottom: ${(props) => props.theme.space('space-08')};
  border-bottom: ${(props) => `1px solid ${props.theme.color('shape-color-stroke-primary')}`};
  &:last-child {
    border-bottom: 0px;
    padding-bottom: 0px;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${(props) => props.theme.space('space-04')};
`;

export { ItemContainer, DescriptionContainer };
