export enum PaymentMethodsText {
  COUPON = 'cupón',
  CASH = 'efectivo',
  ONLINE = 'online',
  WALLET = 'wallet',
  CREDIT_CARD = 'tarjeta',
  DEBIT_CARD = 'tarjeta',
  CARD = 'tarjeta',
  VOUCHER = 'cupón',
  BANK_TRANSFER = 'transferencia',
}
