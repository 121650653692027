import { defineMessages } from 'react-intl';

export default defineMessages({
  'refundItemCard.credit_message': {
    id: 'refundItemCard.credit.message',
    defaultMessage: 'Tu banco o emisor puede tardar hasta 7 días hábiles en acreditar tu dinero.',
  },
  'refundItemCard.credit_action': {
    id: 'refundItemCard.credit.action',
    defaultMessage: 'Cómo veo mi reembolso',
  },
  'refundItemCard.refund_amount_message': {
    id: 'refundItemCard.refund_amount.message',
    defaultMessage: 'Verás el monto actualizado en tu cuenta como el mismo movimiento.',
  },
  'refundItemCard.refund_amount_action': {
    id: 'refundItemCard.refund_amount.action',
    defaultMessage: 'Cómo lo veo en mi cuenta',
  },
  'refundItemCard.void_message': {
    id: 'refundItemCard.void.message',
    defaultMessage: 'El movimiento desaparecerá de tu cuenta en hasta 7 días hábiles.',
  },
  'refundItemCard.void_action': {
    id: 'refundItemCard.void.action',
    defaultMessage: 'Cómo veo mi pago anulado',
  },
});
