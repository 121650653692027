import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Translation from './DetailSummary.translation';
import { useIntl } from 'react-intl';
import { Container } from '@app/components/Container';
import { Accordion } from '@app/components/Acoordion';
import { SummaryItem } from './SummaryItem';
import { TotalItem } from './TotalItem';
import { ItemsContainer, BillingContainer } from './DetailSummary.style';
import { BillingItem } from './BillingItem';
import { useDetailSummary } from './useDetailSummary';

const DetailSummary = memo(
  ({
    total,
    summary,
    currencyIso,
    billingAvailable,
    billingDocumentId,
    orderId,
    countryId,
    country,
    openSection,
  }) => {
    const { formatMessage } = useIntl();
    const [open, setOpen] = useState(openSection);
    const { isLoading, isError, downloadBilling } = useDetailSummary(
      orderId,
      billingDocumentId,
      countryId,
    );

    return (
      <Container pBottom="16">
        <Accordion expanded={open} toggle={() => setOpen(!open)}>
          <Accordion.title>
            <Typography token="font-subtitle-highcontrast-sentence-large" component="h4">
              {formatMessage(Translation.summary_title)}
            </Typography>
          </Accordion.title>
          <Accordion.body>
            {summary.map((element, index) => {
              return (
                <ItemsContainer key={index}>
                  <SummaryItem
                    description={element.text}
                    image={element?.imageUrl}
                    amount={element.amount}
                    amountNoDiscount={element?.amountNoDiscount}
                    currencyIso={currencyIso}
                    country={country}
                  ></SummaryItem>
                </ItemsContainer>
              );
            })}
            <TotalItem total={total} currencyIso={currencyIso} country={country} />
          </Accordion.body>
        </Accordion>
        {billingAvailable && open ? (
          <BillingContainer>
            <BillingItem onTryDownload={downloadBilling} isLoading={isLoading} />
            {isError && (
              <Typography
                color="shape-color-surface-tertiary"
                component="p"
                token="font-subtitle-highcontrast-sentence-medium"
              >
                {formatMessage(Translation.billing_download_error)}
              </Typography>
            )}
          </BillingContainer>
        ) : (
          <></>
        )}
      </Container>
    );
  },
);

DetailSummary.defaultProps = {
  summary: [],
  total: 0,
  currencyIso: 'UYU',
  billingAvailable: false,
  billingDocumentId: '',
  orderId: '',
  countryId: '',
  country: '',
  openSection: false,
};

DetailSummary.propTypes = {
  total: PropTypes.number.isRequired,
  currencyIso: PropTypes.string.isRequired,
  summary: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      amount: PropTypes.number.isRequired,
      amountNoDiscount: PropTypes.number,
      text: PropTypes.string.isRequired,
    }),
  ),
  billingAvailable: PropTypes.bool,
  billingDocumentId: PropTypes.string,
  orderId: PropTypes.string,
  countryId: PropTypes.string,
  country: PropTypes.string,
  openSection: PropTypes.bool,
};

export { DetailSummary };
