import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import Translations from '@app/translations/globalTranslations';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Image from '@pedidosya/web-fenix/foundations/Image';
import VendorDefault from '@app/assets/images/vendor_default.svg';
import Button from '@pedidosya/web-fenix/atoms/Button';
import HelpCenter from '@pedidosya/web-fenix/icons/HelpCenter';
import { useIntl } from 'react-intl';
import { NavBar } from '@app/components/NavBar';
import { Verticals } from '@app/config/verticals';
import { getTranslationKey } from '@app/utils/verticalTranslation';
import { MockSwitch } from '@app/components/MockSwitch';
import { ACTIONS } from '@app/utils/deeplink';
import { Constants } from '@app/config/constants';
import { useDetailHeader } from './useDetailHeader';
import { useTracker } from '@app/contexts/TrackerProvider';
import { usePublicEnv } from '@app/contexts/PublicEnv';
/**
 * Styled
 */
import {
  Container,
  Shape,
  TextContainer,
  MockContainer,
  DefaultImage,
  HelpCenterContainer,
} from './DetailHeader.style';

const DetailHeader = memo(
  ({ banner, name, businessType, mock, vendorId, vendorSlug, cityName, orderId, orderStatus }) => {
    const ref = useRef();
    const { showHeader } = usePublicEnv();
    const { partnerClicked } = useTracker();
    const { formatMessage } = useIntl();
    const { handleBack, handleEvent, handleHelp, hasSafe } = useDetailHeader(orderId, orderStatus);
    const vertical = formatMessage(Translations[getTranslationKey(businessType)]);
    const storeName =
      businessType !== Verticals.COURIER && businessType !== Verticals.COURIER_BUSINESS
        ? name
        : formatMessage(Translations.global_courier_header_title);
    const handlePartnerClick = () => {
      partnerClicked(orderId, orderStatus);
      handleEvent(ACTIONS.SHOPLIST, Constants.DEEPLINK, {
        partnerId: vendorId,
        city: cityName,
        name: vendorSlug,
      });
    };

    return (
      <>
        <Container src={banner}>
          <Shape ref={ref}></Shape>
          <TextContainer>
            <Typography
              component="p"
              token="font-headline-highcontrast-small"
              color="shape-color-surface-primary"
            >
              {storeName}
            </Typography>
            {businessType !== Verticals.COURIER && businessType !== Verticals.COURIER_BUSINESS ? (
              <Typography
                component="span"
                token="font-label-highercontrast-underline-medium"
                color="shape-color-surface-primary"
                onClick={handlePartnerClick}
              >
                {formatMessage(Translations.goToAnyVertical, {
                  vertical: vertical,
                  name: vendorSlug,
                  city: cityName,
                })}
              </Typography>
            ) : (
              <></>
            )}
          </TextContainer>
          {!banner && (
            <DefaultImage>
              <Image src={VendorDefault} alt="Partner image" />
            </DefaultImage>
          )}
          <HelpCenterContainer hasSafe={hasSafe}>
            <Button
              fullWidth={true}
              type="button"
              size="small"
              hierarchy="secondary"
              label={formatMessage(Translations.detail_header_help_center)}
              icon={<HelpCenter size="medium" color="shape-color-background-primary" />}
              onClick={handleHelp}
            />
          </HelpCenterContainer>
          {mock && (
            <MockContainer>
              <MockSwitch />
            </MockContainer>
          )}
        </Container>
        {showHeader && <NavBar ref={ref} onBack={handleBack} name={storeName} />}
      </>
    );
  },
);

DetailHeader.defaultProps = {
  banner: '',
  name: '',
  businessType: Verticals.RESTAURANT,
  mock: false,
  vendorId: '',
  vendorSlug: '',
  cityName: '',
  orderId: '',
  orderStatus: '',
};

DetailHeader.propTypes = {
  banner: PropTypes.string,
  name: PropTypes.string.isRequired,
  businessType: PropTypes.string.isRequired,
  mock: PropTypes.bool,
  vendorId: PropTypes.string.isRequired,
  vendorSlug: PropTypes.string,
  cityName: PropTypes.string,
  orderId: PropTypes.string,
  orderStatus: PropTypes.string,
};

export { DetailHeader };
