import React from 'react';
import styled from 'styled-components';

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin-top: ${(props) => props.theme.space('space-10')};
`;

const ButtonSide = styled.div`
  width: ${(props) => (props.length > 1 ? '50%' : '100%')};
  & > div {
    padding-right: 8px;
    padding-left: 8px;
  }
  &:last-child {
    & > div {
      padding-right: 0px;
    }
  }
  &:first-child {
    & > div {
      padding-left: 0px;
    }
  }
`;

const TextContainer = styled.div`
  text-align: center;
`;

export { ActionsContainer, TextContainer, ButtonSide };
