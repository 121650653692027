import React from 'react';
import styled from 'styled-components';

const DateFormat = styled.span`
  position: relative;
`;

const MonthStyle = styled(DateFormat)`
  margin-right: 0px;
`;

const HourStyle = styled(DateFormat)`
  margin-left: 0px;
`;

export { MonthStyle, HourStyle };
