import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import ProductDefault from '@app/assets/images/product_default.png';
import { Tag } from '@app/components/Tag';
import { useIntl } from 'react-intl';
import {
  AmountSection,
  DescriptionSection,
  ImageSection,
  Notes,
  Description,
  Amount,
  TagContainer,
  QuantitySection,
  AmountContainer,
  ModifiedText,
  GridContainer,
} from './ProductItem.style';
import { ImageLoader } from '@app/components/ImageLoader';
import { ProductStatus } from '@app/components/Detail/DetailProducts/ProductStatus';
import LocalTranslations from './ProductItem.translation';
import { useProductItem } from './useProductItem';

const ProductItem = memo(({ product, country, state, tag }) => {
  const { formatMessage } = useIntl();
  const {
    quantity,
    quantityModifications,
    getTag,
    productEnabled,
    hasTag,
    amount,
    amountModified,
    amountColor,
    quantityColor,
  } = useProductItem({
    product,
    country,
    state,
    tag,
  });

  return (
    <>
      {hasTag ? (
        <TagContainer>
          <Tag label={getTag(state)} />{' '}
        </TagContainer>
      ) : (
        <></>
      )}
      <GridContainer data-testid="product" hasTag={hasTag}>
        <ImageSection>
          <ImageLoader
            src={product.image}
            fallback={ProductDefault}
            state={productEnabled ? 'enable' : 'disable'}
          />
        </ImageSection>
        <DescriptionSection>
          <Typography
            component="h6"
            token="font-subtitle-highcontrast-sentence-medium"
            color={productEnabled ? 'text-color-primary' : 'text-color-action-disabled-default'}
          >
            {product.name}
          </Typography>
          <Description
            component="p"
            token="font-body-midcontrast-sentence-small"
            color={productEnabled ? 'text-color-primary' : 'text-color-action-disabled-default'}
          >
            {product.description}
          </Description>
          <Typography
            component="p"
            token="font-body-midcontrast-sentence-small"
            color={productEnabled ? 'text-color-primary' : 'text-color-action-disabled-default'}
          >
            {product.options}
          </Typography>
          {product.note && (
            <Notes
              component="p"
              token="font-body-midcontrast-sentence-small"
              color={productEnabled ? 'text-color-primary' : 'text-color-action-disabled-default'}
            >
              <b>{formatMessage(LocalTranslations.products_note)}:</b> {product.note}
            </Notes>
          )}
        </DescriptionSection>
        <AmountSection hasPriceChanged={product?.modification?.amount}>
          <QuantitySection>
            {quantityModifications ? (
              <Typography
                component="span"
                token="font-body-midcontrast-strikethrough-small"
                color={'text-color-action-disabled-default'}
              >
                {quantityModifications}
              </Typography>
            ) : null}
            <Typography
              component="span"
              token="font-subtitle-highcontrast-sentence-large"
              color={quantityColor}
            >
              {quantity}
            </Typography>
          </QuantitySection>
          <AmountContainer>
            {amountModified ? (
              <Amount
                component="span"
                token="font-body-midcontrast-strikethrough-small"
                color="text-color-action-disabled-default"
              >
                {amountModified}
              </Amount>
            ) : null}
            <Amount
              component="span"
              token="font-subtitle-highcontrast-sentence-large"
              color={amountColor}
            >
              {amount}
            </Amount>
          </AmountContainer>
        </AmountSection>
      </GridContainer>
      {product?.modification?.description ? (
        <ModifiedText token="text-base-medium" color="text-color-tertiary" component="p">
          {product?.modification?.description}
        </ModifiedText>
      ) : null}
    </>
  );
});

ProductItem.defaultProps = {
  product: {},
  country: '',
  state: ProductStatus.DELIVERD,
};

ProductItem.propTypes = {
  product: PropTypes.shape({
    itemId: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    amount: PropTypes.number,
    quantity: PropTypes.number,
    note: PropTypes.string,
    options: PropTypes.string,
    showUnit: PropTypes.bool,
    unit: PropTypes.string,
    modifications: PropTypes.shape({
      description: PropTypes.string,
      amount: PropTypes.number,
      quantity: PropTypes.number,
    }),
  }),
  country: PropTypes.string,
  state: PropTypes.oneOf(Object.values(ProductStatus)),
};

export { ProductItem };
