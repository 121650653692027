import React, { useContext, useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import BrandLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { HttpClient } from '@app/utils/http';
import { Urls } from '@app/config/urls';
import { handleItem } from '@app/utils/storage';
import { Constants } from '@app/config/constants';
import { ErrorPage } from '@app/components/ErrorPage';
import { sentryHub } from '@app/utils/sentry';
import { getWindow } from '@app/utils/window';

const PublicEnvContext = createContext();

export const getPublicEnv = async () => {
  const wn = getWindow();
  const urlParams = new URLSearchParams(wn.location.search);
  const orderId = urlParams.get('orderId');
  const { data } = await HttpClient.getInstance().get(`${Urls.ENV}?orderId=${orderId}`);
  handleItem(Constants.MOCK_FLAG, data.mock);
  return data;
};

export const usePublicEnv = () => {
  return useContext(PublicEnvContext);
};

export const PublicEnvProvider = ({ children, context, getPublicEnv }) => {
  const [publicEnv, setPublicEnv] = useState(context);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (publicEnv) {
      handleItem(Constants.MOCK_FLAG, publicEnv.mock);
      return;
    }
    getPublicEnv().then(setPublicEnv).catch(setError);
  }, [getPublicEnv, publicEnv]);

  if (error) {
    sentryHub.withScope((scope) => {
      scope.setTransactionName('ErrorGetPublicEnv');
      sentryHub.captureException(new Error(JSON.stringify(error?.response?.data)));
    });
    return <ErrorPage />;
  }

  if (!publicEnv) return <BrandLoader position="center" />;

  return <PublicEnvContext.Provider value={publicEnv}>{children}</PublicEnvContext.Provider>;
};

export const publicEnvShape = {
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  userId: PropTypes.number,
  sessionId: PropTypes.string,
  deviceOS: PropTypes.string,
  appVersion: PropTypes.string,
  showHeader: PropTypes.bool,
  redirectToBasketRecreation: PropTypes.string,
};
