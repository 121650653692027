import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TagLabel } from './TagLabel';
import { ProductStatus } from '@app/components/Detail/DetailProducts/ProductStatus';
import { format } from '@pedidosya/currency-formatter';

export const useProductItem = ({ product, country, state }) => {
  const { formatMessage } = useIntl();

  const amount = useMemo(() => {
    if (product?.amount && country) {
      return format(parseFloat(product?.amount), country).price;
    }
    return 0;
  }, [product?.amount, country]);

  const amountModified = useMemo(() => {
    if (product?.modification?.amount) {
      return format(parseFloat(product?.modification?.amount), country).price;
    }
    return 0;
  }, [product?.modification?.amount, country]);
  /**
   * Function for determine with type of string
   * show to user based on product type
   * isWithWeight means that the product is Weightable
   * instead of the product is by units
   */
  const quantity = useMemo(() => {
    if (product?.showUnit && product?.quantity) {
      return `${product.quantity} ${product?.unit}`;
    } else if (!product?.showUnit && product?.quantity) {
      return `${parseInt(product.quantity)}x`;
    }

    return '';
  }, [product]);

  /**
   * Function for determine with type of string
   * show to user based on product type
   * isWithWeight means that the product is Weightable
   * instead of the product is by units
   */
  const quantityModifications = useMemo(() => {
    if (!product?.modification?.quantity) {
      return '';
    }
    if (product?.showUnit) {
      return `${product?.modification?.quantity} ${product?.unit}`;
    }
    return `${parseInt(product?.modification?.quantity)}x`;
  }, [product]);

  /**
   * getTag
   *
   * @description Function to get label for tag
   * @param {string} state - Product status
   * @returns {string}
   */
  const getTag = (state) => {
    const label = TagLabel[state];
    if (label) {
      return formatMessage(label);
    }

    return '';
  };

  /**
   * Function to know if a product
   * has status enabled
   */
  const productEnabled = useMemo(() => {
    return state !== ProductStatus.SOLD_OUT && state !== ProductStatus.MISSING;
  }, [state]);

  /**
   * Function to know if a product
   * has any tag
   */
  const hasTag = useMemo(() => {
    return state && state !== ProductStatus.DELIVERED;
  }, [state]);

  const amountColor = useMemo(() => {
    if (productEnabled && amountModified) {
      return 'text-color-feedback-informative';
    } else if (productEnabled && !amountModified) {
      return 'text-color-primary';
    } else {
      return 'text-color-action-disabled-default';
    }
  }, [productEnabled, amountModified]);

  const quantityColor = useMemo(() => {
    if (productEnabled && quantityModifications) {
      return 'text-color-feedback-informative';
    } else if (productEnabled && !quantityModifications) {
      return 'text-color-primary';
    } else {
      return 'text-color-action-disabled-default';
    }
  }, [quantityModifications, productEnabled]);

  return {
    hasTag,
    productEnabled,
    getTag,
    quantityModifications,
    quantity,
    amount,
    amountModified,
    amountColor,
    quantityColor,
  };
};
