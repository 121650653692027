import React from 'react';
import Delivery from '@pedidosya/web-fenix/icons/DeliveryBike';
import DineIn from '@pedidosya/web-fenix/icons/DineIn';
import PickUp from '@pedidosya/web-fenix/icons/PickUp';
import Location from '@pedidosya/web-fenix/icons/Location';
import Comment from '@pedidosya/web-fenix/icons/Comment';
/**
 * AddressCode
 *
 * @description Constants by code to get object with icon
 */
export const AddressCode = {
  DELIVERY: {
    icon: <Delivery />,
    translateKey: 'delivery_title',
    translateKeyTitle: 'shipping_type_label',
  },
  OWN_DELIVERY: {
    icon: <Delivery />,
    translateKey: 'delivery_title',
    translateKeyTitle: 'shipping_type_label',
  },
  VENDOR_DELIVERY: {
    icon: <Delivery />,
    translateKey: 'delivery_title',
    translateKeyTitle: 'shipping_type_label',
  },
  ADDRESS: {
    icon: <Location />,
    translateKeyTitle: 'address_label',
  },
  ADDRESS_ORIGIN: {
    icon: <Location />,
    translateKeyTitle: 'address_origin',
  },
  ADDRESS_DESTINATION: {
    icon: <Location />,
    translateKeyTitle: 'address_destination',
  },
  NOTE: {
    icon: <Comment />,
    translateKeyTitle: 'instructions_label',
  },
  PICKUP: {
    icon: <PickUp />,
    translateKey: 'pickup_title',
    translateKeyTitle: 'shipping_type_label',
  },
  DINEIN: {
    icon: <DineIn />,
    translateKey: 'dinein_title',
    translateKeyTitle: 'shipping_type_label',
  },
};
