export const Routes = {
  ORDER_BY_CODE: `/order-details/code/:code`,
  ORDER_BY_ORDERID: `/order-details`,
  ORDER_BY_ORDERIDTEST: `/order-details-test`,
  ORDER_BY_ORDERIDALTERNATIVE: `/order-details/order-details`, // This is for a bug in mobile
  NOTFOUND: `/order-details/404`,
  ORDER_CARD_MORE_INFORMATION: `/order-details/card-information/:orderId/:referenceId`,
  FEEDBACK: '/order-details/feedback/:country/:resourceId/:surveyId',
  CARD_REFUND_DETAIL: '/order-details/:orderId/:paymentMethod/detail',
};
