import { DetailRepository } from '@app/detail/data/repositories';
import { SectionRequest } from '@app/detail/domain/models';
import type { Detail } from '@app/detail/domain/services';

export class DetailService implements Detail {
  private repository: DetailRepository;

  constructor() {
    this.repository = new DetailRepository();
  }

  getSection<T>(payload: SectionRequest): Promise<T> {
    return this.repository.getSection(payload);
  }
}
