import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

const AddItemContainer = styled.div`
  margin-top: ${(props) => props.theme.space('space-08')};
  margin-bottom: ${(props) => props.theme.space('space-08')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: ${(props) => props.theme.space('space-08')};
`;

const AddItemTitle = styled(Typography)`
  text-align: center;
`;

const CTAContainer = styled.div`
  text-align: center;
  & > div {
    display: inline-block;
  }
`;

export { AddItemContainer, AddItemTitle, CTAContainer };
