import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Button from '@pedidosya/web-fenix/atoms/Button';
import { Container } from '@app/components/Container';
import { useDetailRemedies } from './useDetailRemedies';
import { ActionsContainer, TextContainer, ButtonSide } from './DetailRemedies.style';
import { Constants } from '@app/config/constants';
import isEmpty from 'lodash/isEmpty';

const DetailRemedies = memo(
  ({
    localReason,
    businessType,
    orderId,
    vendorId,
    orderStatus,
    cityName,
    vendorState,
    country,
    basketRecreationValue,
    remedies: rm,
  }) => {
    const { onClickButton } = useDetailRemedies(
      localReason,
      orderId,
      vendorId,
      businessType,
      orderStatus,
      cityName,
      country,
      basketRecreationValue,
    );

    if (!isEmpty(rm)) {
      return (
        <Container pBottom="16" pTop="20">
          <TextContainer>
            <Typography
              token="font-subtitle-highcontrast-sentence-medium"
              color="text-color-action-enabled-loud"
              component="p"
            >
              {rm?.title}
            </Typography>
          </TextContainer>
          <ActionsContainer>
            {rm?.actions.map((element) => {
              return (
                <ButtonSide key={element.title} length={rm?.actions.length}>
                  <div>
                    <Button
                      type="button"
                      size="medium"
                      fullWidth={true}
                      hierarchy={element?.hierarchy ? element?.hierarchy.toLowerCase() : 'primary'}
                      label={element.title}
                      onClick={() => onClickButton(element?.link, element?.title)}
                    />
                  </div>
                </ButtonSide>
              );
            })}
          </ActionsContainer>
        </Container>
      );
    }

    return <></>;
  },
);

DetailRemedies.defaultProps = {
  localReason: '',
  businessType: 'RESTAURANT',
  orderId: '',
  vendorId: '',
  orderStatus: '',
  cityName: '',
  vendorState: Constants.VENDOR_OPEN_STATE,
  country: '',
  remedies: {},
};

DetailRemedies.DetailRefund = {
  localReason: PropTypes.string,
  businessType: PropTypes.string,
  orderId: PropTypes.string,
  vendorId: PropTypes.string,
  orderStatus: PropTypes.string,
  cityName: PropTypes.string,
  vendorState: PropTypes.string,
  country: PropTypes.string,
  remedies: PropTypes.shape({
    title: PropTypes.string,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        deeplink: PropTypes.string,
        link: PropTypes.string,
        hierarchy: PropTypes.string,
        title: PropTypes.string,
        webLink: PropTypes.string,
      }),
    ),
  }),
};

export { DetailRemedies };
