import { defineMessages } from 'react-intl';

export default defineMessages({
  'refundTitle.void_title': {
    id: 'refundTitle.void_title',
    defaultMessage: 'Detalle de tu devolución',
  },
  'refundTitle.credit_title': {
    id: 'refundTitle.credit_title',
    defaultMessage: 'Detalle de tu devolución de {amount}',
  },
  'refundTitle.void_credit_title': {
    id: 'refundTitle.void_credit_title',
    defaultMessage: 'Detalle de tu devolución',
  },
  'refundTitle.update_amount_title': {
    id: 'refundTitle.update_amount_title',
    defaultMessage: 'Detalle de los cambios en tu pago',
  },
});
