import React from 'react';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { useOrderByCode } from './useOrderByCode';
import { ErrorPage } from '@app/components/ErrorPage';
/**
 * Components
 */
import { Detail } from '@app/components/Detail';

const OrderByCode = () => {
  const { data, isLoading, isError } = useOrderByCode();

  if (isLoading) {
    return <PeyaLoader position="center" />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <>
      <Detail {...data} />
    </>
  );
};

export { OrderByCode };
