import React, { memo } from 'react';
import type { FC } from 'react';
import type { RefundContingencyTextProps } from './RefundContiengencyText.type';
import { PaymentMethodsText } from '@shared/config';
import { PaymentMethodProxy } from '@app/detail/use_cases/proxy/PaymentsMethod';
import { useIntl } from 'react-intl';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import Translation from './RefundContingencyText.translation';

export const RefundContingencyText: FC<RefundContingencyTextProps> = memo(
  ({ finalMethod, originalMethod, count = 1 }) => {
    const { formatMessage } = useIntl();
    const proxy = new PaymentMethodProxy(originalMethod);
    const proxyFinal = new PaymentMethodProxy(finalMethod);

    if (proxy.isVoucher()) {
      if (!proxyFinal.acceptRefund()) {
        return (
          <Typography component="p" token="text-base-medium" color="text-color-tertiary">
            {formatMessage(Translation['refundContingencyText.not_refund_voucher'], { count })}
          </Typography>
        );
      }
      return (
        <Typography component="p" token="text-base-medium" color="text-color-tertiary">
          {PaymentMethodsText[finalMethod]
            ? formatMessage(Translation['refundContingencyText.voucher'], {
                instrument: PaymentMethodsText[finalMethod],
                count,
              })
            : formatMessage(Translation['refundContingencyText.default_voucher'], { count })}
        </Typography>
      );
    }

    if (proxy.isWallet()) {
      if (!proxyFinal.acceptRefund()) {
        return (
          <Typography component="p" token="text-base-medium" color="text-color-tertiary">
            {formatMessage(Translation['refundContingencyText.not_refund_wallet'], { count })}
          </Typography>
        );
      }
      return (
        <Typography component="p" token="text-base-medium" color="text-color-tertiary">
          {PaymentMethodsText[finalMethod]
            ? formatMessage(Translation['refundContingencyText.wallet'], {
                instrument: PaymentMethodsText[finalMethod],
              })
            : formatMessage(Translation['refundContingencyText.default_wallet'])}
        </Typography>
      );
    }
    return <></>;
  },
);
