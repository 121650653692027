import React, { useRef, memo } from 'react';
import PropTypes from 'prop-types';
import Image from '@pedidosya/web-fenix/foundations/Image';
import Translations from '@app/translations/globalTranslations';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import RatingIcon from '@pedidosya/web-fenix/icons/RatingIcon';
import isEmpty from 'lodash/isEmpty';
import useIsWebView from '@app/hooks/useIsWebView';
import { useInView } from 'react-intersection-observer';
import { useDetailStatus } from './useDetailStatus';
import { Container } from '@app/components/Container';
import { useIntl } from 'react-intl';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { DEVICESOS } from '@app/config/device';
import {
  ActionsContainer,
  Content,
  StartContainer,
  FloatingMainAction,
  StackContainer,
  LT,
  LTImage,
} from './DetailStatus.style';
import { DateFormatter } from '@app/components/DateFormatter';
import { DateFormat24 } from '@app/config/dateFormat';
import { FeedbackStates } from '@app/config/feedbackStates';
import { Constants } from '@app/config/constants';
import { ActionsButtons } from './ActionsButtons';

const DetailStatus = memo(
  ({
    status,
    feedback,
    businessType,
    registerDate,
    cancellationReason,
    country,
    feedbackPoint,
    onShowFloating,
    orderId,
    vendorId,
    feedbackResource,
    feedbackSurvey,
    orderStatus,
    vendorState,
    statusDetail,
    hasRefund,
  }) => {
    const { formatMessage } = useIntl();
    const { deviceOS } = usePublicEnv();
    const {
      remedies,
      mainAction,
      onClickButton,
      Image: Icon,
    } = useDetailStatus({
      status,
      feedback,
      businessType,
      cancellationReason,
      orderId,
      vendorId,
      feedbackResource,
      feedbackSurvey,
      country,
      orderStatus,
      statusDetail,
      hasRefund,
    });
    const isWebView = useIsWebView();
    const { ref: inViewRef, inView } = useInView({
      rootMargin: '-15px',
      onChange: (inView) => {
        if (mainAction && !inView && onShowFloating) {
          onShowFloating(!inView);
        }
      },
    });

    return (
      <Container pBottom="16">
        <StackContainer
          direction="row"
          grow={0}
          wrap="nowrap"
          alignItems="center"
          justify={isWebView ? 'flex-start' : 'center'}
        >
          <StackContainer grow="0" margin="0px 0px 0px 4px" width="64px">
            {remedies && Icon ? <Image src={Icon} alt="Order state image" /> : null}
          </StackContainer>
          <StackContainer direction="column" grow={0} shrink={1}>
            <Content>
              {/*  Adding order's state  */}
              {statusDetail?.title && (
                <Typography
                  component="span"
                  token="font-headline-highcontrast-small"
                  color="text-color-action-enabled-loud"
                >
                  {statusDetail?.title}
                </Typography>
              )}
              {/*  Adding cancel reasons if it have  */}
              {statusDetail?.subTitle && (
                <Typography
                  component="p"
                  token="font-label-midcontrast-medium"
                  color="text-color-action-enabled-loud"
                >
                  {statusDetail?.subTitle}
                </Typography>
              )}

              {/*  Adding date of order created  */}
              <Container pTop="4" pBottom="0" pLeft="0" pRight="0">
                <Typography
                  component="span"
                  token="font-body-midcontrast-sentence-small"
                  color="text-color-action-enabled-quiet"
                >
                  <DateFormatter
                    date={registerDate}
                    hour12={!DateFormat24.includes(country)}
                  ></DateFormatter>
                </Typography>
              </Container>

              {/* Adding feedback information */}
              {feedback === FeedbackStates.COMPLETED && (
                <StartContainer>
                  <Typography
                    component="span"
                    token="font-body-midcontrast-sentence-small"
                    color="text-color-action-enabled-quiet"
                  >
                    {formatMessage(Translations['status_feedback'])}
                  </Typography>
                  <Typography
                    component="span"
                    token="font-label-highercontrast-medium"
                    color="text-color-action-enabled-quiet"
                  >
                    {feedbackPoint}
                  </Typography>
                  <RatingIcon
                    filled={true}
                    color="shape-color-surface-communication-rating"
                    size="small"
                  />
                </StartContainer>
              )}
            </Content>
          </StackContainer>
        </StackContainer>
        {/*  Adding actions to repeat order or feedback  */}

        {!isEmpty(statusDetail?.actions) ? (
          <ActionsContainer>
            <ActionsButtons
              actions={statusDetail?.actions}
              onClickButton={onClickButton}
              ref={inViewRef}
            />
          </ActionsContainer>
        ) : (
          <></>
        )}

        {mainAction && !inView ? (
          <FloatingMainAction isIOS={DEVICESOS.IOS === deviceOS}>
            <Container pBottom="16">
              <ActionsButtons actions={statusDetail?.actions} onClickButton={onClickButton} />
            </Container>
          </FloatingMainAction>
        ) : (
          <></>
        )}
      </Container>
    );
  },
);

DetailStatus.defaultProps = {
  status: '',
  registerDate: '',
  businessType: '',
  feedback: '',
  cancellationReason: '',
  country: '',
  feedbackPoint: 0,
  onShowFloating: () => {},
  orderId: '',
  vendorId: '',
  feedbackResource: '',
  feedbackSurvey: '',
  orderStatus: '',
  vendorState: Constants.VENDOR_OPEN_STATE,
  statusDetail: {
    title: '',
    subTitle: '',
    actions: {},
  },
  hasRefund: false,
};

DetailStatus.propTypes = {
  status: PropTypes.string,
  statusDetail: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    actions: PropTypes.shape({
      feedback: PropTypes.shape({
        title: PropTypes.string,
        deeplink: PropTypes.number,
      }),
      repeat: PropTypes.shape({
        title: PropTypes.string,
        deeplink: PropTypes.string,
      }),
    }),
  }),
  registerDate: PropTypes.string,
  businessType: PropTypes.string,
  feedback: PropTypes.string,
  cancellationReason: PropTypes.string,
  country: PropTypes.string,
  feedbackPoint: PropTypes.number,
  onShowFloating: PropTypes.func,
  orderId: PropTypes.string,
  vendorId: PropTypes.string,
  feedbackResource: PropTypes.string,
  feedbackSurvey: PropTypes.string,
  orderStatus: PropTypes.string,
  vendorState: PropTypes.string,
  hasRefund: PropTypes.bool,
};

export { DetailStatus };
