import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { ItemContainer, DescriptionContainer } from './Items.style';
import { ImageLoader } from '@app/components/ImageLoader';
import { NumberFormatter } from '@app/components/NumberFormatter';

const Items = memo(function Items({ imageUrl, icon, description, amount, country }) {
  return (
    <ItemContainer>
      <DescriptionContainer>
        {imageUrl && !icon ? (
          <ImageLoader src={imageUrl} width={32} height={20} alt="Payment icon" />
        ) : (
          <></>
        )}
        {icon ? icon : <></>}
        <Typography
          token="font-label-midcontrast-medium"
          color="text-color-action-enabled-loud"
          component="p"
        >
          {description}
        </Typography>
      </DescriptionContainer>
      <div>
        {amount & parseFloat(amount) ? (
          <Typography
            token="font-label-midcontrast-medium"
            color="text-color-action-enabled-loud"
            component="p"
          >
            <NumberFormatter amount={amount} country={country} />
          </Typography>
        ) : (
          <></>
        )}
      </div>
    </ItemContainer>
  );
});

Items.defaultProps = {
  description: '',
  icon: null,
  image: '',
  amount: 0,
  country: '',
};

Items.propTypes = {
  description: PropTypes.string.isRequired,
  icon: PropTypes.node,
  image: PropTypes.string,
  amount: PropTypes.number,
  country: PropTypes.string,
};

export { Items };
