import React from 'react';
import PropTypes from 'prop-types';
import fenixTheme from '@pedidosya/web-fenix/theme';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import QueryClient from '@app/utils/query_client';
import {
  usePublicEnv,
  PublicEnvProvider,
  publicEnvShape,
  getPublicEnv,
} from '@app/contexts/PublicEnv';
import { createIntlProvider, getMessages } from '@app/contexts/IntlProvider';
import { SentryProvider } from '@app/contexts/SentryProvider';
import { RouterApp } from '@app/components/Router';
import { QueryClientProvider } from 'react-query';
import { LinkProvider } from '@app/contexts/LinkProvider';
import { TrackerProvider } from '@app/contexts/TrackerProvider';
import { LocalStyle } from '@app/components/LocalStyle';
import { GlobalError } from '@app/components/GlobalError';
import { SentrySSR } from '@app/shared/contexts/SentrySSR';

const IntlProvider = (props) => {
  const { locale } = usePublicEnv();
  return createIntlProvider(locale, getMessages)(props);
};

const Root = ({ context, messages, name, tracker, trackerConfig, isServer, sentryConfig }) => {
  return (
    <GlobalError>
      <SentrySSR
        sentryDSN={sentryConfig?.dsn}
        environment={context?.environment}
        enabled={sentryConfig?.isEnabled}
        isServer={isServer ? true : false}
      >
        <PublicEnvProvider context={context} getPublicEnv={getPublicEnv}>
          <SentryProvider isServer={isServer ? true : false}>
            <ThemeProvider theme={fenixTheme}>
              <>
                <LocalStyle />
                <FenixFonts />
                <IntlProvider initialMessages={messages}>
                  <QueryClientProvider client={QueryClient}>
                    <TrackerProvider tracker={tracker} config={trackerConfig}>
                      <LinkProvider>
                        <RouterApp />
                      </LinkProvider>
                    </TrackerProvider>
                  </QueryClientProvider>
                </IntlProvider>
              </>
            </ThemeProvider>
          </SentryProvider>
        </PublicEnvProvider>
      </SentrySSR>
    </GlobalError>
  );
};

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};

Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.shape(publicEnvShape),
  messages: PropTypes.shape({}),
  name: PropTypes.string,
  trackerConfig: PropTypes.object,
  isServer: PropTypes.bool,
  sentryConfig: PropTypes.object,
};

export default Root;
