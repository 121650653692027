import React from 'react';
import useIsWebView from '@app/hooks/useIsWebView';
import { useLinkContext } from '@app/contexts/LinkProvider';
import { WebViewEvents } from '@app/config/webViewEvents';
import { sentryHub } from '@app/utils/sentry';
import { getWindow } from '@app/utils/window';

const useDownloadFile = () => {
  const iswebView = useIsWebView();
  const { emitEvent } = useLinkContext();

  const downloadWeb = (fileName, content, fileType) => {
    const data = new Blob([new Uint8Array(content)]);
    const wn = getWindow();
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = wn.URL.createObjectURL(data, { type: fileType });
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      wn.URL.revokeObjectURL(link.href);
      link.parentNode.removeChild(link);
    }, 0);
  };

  const downloadWebView = (fileName, content, fileType) => {
    emitEvent(WebViewEvents.DOWNLOAD, {
      payload: {
        fileName,
        fileData: content,
        fileType,
      },
    });
  };
  /**
   * download
   *
   * @description Function to download files
   * @param {string} fileName - File name
   * @param {ArrayBuffer} content - Content of file in arraybuffer format
   * @param {string} fileType - mimetype of file
   */
  const download = (fileName, content, fileType) => {
    if (iswebView) {
      return downloadWebView(`${fileName}.pdf`, content, fileType);
    }

    return downloadWeb(`${fileName}.pdf`, content, fileType);
  };

  return {
    download,
  };
};

export { useDownloadFile };
