import React, { memo } from 'react';
import Button from '@pedidosya/web-fenix/atoms/Button';
import Translation from '../DetailProducts.translation';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { AddItemContainer, AddItemTitle, CTAContainer } from './AddItem.style';
import { useDeeplinks } from '@app/hooks/useDeeplinks';
import { ACTIONS } from '@app/utils/deeplink';
import { Constants } from '@app/config/constants';

const AddItem = memo(({ vendorName, addItemDmart, addItemDmartDeeplink, orderId }) => {
  const { handleEvent } = useDeeplinks();
  const { formatMessage } = useIntl();
  const handleClick = () => {
    if (addItemDmart) {
      window.location.href = addItemDmartDeeplink;
      return;
    }

    handleEvent(ACTIONS.ADD_ITEMS, Constants.DEEPLINK, {
      orderId: orderId,
    });
  };

  return (
    <AddItemContainer>
      <AddItemTitle
        component="p"
        token="font-subtitle-highcontrast-sentence-large"
        color="text-color-action-enabled-loud"
      >
        {formatMessage(Translation.products_add_item_title, { vendor: vendorName })}
      </AddItemTitle>
      <CTAContainer>
        <Button
          type="button"
          size="large"
          label={formatMessage(Translation.products_button_add_item)}
          onClick={handleClick}
        />
      </CTAContainer>
    </AddItemContainer>
  );
});

AddItem.defaultProps = {
  addItemDmart: false,
  addItemDmartDeeplink: '',
  vendorName: '',
  orderId: '',
};

AddItem.propTypes = {
  addItemDmart: PropTypes.bool,
  addItemDmartDeeplink: PropTypes.string,
  vendorName: PropTypes.string,
  orderId: PropTypes.string,
};

export { AddItem };
