import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@pedidosya/web-fenix/atoms/Typography';
import { ItemContainer, DescriptionContainer } from './PaymentItem.style';
import { NumberFormatter } from '@app/components/NumberFormatter';
import { ImageLoader } from '@app/components/ImageLoader';

const PaymentItem = memo(({ description, icon, image, amount, currencyIso, country }) => {
  return (
    <ItemContainer>
      <DescriptionContainer>
        {image && !icon ? (
          <ImageLoader src={image} width={32} height={20} alt="Payment icon" />
        ) : (
          <></>
        )}
        {icon ? icon : <></>}
        <Typography
          token="font-label-midcontrast-medium"
          color="text-color-action-enabled-loud"
          component="p"
        >
          {description}
        </Typography>
      </DescriptionContainer>
      <div>
        {amount && parseFloat(amount) > 0.0 ? (
          <Typography
            token="font-label-midcontrast-medium"
            color="text-color-action-enabled-loud"
            component="p"
          >
            <NumberFormatter amount={amount} currencyIso={currencyIso} country={country} />
          </Typography>
        ) : (
          <></>
        )}
      </div>
    </ItemContainer>
  );
});

PaymentItem.defaultProps = {
  description: '',
  icon: null,
  image: '',
  amount: 0,
  currencyIso: 'UYU',
  country: '',
};

PaymentItem.propTypes = {
  description: PropTypes.string.isRequired,
  icon: PropTypes.node,
  image: PropTypes.string,
  amount: PropTypes.number,
  currencyIso: PropTypes.string,
  country: PropTypes.string,
};

export { PaymentItem };
