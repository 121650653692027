import useIsWebView from '@app/hooks/useIsWebView';
import { useHistory } from 'react-router-dom';
import { WebViewEvents } from '@app/config/webViewEvents';
import { useDeeplinks } from '@app/hooks/useDeeplinks';
import { Constants } from '@app/config/constants';
import { ACTIONS } from '@app/utils/deeplink';
import { useUrlQuery } from '@app/hooks/useUrlQuery';
import { useTracker } from '@app/contexts/TrackerProvider';
import { useMemo } from 'react';

const useDetailHeader = (orderId, orderStatus) => {
  const { goBack } = useHistory();
  const { handleEvent } = useDeeplinks();
  const { helpClicked } = useTracker();
  const isWebView = useIsWebView();
  const query = useUrlQuery();
  const origin = useMemo(() => query.get(Constants.ORIGIN_PARAM) || '', []);

  const handleBack = () => {
    if (isWebView) {
      /**
       * To avoid ios webview stack
       * when flow come from pdi, and user make click
       * back button, should redirect to main instead of
       * to close webview
       */
      if (origin === Constants.ORIGIN_PDI || origin === Constants.ORIGIN_OMN_EMAIL) {
        return handleEvent(ACTIONS.HOME, Constants.DEEPLINK, {});
      }
      return handleEvent(WebViewEvents.CLOSE, Constants.WEBEVENT);
    }

    goBack();
  };

  const handleHelp = () => {
    helpClicked(orderId, orderStatus);
    handleEvent(ACTIONS.HELP_CENTER, Constants.DEEPLINK, { id: orderId });
  };

  return {
    handleBack,
    handleEvent,
    handleHelp,
    hasSafe: query.get('hasSafe') === 'true',
  };
};

export { useDetailHeader };
