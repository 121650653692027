import React, { memo } from 'react';
import type { FC } from 'react';
import type { RefundItemProps } from './RefundItem.type';
import { RefundItemCard } from '@app/detail/presentation/components/Refunds/components/RefundItemCard';
import { RefundItemVoucher } from '@app/detail/presentation/components/Refunds/components/RefundItemVoucher';
import { RefundItemWallet } from '@app/detail/presentation/components/Refunds/components/RefundItemWallet';
import { RefundItemBankTransfer } from '@app/detail/presentation/components/Refunds/components/RefundItemBankTransfer';
import { RefundItemDefault } from '@app/detail/presentation/components/Refunds/components/RefundItemDefault';
import { RefundTypeProxy } from '@app/detail/use_cases/proxy/RefundType';

export const RefundItem: FC<RefundItemProps> = memo(({ data, country, orderId, orderState }) => {
  const proxy = new RefundTypeProxy(data);
  if (proxy.isVoucher()) {
    return <RefundItemVoucher country={country} data={data} />;
  }

  if (proxy.isWallet()) {
    return <RefundItemWallet country={country} data={data} />;
  }

  if (proxy.isCard()) {
    return (
      <RefundItemCard country={country} data={data} orderId={orderId} orderState={orderState} />
    );
  }

  if (proxy.isBankTransfer()) {
    return <RefundItemBankTransfer country={country} data={data} orderId={orderId} />;
  }

  return <RefundItemDefault country={country} data={data} orderId={orderId} />;
});
