import { DEVICESOS, DEVICES } from '@app/config/device';
import { Routes } from '@app/config/routes';

export const DeepLink = {
  home: {
    ios: 'pedidosya://launcher',
    android: 'pedidosya://launcher',
    web: '/',
  },
  orderStatus: {
    ios: 'pedidosya://os/main?orderId={id}&origin=orderDetail',
    android: 'pedidosya://orderStatus?orderId={id}&origin=orderDetail',
    web: '/order-state?code={code}&origin=orderDetail&c={country}',
  },
  feedback: {
    ios: 'pedidosya://feedback?survey={surveyId}&resource={resourceId}&origin=orderDetail',
    android: 'pedidosya://feedback?survey={surveyId}&resource={resourceId}&origin=orderDetail',
    web: '/feedback/web?survey={surveyId}&resource={resourceId}&origin=orderDetail&device=web&language=es',
  },
  otherOrder: {
    ios: 'pedidosya://verticals?bt={vertical}&origin=orderDetail',
    android: 'pedidosya://verticals?bt={vertical}&origin=orderDetail',
    web: '/restaurantes?city={city}',
  },
  feedbackWeb: {
    ios: `/feedback/web?resource={resourceId}&survey={surveyId}&device=web&language=es&c={country}&origin=orderDetail&callback=/order-details?orderId={orderId}&c={country}`,
    android: `/feedback/web?resource={resourceId}&survey={surveyId}&device=web&language=es&c={country}&origin=orderDetail&callback=/order-details?orderId={orderId}&c={country}`,
    web: `/feedback/web?resource={resourceId}&survey={surveyId}&device=web&language=es&c={country}&origin=orderDetail&callback=/order-details?orderId={orderId}&c={country}`,
  },
  helpCenter: {
    ios: 'pedidosya://onlinehelp?order_id={id}&page_id=orderDetail&origin=orderDetail',
    android: 'pedidosya://onlinehelp?order_id={id}&page_id=orderDetail&origin=orderDetail',
    web: '/customer-service?orderId={id}&page_id=orderDetail&origin=orderDetail',
  },
  coupons: {
    ios: 'pedidosya://vouchers-section',
    android: 'pedidosya://vouchers-section',
    web: '/vouchers-wallet',
  },
  shopList: {
    ios: 'pedidosya://shop_detail/{partnerId}',
    android: 'pedidosya://shop_detail/{partnerId}',
    web: '/restaurantes/{city}/{name}-menu',
  },
  addItems: {
    ios: 'pedidosya://orderactions/itemsmodification?orderId={orderId}&flow=orderDetail&action=add-items',
    android:
      'pedidosya://orderactions/itemsmodification?orderId={orderId}&flow=orderDetail&action=add-items',
    web: '/',
  },
  basketRecreation: {
    ios: 'pedidosya://basketRecreation?referenceId={orderId}&referenceType=ORDER&flow=orderDetails&partnerId={partnerId}&country={country}',
    android:
      'pedidosya://basketRecreation?referenceId={orderId}&referenceType=ORDER&flow=orderDetails&partnerId={partnerId}&country={country}',
    web: '/basket-recreation/{orderId}/orders?flow=orderDetails',
  },
  wallet: {
    ios: 'pedidosya://wallet/home',
    android: 'pedidosya://wallet/home',
    web: '',
  },
  cardMoreInformation: {
    ios: '/order-details/card-information/{orderId}/{referenceId}',
    android: '/order-details/card-information/{orderId}/{referenceId}',
    web: '/order-details/card-information/{orderId}/{referenceId}',
  },
  newFeedback: {
    ios: `/order-details/feedback/{country}/{resourceId}/{surveyId}`,
    android: `/order-details/feedback/{country}/{resourceId}/{surveyId}`,
    web: `/order-details/feedback/{country}/{resourceId}/{surveyId}`,
  },
};

export const ACTIONS = {
  HOME: 'home',
  ORDER_STATUS: 'orderStatus',
  FEEDBACK: 'feedback',
  MAKE_ORDER: 'otherOrder',
  DETAIL: 'detail',
  FEEDBACK_WEB: 'feedbackWeb',
  HELP_CENTER: 'helpCenter',
  COUPONS: 'coupons',
  SHOPLIST: 'shopList',
  ADD_ITEMS: 'addItems',
  BASKET_RECREATION: 'basketRecreation',
  WALLET: 'wallet',
  CARD_MORE_INFORMATION: 'cardMoreInformation',
  NEW_FEEDBACK: 'newFeedback',
};

/**
 * generateDeepLink
 *
 * @description Function for generate deeplink based on os param
 * @param {string} action - Action to generate deep link
 * @param {string} os - OS of device (Ios | AndroidOS)
 * @param {Object} params - Any param to replace in final deeplink
 * @returns {string}
 */
export const generateDeepLink = (action, os, params) => {
  switch (os) {
    case DEVICESOS.ANDROID:
      return replaceAll(DeepLink[action].android, params);
    case DEVICESOS.IOS:
      return replaceAll(DeepLink[action].ios, params);
    case DEVICESOS.WEB:
      return replaceAll(DeepLink[action].web, params);
    default:
      return '';
  }

  function replaceAll(str, params) {
    let finalStr = str;
    for (const [key, value] of Object.entries(params)) {
      finalStr = finalStr.replace(`{${key}}`, value);
    }
    return finalStr;
  }
};

/**
 * generateLink
 *
 * @description Function for genereate link or deeplink in base to platform
 * @param {string} device - Name of device
 * @param {string} deviceOS - Operative System of device
 * @param {Object} params - Object with parameters to build final urls
 */
export const generateLink = (device, deviceOS, action, params) => {
  if (DEVICES.MOBILE === device || DEVICES.WEBVIEW === device) {
    return generateDeepLink(action, deviceOS, params);
  }
  return generateDeepLink(action, DEVICESOS.WEB, params);
};
