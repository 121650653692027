import React, { memo } from 'react';
import type { FC } from 'react';
import type { RefundTitleProps } from './RefundTitle.type';
import { useIntl } from 'react-intl';
import { Container, IconContainer } from './RefundTitle.style';
import { Formatter } from '@app/components/NumberFormatter';
import { Container as Block } from '@app/components/Container';
import { RefundReference } from '../RefundReference';
import Translations from './RefundTitle.translation';
import Alert from '@pedidosya/web-fenix/icons/Alert';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

export const RefundTitle: FC<RefundTitleProps> = memo(
  ({ cancellations = [], refunds, country, updates = [] }) => {
    const { formatMessage } = useIntl();
    let message = '';

    /**
     * Only credits elements
     */
    if (!cancellations.length && !updates.length && refunds.length) {
      /**
       * Sum all refunds
       */
      if (refunds.length > 1) {
        const amount = refunds.reduce((acc, element) => {
          return acc + parseFloat(element.amount.toString());
        }, 0);
        message = formatMessage(Translations['refundTitle.credit_title'], {
          amount: Formatter(amount, country),
        });
      } else {
        message = formatMessage(Translations['refundTitle.void_credit_title']);
      }
      /**
       * There are updates elements
       */
    } else if (updates.length) {
      message = formatMessage(Translations['refundTitle.update_amount_title']);
      /**
       * Credtis and Void elements
       */
    } else {
      message = formatMessage(Translations['refundTitle.void_credit_title']);
    }

    return message ? (
      <Container>
        <IconContainer>
          <Alert filled color="shape-color-surface-feedback-informative" size="medium" />
        </IconContainer>
        <div>
          <Typography component="p" token="text-title-small">
            {message}
          </Typography>
          <RefundReference
            cancellations={cancellations}
            refunds={refunds}
            updates={updates}
            country={country}
          />
        </div>
      </Container>
    ) : null;
  },
);
