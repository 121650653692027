import pkg from '../../package.json';

export const Urls = {
  BASE_HTTP:
    typeof window !== 'undefined' && window.location.pathname.includes('-test')
      ? `/${pkg.name}-test/api`
      : `/${pkg.name}/api`,
  ENV: `/env`,
  ORDER_BY_CODE: `/order/code/:code`,
  ORDER_BY_ORDERID: `/order/:orderId`,
  BILLING: '/order/:orderId/billing/:documentId/:countryId',
  METRICS: `/metrics`,
  CARD_REFUND_DETAIL: '/order/:orderId/refund/:paymentMethod/detail',
};
