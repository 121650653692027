import React from 'react';
import styled from 'styled-components';

const AddressContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: ${(props) => props.theme.space('space-08')};
  padding-bottom: ${(props) => props.theme.space('space-08')};
  align-items: center;
`;

const IconContainer = styled.div`
  width: ${(props) => props.theme.space('space-12')};
  margin-right: ${(props) => props.theme.space('space-06')};
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space('space-02')};
`;

export { AddressContainer, IconContainer, ContentContainer };
