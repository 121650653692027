import React, { useState, useEffect } from 'react';
import { Constants } from '@app/config/constants';
import { ProductStatus } from './ProductStatus';

const useDetailProducts = (products, openSection) => {
  const [productList, setProductsList] = useState([]);
  const [open, setOpen] = useState(openSection);
  const [viewAll, setViewAll] = useState(false);
  const [viewAllState, setViewAllState] = useState(false);

  useEffect(() => {
    if (products?.length > Constants.PRODUCT_MAX_VIEWABLE) {
      setViewAll(true);
      setViewAllState(false);
    }
  }, [products, open]);

  useEffect(() => {
    if (products?.length) {
      setProductsList(() => {
        return products.filter((element, index) => {
          if (!viewAllState) {
            return index + 1 <= Constants.PRODUCT_MAX_VIEWABLE;
          }
          return true;
        });
      });
    }
  }, [products, viewAllState]);

  return {
    open,
    setOpen,
    viewAll,
    productList,
    setViewAll,
    viewAllState,
    setViewAllState,
  };
};

export { useDetailProducts };
