import { defineMessages } from 'react-intl';

export default defineMessages({
  'refundItemWallet.title': {
    id: 'refundItemWallet.title',
    defaultMessage: 'Saldo de PedidosYa',
  },
  'refundItemWallet.description': {
    id: 'refundItemWallet.description',
    defaultMessage: 'Ya lo tienes disponible en tu Billetera de PedidosYa.',
  },
  'refundItemWallet.view': {
    id: 'refundItemWallet.view',
    defaultMessage: 'Ver saldo',
  },
});
