import { defineMessages } from 'react-intl';

const messages = defineMessages({
  pickup_title: {
    id: 'OA_orderDetail_address_pickup_title',
    defaultMessage: 'Retiro en local',
  },
  delivery_title: {
    id: 'OA_orderDetail_address_delivery_title',
    defaultMessage: 'Delivery',
  },
  dinein_title: {
    id: 'OA_orderDetail_address_dinein_title',
    defaultMessage: 'Comer en local',
  },
  address_title: {
    id: 'OA_orderDetail_address_title',
    defaultMessage: 'Detalles sobre la entrega',
  },
  address_label: {
    id: 'OA_orderDetail_address_label',
    defaultMessage: 'Dirección',
  },
  address_origin: {
    id: 'OA_orderDetail_address_origin_label',
    defaultMessage: 'Dirección de origen',
  },
  address_destination: {
    id: 'OA_orderDetail_address_destination_label',
    defaultMessage: 'Dirección de entrega',
  },
  instructions_label: {
    id: 'OA_orderDetail_address_instructions_label',
    defaultMessage: 'Instrucciones',
  },
  shipping_type_label: {
    id: 'OA_orderDetail_address_shipping_type_label',
    defaultMessage: 'Tipo de entrega',
  },
});

export default messages;
