import { Verticals } from '@app/config/verticals';

const getTranslationKey = (vertical = '') => {
  if (Verticals.RESTAURANT === vertical.toUpperCase()) {
    return 'global_vertical_restaurant';
  }

  return 'global_vertical_store';
};

export { getTranslationKey };
