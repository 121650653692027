import { defineMessages } from 'react-intl';

const messages = defineMessages({
  product_state_missing_item: {
    id: 'OA_orderDetail_product_state_missing_item',
    defaultMessage: 'Producto faltante',
  },
  product_state_sold_out_item: {
    id: 'OA_orderDetail_product_state_sold_out_item',
    defaultMessage: 'Producto agotado',
  },
  product_state_price_change_item: {
    id: 'OA_orderDetail_product_state_price_change_item',
    defaultMessage: 'Precio modificado',
  },
  product_state_replaced_item: {
    id: 'OA_orderDetail_product_state_replaced_item',
    defaultMessage: 'Reemplazo',
  },
  product_state_weight_change_item: {
    id: 'OA_orderDetail_product_state_weight_change_item',
    defaultMessage: 'Peso modificado',
  },
  product_state_quantity_change_item: {
    id: 'OA_orderDetail_product_state_quantity_change_item',
    defaultMessage: 'Cantidad modificada',
  },
  products_note: {
    id: 'OA_orderDetail_products_note',
    defaultMessage: 'Notas',
  },
});

export default messages;
