import { Urls } from '@app/config/urls';
import { Constants } from '@app/config/constants';
import { getItem } from '@app/utils/storage';
import { HttpClient } from '@app/utils/http';

const OrderDetailRepository = {
  /**
   * getDetailByCode
   *
   * @description Method for return order detail by code
   * @param {string} flow - Origin of request
   * @param {string} cursor - Cursor reference to paginate
   */
  getDetailByCode: (code, flow, signal) => {
    const url = Urls.ORDER_BY_CODE.replace(':code', code);
    return HttpClient.getInstance().get(
      `${url}?mock=${getItem(Constants.MOCK_FLAG, false)}&flow=${flow}`,
      { signal },
    );
  },
  /**
   * getDetailByOrderId
   *
   * @description Method for return order detail by code
   * @param {string} flow - Origin of reuqest
   * @returns {Promise<Object>}
   */
  getDetailByOrderId: (orderId, flow, signal) => {
    const url = Urls.ORDER_BY_ORDERID.replace(':orderId', orderId);
    return HttpClient.getInstance().get(
      `${url}?mock=${getItem(Constants.MOCK_FLAG, false)}&flow=${flow}`,
      { signal },
    );
  },
  /**
   * getCardRefundDetail
   *
   * @description Function to get information about refund in card
   * @param {string} orderId - Order id
   * @param {string} paymentMethod - Payment method (CARD | VOUCHER | etc)
   * @param {string} flow - Origin request
   * @param {AbortSignal} signal
   * @returns Promise
   */
  getCardRefundDetail: (orderId, paymentMethod, flow, signal) => {
    const url = Urls.CARD_REFUND_DETAIL.replace(':orderId', orderId).replace(
      ':paymentMethod',
      paymentMethod,
    );
    return HttpClient.getInstance().get(
      `${url}?mock=${getItem(Constants.MOCK_FLAG, false)}&flow=${flow}`,
      { signal },
    );
  },
};

export { OrderDetailRepository };
