import React from 'react';
import styled from 'styled-components';

const ItemsContainer = styled.div`
  margin-top: 16px;
  & > div:last-child {
    padding-bottom: ${(props) => props.theme.space('space-04')};
    border-bottom: 0px;
  }
  & > div {
    border-bottom: 1px solid ${(props) => props.theme.color('shape-color-stroke-primary')};
  }
`;

export { ItemsContainer };
